

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import { BASE_URL } from '../common/baseUrl';

const CmWaterfall = ({ selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedBusiness, selectedVertical }) => {
  const [totals, setTotals] = useState(null);
  const [totals_budget, setTotals_budget] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const formatCurrency = (value) => {
    return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
  };

  useEffect(() => {
    const fetchTotalsData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/api/pvm-calculations/`, {
          params: {
            period: selectedPeriod,
            base_period: selectedBasePeriod,
            comparison_period: selectedCompPeriod,
            business: selectedBusiness,
            ship_to_vertical: selectedVertical,
          },
        });
        setTotals(response.data.totals);
      } catch (error) {
        console.error('Error fetching totals data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedPeriod && selectedBasePeriod && selectedCompPeriod) {
      fetchTotalsData();
    }
  }, [selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedBusiness, selectedVertical]);

  useEffect(() => {
    const fetchTotalsBudgetData = async () => {
      setIsLoading(true);
      try {
        const response_budget = await axios.get(`${BASE_URL}/budget-pvm-calculations`, {
          params: {
            period: selectedPeriod,
            base_period: selectedBasePeriod,
            comparison_period: selectedCompPeriod,
            business: selectedBusiness,
            ship_to_vertical: selectedVertical,
          },
        });
        setTotals_budget(response_budget.data.totals);
      } catch (error) {
        console.error('Error fetching budget totals data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedPeriod && selectedBasePeriod && selectedCompPeriod) {
      fetchTotalsBudgetData();
    }
  }, [selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedBusiness, selectedVertical]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!totals || !totals_budget) {
    return <p>No data available</p>;
  }

  const data = [
    {
      type: 'waterfall',
      orientation: 'v',
      measure: [
        'relative',
        'relative',
        'relative',
        'relative',
        'relative',
        'relative',
        'relative',
        'total',
        'relative',
        'relative',
        'relative',
        'relative',
        'relative',
        'relative',
        'total',
      ],
      x: [
        'Actual Comp CM',
        'Actual Pure Price Impact',
        'Actual FX & Other Impact',
        'Actual Vol + Mix Impact',
        'Actual STD VC Impact CM',
        'Actual VC Variance',
        'Actual Others',
        'Total Base CM',
        'Budget Pure Price Impact',
        'Budget FX & Other Impact',
        'Budget Vol + Mix Impact',
        'Budget STD VC Impact CM',
        'Budget VC Variance',
        'Budget Others',
        'Budget CM',
      ],
      text: [
        formatCurrency(totals.total_comp_cm),
        formatCurrency(totals.total_pure_price_impact),
        formatCurrency(totals.total_fx_other_impact),
        formatCurrency(totals.total_total_volume_mix_impact_cm),
        formatCurrency(totals.total_std_vc_impact_cm),
        formatCurrency(totals.total_vc_variance_impact_cm),
        formatCurrency(totals.total_vc_freight_other_impact),
        formatCurrency(totals.total_base_cm),
        formatCurrency(totals_budget.total_pure_price_impact),
        formatCurrency(totals_budget.total_fx_other_impact),
        formatCurrency(totals_budget.total_total_volume_mix_impact_cm),
        formatCurrency(totals_budget.total_std_vc_impact_cm),
        formatCurrency(totals_budget.total_vc_variance_impact_cm),
        formatCurrency(totals_budget.total_vc_freight_other_impact),
        formatCurrency(totals_budget.total_comp_cm),
      ],
      y: [
        totals.total_comp_cm,
        totals.total_pure_price_impact,
        totals.total_fx_other_impact,
        totals.total_total_volume_mix_impact_cm,
        totals.total_std_vc_impact_cm,
        totals.total_vc_variance_impact_cm,
        totals.total_vc_freight_other_impact,
        totals.total_base_cm,
        totals_budget.total_pure_price_impact,
        totals_budget.total_fx_other_impact*0,
        totals_budget.total_total_volume_mix_impact_cm,
        totals_budget.total_std_vc_impact_cm,
        totals_budget.total_vc_variance_impact_cm,
        totals_budget.total_vc_freight_other_impact,
        totals_budget.total_comp_cm,
      ],
      decreasing: { marker: { color: 'red' } },
      increasing: { marker: { color: 'green' } },
      totals: { marker: { color: 'blue' } },
    },
  ];

  const layout = {
    title: 'CM PVM Actual & Budget',
    // xaxis: { title: 'Metrics' },
    // yaxis: { title: 'Values' },
    showlegend: true,
    width: 800,
    height: 500,
    margin: {
      t: 30,
      b: 80,
      l: 50,
      r: 50,
    },
  };

  return <Plot data={data} layout={layout} />;
};

export default CmWaterfall;
