import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import { BASE_URL } from '../common/baseUrl';

const Waterfallchart = ({ selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedBusiness, selectedVertical }) => {
  const [totals, setTotals] = useState(null);
  const [totals_budget, setTotals_budget] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

      const formatCurrency = (value) => {
        return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
      };

      // const formatCurrency = (value) => {
      //   if (value >= 1000000) {
      //     // For values greater than or equal to 1,000,000, format as $X.XM
      //     const millions = value / 1000000;
      //     return `$${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
      //   } else {
      //     // For values less than 1,000,000, format with commas
      //     return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
      //   }
      // };
      
  useEffect(() => {
    const fetchTotalsData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/pvm-calculations`, {
          params: {
            period: selectedPeriod,
            base_period: selectedBasePeriod,
            comparison_period: selectedCompPeriod,
            business: selectedBusiness,
            ship_to_vertical: selectedVertical,
          },
        });
        setTotals(response.data.totals); // Access the correct structure
      } catch (error) {
        console.error('Error fetching totals data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedPeriod && selectedBasePeriod && selectedCompPeriod) {
      fetchTotalsData();
    }
  }, [selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedBusiness, selectedVertical]);




  useEffect(() => {
    const fetchTotalsData = async () => {
      setIsLoading(true);
      try {
        const response_budget = await axios.get(`${BASE_URL}/api/budget-pvm-calculations/`, {
          params: {
            period: selectedPeriod,
            base_period: selectedBasePeriod,
            comparison_period: selectedCompPeriod,
            business: selectedBusiness,
            ship_to_vertical: selectedVertical,
          },
        });
        setTotals_budget(response_budget.data.totals); // Access the correct structure
      } catch (error) {
        console.error('Error fetching totals data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedPeriod && selectedBasePeriod && selectedCompPeriod) {
      fetchTotalsData();
    }
  }, [selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedBusiness, selectedVertical]);





  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!totals) {
    return <p>No data available</p>;
  }

  if (!totals_budget) {
    return <p>No data available</p>;
  }

  const data = [
    {
      type: 'waterfall',
      orientation: 'v',
      measure: [
        'relative',
        'relative',
        'relative',
        'relative',

        'total',

        'relative',
        'relative',
        'relative',
        'total',
      ],
      x: [
        'Actual PY NS',
        'Pure Price Impact',
        'FX & Other Impact',
        'Vol + Mix Impact',

        'Actual NS',

        'budget Pure Price Impact',
        'budget FX & Other Impact',
        'budgetVol + Mix Impact',
        'Budget NS',
      ],
      text: [
        formatCurrency(totals.total_comp_ns),
        formatCurrency(totals.total_pure_price_impact),
        formatCurrency(totals.total_fx_other_impact),
        formatCurrency(totals.total_total_volume_mix_impact),
        formatCurrency(totals.total_base_ns),
        // formatCurrency(totals_budget.total_base_ns),
        formatCurrency(totals_budget.total_pure_price_impact),
        formatCurrency(totals_budget.total_fx_other_impact*0),
        formatCurrency(totals_budget.total_total_volume_mix_impact),
        formatCurrency(totals_budget.total_comp_ns),
      ],
      y: [
        formatCurrency(totals.total_comp_ns),
        formatCurrency(totals.total_pure_price_impact),
        formatCurrency(totals.total_fx_other_impact),
        formatCurrency(totals.total_total_volume_mix_impact),
        formatCurrency(totals.total_base_ns),
        // formatCurrency(totals_budget.total_base_ns),
        formatCurrency(totals_budget.total_pure_price_impact),
        formatCurrency(totals_budget.total_fx_other_impact*0),
        formatCurrency(totals_budget.total_total_volume_mix_impact),
        formatCurrency(totals_budget.total_comp_ns),
      ],
      decreasing: { marker: { color: 'red' } },
      increasing: { marker: { color: 'green' } },
      totals: { marker: { color: 'blue' } },

      // decreasing: { marker: { color: 'red' }, name: 'Decrease' },
      // increasing: { marker: { color: 'green' }, name: 'Increase' },
      // totals: { marker: { color: 'blue' }, name: 'Total' },
    },
  ];

  const layout = {
    title: 'NS PVM Actual & Budget',
    // xaxis: { title: 'Metrics' },
    // yaxis: { title: 'Values' },
    showlegend: true,
    // legend: {
    //   orientation: 'h', // Horizontal layout for the legend
    //   x: 0.5,
    //   xanchor: 'center',
    //   y: -0.3, // Position the legend below the chart
    // },
    width: 800, // Set the width here
    height: 500, // Set the height here
    margin: {
      t: 30, // Reduce the top margin
      b: 80, // Bottom margin
      l: 50, // Left margin
      r: 50, // Right margin
    },
  };

  return <Plot data={data} layout={layout} />;
};

export default Waterfallchart;
