// import React, { useState } from "react";
// import Navbar from "../components/common/Navbar";
// import '../components/static/CmAnalysis.css';
// import CmFilters from "../components/cm_bridge/CmFilters";
// import SummaryStats from "../components/common/SummaryStats";
// import { useEffect } from "react";
// import axios from "axios";
// import { BASE_URL } from "../components/common/baseUrl";
// import CmWaterfall from "../components/cm_bridge/CmWaterfall";


// function CmAnalysis () {
//     const [selectedYear, setSelectedYear] = useState('');
//     const [selectedBusiness, setSelectedBusiness] = useState('');
//     const [selectedVertical, setSelectedVertical] = useState('');
//     const [selectedCustomer, setSelectedCustomer] = useState('');
//     const [selectedProductLine, setselectedProductLine] = useState('');
//     const [selectedKeyaccount, setselectedKeyaccount] = useState('');
//     const [years, setYears] = useState([]);
//     const [businesses, setBusinesses] = useState([]);
//     const [verticals, setVerticals] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);
//     const [customers, setCustomers] = useState([]);
//     const [products, setProducts] = useState([]);
//     const [keyaccounts, setKeyaccount] = useState([]);
//     const [productlines, setProductlines] = useState([]);

//     useEffect(() => {
//         fetchFilters();
//     }, []);

//     const fetchFilters = async () => {
//         try {
//             const response = await axios.get(`${BASE_URL}/api/pricingdata/`);
//             const data = response.data;

//             const response_customer = await axios.get(`${BASE_URL}/api/customers/`);
//             const data_cust = response_customer.data;

//             // const response_calendar = await axios.get(`${BASE_URL}/api/calendar/`);
//             // const data_cal = response_calendar.data;

//             const response_material = await axios.get(`${BASE_URL}/api/material/`);
//             const data_mat = response_material.data;

//             const uniqueYears = [''].concat([...new Set(data.map(item => item.year))]);
//             const uniqueBusinesses = [''].concat([...new Set(data.map(item => item.business))]);
//             const uniqueVerticals = [''].concat([...new Set(data_cust.map(item => item.ship_to_vertical))]);
//             const uniqueKeyaccounts = [''].concat([...new Set(data_cust.map(item => item.key_account))])
//             const uniqueCustomers = [''].concat([...new Set(data_cust.map(item => item.customer_name))])
//             const uniqueProductlines = [''].concat([...new Set(data_mat.map(item => item.product_line))])
//             const uniqueProducts = [''].concat([...new Set(data_mat.map(item => item.product_name))])

//             setYears(uniqueYears);
//             setBusinesses(uniqueBusinesses);
//             setVerticals(uniqueVerticals);
//             setKeyaccount(uniqueKeyaccounts);
//             setCustomers(uniqueCustomers);
//             setProductlines(uniqueProductlines);
//             setProducts(uniqueProducts);
            
//         } catch (error) {
//             console.error('Error fetching filter data:', error);
//         } finally {
//             setIsLoading(false);
//         }
//     };




//     return (
//         <div className="cm-analysis">
//             <Navbar />
//             <div className="main-content">
//                 <div className="header-container">
//                     <div className="summary-container">
//                       <h3>CM Bridge Comming soon</h3>
//                     </div>
//                     <div className="filter-container">
//                         <CmFilters
//                           years={years}
//                           businesses={businesses}
//                           selectedYear={selectedYear}
//                           selectedBusiness={selectedBusiness}
//                         />
//                     </div>
//                 </div>
//                 <div className="content-container-cm">
//                     <div className="chart-container-cm">
//                          <CmWaterfall
//                           selectedYear = {selectedYear}
//                           selectedBusiness = {selectedBusiness}
//                          />
//                     </div>

//                     <div className="table-container-cm">
//                          <p>from table</p>
//                     </div>

//                 </div>
//             </div>
//         </div>
//     );
// }

// export default CmAnalysis;




// CmAnalysis.js




import React, { useState, useEffect } from "react";
import Navbar from "../../components/common/Navbar/Navbar";
import './CmAnalysis.css';
import CmFilters from "../../components/cm_bridge/CmFilter/CmFilters";
import axios from "axios";
import { BASE_URL } from "../../components/common/baseUrl";
import CmWaterfall from "../../components/cm_bridge/CmWaterfall/CmWaterfall";
import SummaryStats from "../../components/common/Totals/SummaryStats";
import CmTable from "../../components/cm_bridge/CmTable/CmTable";
import CmTotals from "../../components/cm_bridge/CmTotals/CmTotals";

function CmAnalysis () {
    const [selectedYear, setSelectedYear] = useState('2023');
    const [selectedBusiness, setSelectedBusiness] = useState('');
    const [selectedVertical, setSelectedVertical] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedMaterial, setselectedMaterial] = useState('');
    const [selectedProductLine, setselectedProductLine] = useState('');
    const [selectedKeyaccount, setselectedKeyaccount] = useState(null);
    // const [selectedKeyAccount, setSelectedKeyAccount] = useState(null);
    const [years, setYears] = useState([]);
    const [businesses, setBusinesses] = useState([]);
    const [verticals, setVerticals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [keyaccounts, setKeyaccount] = useState([]);
    const [productlines, setProductlines] = useState([]);
    

    useEffect(() => {
        fetchFilters();
    }, []);

    const fetchFilters = async () => {
        setIsLoading(true);
        try {
            const [pricingDataRes, customersRes, materialRes] = await Promise.all([
                axios.get(`${BASE_URL}/pricingdata`),
                axios.get(`${BASE_URL}/customers`),
                axios.get(`${BASE_URL}/materials`),
            ]);

            const pricingData = pricingDataRes.data;
            const customersData = customersRes.data;
            const materialData = materialRes.data;

            const uniqueYears = [...new Set(pricingData.map(item => item.year))];
            const uniqueBusinesses = [...new Set(pricingData.map(item => item.business))];
            const uniqueVerticals = [''].concat([...new Set(customersData.map(item => item.ship_to_vertical))]);
            const uniqueKeyaccounts = [''].concat([...new Set(customersData.map(item => item.key_account))])
            const uniqueCustomers = [''].concat([...new Set(customersData.map(item => item.customer_name))])
            const uniqueProductlines = [''].concat([...new Set(materialData.map(item => item.product_line))])
            const uniqueProducts = [''].concat([...new Set(materialData.map(item => item.product_name))])

            setYears(uniqueYears);
            setBusinesses(uniqueBusinesses);
            setVerticals(uniqueVerticals);
            setKeyaccount(uniqueKeyaccounts);
            setCustomers(uniqueCustomers);
            setProductlines(uniqueProductlines);
            setMaterials(uniqueProducts);
        } catch (error) {
            console.error('Error fetching filter data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Handler functions
    const handleYearChange = (year) => {
        setSelectedYear(year);
    };

    const handleBusinessChange = (business) => {
        setSelectedBusiness(business);
    };

    const handleCustomerChange = (customer) => {
        setSelectedCustomer(customer);
    };

    const handleVerticalChange = (vertical) =>{
        setSelectedVertical(vertical)
    }

    const handleMaterialChange = (product) => {
        setselectedMaterial(product);
    };

    const handleKeyaccountChange = (keyaccount) =>{
        setselectedKeyaccount(keyaccount)
    }

    const handleProductlineChange = (productline) =>{
        setselectedProductLine(productline)
    }

    const handleFilterChange = (filterModel) => {
        console.log('Filter Model:', filterModel);
        // Use the filter model to update states or perform other actions
        // For example, set states based on filter changes:
        if (filterModel.productline){
            selectedProductLine(filterModel.productline.values[0])
        }
        if (filterModel.key_account) {
            setselectedKeyaccount(filterModel.key_account.values[0]);
        }
        if (filterModel.customer_name) {
          setSelectedCustomer(filterModel.customer_name.values[0]);
        }
        if (filterModel.material_name) {
            setselectedMaterial(filterModel.material_name.values[0]);
        }
      };


    return (
        <div className="cm-analysis">
   <Navbar />
   <div className="main-content-cm">
      <div className="header-chart">
         <div className="header-container-cm">
            <div className="title-filter">
               <div className="page-title">
                  <p>CM Bridge Analysis</p>
               </div>
               <div className="filter-container-cm">
                  <CmFilters
                     years={years}
                     businesses={businesses}
                     customers={customers}
                     materials={materials}
                     verticals={verticals}
                     keyaccounts={keyaccounts}
                     productlines={productlines}
                     selectedYear={selectedYear}
                     selectedBusiness={selectedBusiness}
                     selectedCustomer={selectedCustomer}
                     selectedMaterial={selectedMaterial}
                     selectedVertical={selectedVertical}
                     selectedKeyaccount={selectedKeyaccount}
                     selectedProductLine={selectedProductLine}
                     onYearChange={handleYearChange}
                     onBusinessChange={handleBusinessChange}
                     onCustomerChange={handleCustomerChange}
                     onMaterialChange={handleMaterialChange}
                     onVerticalChange={handleVerticalChange}
                     onKeyaccountChange={handleKeyaccountChange}
                     onProductlineChange={handleProductlineChange}
                     isLoading={isLoading}
                     />
               </div>
            </div>
            <div className="summary-container-cm">
               <CmTotals
                  selectedYear={selectedYear}
                  selectedBusiness={selectedBusiness}
                  selectedCustomer={selectedCustomer}
                  selectedMaterial={selectedMaterial}
                  selectedVertical={selectedVertical}
                  selectedKeyaccount={selectedKeyaccount}
                  selectedProductLine={selectedProductLine}
                  />
            </div>
         </div>
         <div className="chart-container-cm">
            <CmWaterfall
               selectedYear={selectedYear}
               selectedBusiness={selectedBusiness}
               selectedCustomer={selectedCustomer}
               selectedMaterial={selectedMaterial}
               selectedVertical={selectedVertical}
               selectedKeyaccount={selectedKeyaccount}
               selectedProductLine={selectedProductLine}
               onProductLineChange={handleProductlineChange}
               onKeyaccountChange={handleKeyaccountChange}
               onCustomerChange={handleCustomerChange}
               onMaterialChange={handleMaterialChange}
               onFilterChange={handleFilterChange}
               />
         </div>
      </div>
      <div className="table-container-cm">
         <CmTable
            selectedYear={selectedYear}
            selectedBusiness={selectedBusiness}
            selectedCustomer={selectedCustomer}
            selectedMaterial={selectedMaterial}
            selectedVertical={selectedVertical}
            selectedKeyaccount={selectedKeyaccount}
            selectedProductLine={selectedProductLine}
            onProductLineChange={handleProductlineChange}
            onKeyaccountChange={handleKeyaccountChange}
            onCustomerChange={handleCustomerChange}
            onMaterialChange={handleMaterialChange}
            onFilterChange={handleFilterChange}
            />
      </div>
   </div>
</div>
    );
}

export default CmAnalysis;









// import React, { useState, useEffect } from "react";
// import Navbar from "../../components/common/Navbar/Navbar";
// import './CmAnalysis.css';
// import CmFilters from "../../components/cm_bridge/CmFilter/CmFilters";
// import axios from "axios";
// import { BASE_URL } from "../../components/common/baseUrl";
// import CmWaterfall from "../../components/cm_bridge/CmWaterfall/CmWaterfall";
// import SummaryStats from "../../components/common/Totals/SummaryStats";
// import CmTable from "../../components/cm_bridge/CmTable/CmTable";
// import CmTotals from "../../components/cm_bridge/CmTotals/CmTotals";

// function CmAnalysis() {
//     const [selectedYear, setSelectedYear] = useState('2023');
//     const [selectedBusiness, setSelectedBusiness] = useState('');
//     const [selectedVertical, setSelectedVertical] = useState('');
//     const [selectedCustomer, setSelectedCustomer] = useState(null);
//     const [selectedMaterial, setSelectedMaterial] = useState('');
//     const [selectedProductLine, setSelectedProductLine] = useState('');
//     const [selectedKeyaccount, setSelectedKeyaccount] = useState(null);
//     const [years, setYears] = useState([]);
//     const [businesses, setBusinesses] = useState([]);
//     const [verticals, setVerticals] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);
//     const [customers, setCustomers] = useState([]);
//     const [materials, setMaterials] = useState([]);
//     const [keyaccounts, setKeyaccounts] = useState([]);
//     const [productlines, setProductlines] = useState([]);
//     const [filteredData, setFilteredData] = useState([]);
//     const [totals, setTotals] = useState({});

//     useEffect(() => {
//         fetchFilters();
//     }, []);

//     const fetchFilters = async () => {
//         setIsLoading(true);
//         try {
//             const [pricingDataRes, customersRes, materialRes] = await Promise.all([
//                 axios.get(`${BASE_URL}/api/pricingdata/`),
//                 axios.get(`${BASE_URL}/api/customers/`),
//                 axios.get(`${BASE_URL}/api/materials/`),
//             ]);

//             const pricingData = pricingDataRes.data;
//             const customersData = customersRes.data;
//             const materialData = materialRes.data;

//             const uniqueYears = [...new Set(pricingData.map(item => item.year))];
//             const uniqueBusinesses = [...new Set(pricingData.map(item => item.business))];
//             const uniqueVerticals = [''].concat([...new Set(customersData.map(item => item.ship_to_vertical))]);
//             const uniqueKeyaccounts = [''].concat([...new Set(customersData.map(item => item.key_account))]);
//             const uniqueCustomers = [''].concat([...new Set(customersData.map(item => item.customer_name))]);
//             const uniqueProductlines = [''].concat([...new Set(materialData.map(item => item.product_line))]);
//             const uniqueProducts = [''].concat([...new Set(materialData.map(item => item.product_name))]);

//             setYears(uniqueYears);
//             setBusinesses(uniqueBusinesses);
//             setVerticals(uniqueVerticals);
//             setKeyaccounts(uniqueKeyaccounts);
//             setCustomers(uniqueCustomers);
//             setProductlines(uniqueProductlines);
//             setMaterials(uniqueProducts);
//         } catch (error) {
//             console.error('Error fetching filter data:', error);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const handleYearChange = (year) => {
//         setSelectedYear(year);
//     };

//     const handleBusinessChange = (business) => {
//         setSelectedBusiness(business);
//     };

//     const handleCustomerChange = (customer) => {
//         setSelectedCustomer(customer);
//     };

//     const handleVerticalChange = (vertical) => {
//         setSelectedVertical(vertical);
//     };

//     const handleMaterialChange = (material) => {
//         setSelectedMaterial(material);
//     };

//     const handleKeyaccountChange = (keyaccount) => {
//         setSelectedKeyaccount(keyaccount);
//     };

//     const handleProductlineChange = (productline) => {
//         setSelectedProductLine(productline);
//     };

//     const handleFilteredDataChange = (data) => {
//         setFilteredData(data);
//     };

//     const handleTotalsChange = (data, recalculatedTotals) => {
//         setFilteredData(data);
//         setTotals(recalculatedTotals);
//     };

//     return (
//         <div className="cm-analysis">
//             <Navbar />
//             <div className="main-content-cm">
//                 <div className="header-chart">
//                     <div className="header-container">
//                         <div className="title-filter">
//                             <div className="page-title">
//                                 <p>CM Bridge Analysis</p>
//                             </div>
//                             <div className="filter-container-cm">
//                                 <CmFilters
//                                     years={years}
//                                     businesses={businesses}
//                                     customers={customers}
//                                     materials={materials}
//                                     verticals={verticals}
//                                     keyaccounts={keyaccounts}
//                                     productlines={productlines}
//                                     selectedYear={selectedYear}
//                                     selectedBusiness={selectedBusiness}
//                                     selectedCustomer={selectedCustomer}
//                                     selectedMaterial={selectedMaterial}
//                                     selectedVertical={selectedVertical}
//                                     selectedKeyaccount={selectedKeyaccount}
//                                     selectedProductLine={selectedProductLine}
//                                     onYearChange={handleYearChange}
//                                     onBusinessChange={handleBusinessChange}
//                                     onCustomerChange={handleCustomerChange}
//                                     onMaterialChange={handleMaterialChange}
//                                     onVerticalChange={handleVerticalChange}
//                                     onKeyaccountChange={handleKeyaccountChange}
//                                     onProductlineChange={handleProductlineChange}
//                                     isLoading={isLoading}
//                                 />
//                             </div>
//                         </div>
//                         <div className="summary-container-cm">
//                             <CmTotals
//                                 selectedYear={selectedYear}
//                                 selectedBusiness={selectedBusiness}
//                                 selectedCustomer={selectedCustomer}
//                                 selectedMaterial={selectedMaterial}
//                                 selectedVertical={selectedVertical}
//                                 selectedKeyaccount={selectedKeyaccount}
//                                 selectedProductLine={selectedProductLine}
//                                 totals={totals} // Pass totals here
//                             />
//                         </div>
//                     </div>
//                     <div className="chart-container-cm">
//                         <CmWaterfall
//                             selectedYear={selectedYear}
//                             selectedBusiness={selectedBusiness}
//                             selectedCustomer={selectedCustomer}
//                             selectedMaterial={selectedMaterial}
//                             selectedVertical={selectedVertical}
//                             selectedKeyaccount={selectedKeyaccount}
//                             selectedProductLine={selectedProductLine}
//                             onProductLineChange={handleProductlineChange}
//                             onKeyaccountChange={handleKeyaccountChange}
//                             onCustomerChange={handleCustomerChange}
//                             onMaterialChange={handleMaterialChange}
//                             // onFilterChange={handleFilterChange}
//                             data={filteredData}
//                         />
//                     </div>
//                 </div>
//                 <div className="table-container-cm">
//                     <CmTable
//                         selectedYear={selectedYear}
//                         selectedBusiness={selectedBusiness}
//                         selectedCustomer={selectedCustomer}
//                         selectedMaterial={selectedMaterial}
//                         selectedVertical={selectedVertical}
//                         selectedKeyaccount={selectedKeyaccount}
//                         selectedProductLine={selectedProductLine}
//                         onFilteredDataChange={handleFilteredDataChange}
//                         onTotalsChange={handleTotalsChange}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default CmAnalysis;
