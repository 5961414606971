// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.summary-stats-price-category,
.filter {
  padding-right: 5px;
  width: 100%;
}

.summary-stats-price-category .stats-grid-price-category {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  background-color: #efefef;
}

.summary-stats-price-category .stat-item-price-category {
  /* margin-top: 8px; */
  display: grid;
  justify-content: space-evenly;
  /* padding: 5px; */
  background-color: #efefef;
  /* border: 1px solid #ddd; */
  border-radius: 4px;
  font-size: 0.9em;
  padding: 5px;
}

.stat-label-price-category {
  /* font-weight: bold; */
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  /* font-family: "DM Sans", sans-serif; */
}

.stat-value-price-category {
  color: #333;
  /* font-weight: bold; */
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}


`, "",{"version":3,"sources":["webpack://./src/components/price_category/PriceTotals/PriceTotals.css"],"names":[],"mappings":";AACA;;EAEE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,0CAA0C;EAC1C,SAAS;EACT,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,6BAA6B;EAC7B,kBAAkB;EAClB,yBAAyB;EACzB,4BAA4B;EAC5B,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,WAAW;EACX,uBAAuB;EACvB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["\n.summary-stats-price-category,\n.filter {\n  padding-right: 5px;\n  width: 100%;\n}\n\n.summary-stats-price-category .stats-grid-price-category {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n  gap: 20px;\n  background-color: #efefef;\n}\n\n.summary-stats-price-category .stat-item-price-category {\n  /* margin-top: 8px; */\n  display: grid;\n  justify-content: space-evenly;\n  /* padding: 5px; */\n  background-color: #efefef;\n  /* border: 1px solid #ddd; */\n  border-radius: 4px;\n  font-size: 0.9em;\n  padding: 5px;\n}\n\n.stat-label-price-category {\n  /* font-weight: bold; */\n  font-size: 11px;\n  font-weight: 500;\n  text-align: center;\n  /* font-family: \"DM Sans\", sans-serif; */\n}\n\n.stat-value-price-category {\n  color: #333;\n  /* font-weight: bold; */\n  font-weight: bold;\n  font-size: 18px;\n  text-align: center;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
