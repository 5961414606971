
import React from 'react';
import Select from 'react-select';
import './PriceCategory.css';

const PriceCategoryFilter = ({
  years = [2020, 2021, 2022, 2023],
  businesses = [],
  customers = [],
  materials = [],
  verticals = [],
  keyaccounts = [],
  productlines = [],
  selectedYear,
  selectedBusiness,
  selectedCustomer,
  selectedMaterial,
  selectedVertical,
  selectedKeyaccount,
  selectedProductLine,
  onYearChange,
  onBusinessChange,
  onCustomerChange,
  onVerticalChange,
  onKeyaccountChange,
  onProductlineChange,
  onMaterialChange
}) => {
  // Convert arrays to the format required by react-select
  const formatOptions = (options) => options.map(option => ({ value: option, label: option }));

  return (
    <div className="filter-price-category-comp">
      <div className='filter-grid-price-category'>
        {/* Year Dropdown */}
        <div className="filter-group-price-category">
          <Select
            options={formatOptions(years)}
            value={selectedYear ? { value: selectedYear, label: selectedYear } : null}
            onChange={(option) => onYearChange(option ? option.value : '')}
            isClearable
            placeholder="Year"
            className="react-select-container"
          />
        </div>
        {/* Business Dropdown */}
        <div className="filter-group-price-category">
          <Select
            options={formatOptions(businesses)}
            value={selectedBusiness ? { value: selectedBusiness, label: selectedBusiness } : null}
            onChange={(option) => onBusinessChange(option ? option.value : '')}
            isClearable
            placeholder="Business"
            className="react-select-container"
          />
        </div>
        {/* Key Account Dropdown */}
        {/* <div className="filter-group">
          <Select
            options={formatOptions(keyaccounts)}
            value={selectedKeyaccount ? { value: selectedKeyaccount, label: selectedKeyaccount } : null}
            onChange={(option) => onKeyaccountChange(option ? option.value : '')}
            isClearable
            placeholder="Key Account"
            className="react-select-container"
          />
        </div> */}
        {/* Customer Dropdown */}
        {/* <div className="filter-group">
          <Select
            options={formatOptions(customers)}
            value={selectedCustomer ? { value: selectedCustomer, label: selectedCustomer } : null}
            onChange={(option) => onCustomerChange(option ? option.value : '')}
            isClearable
            placeholder="Customer"
            className="react-select-container"
          />
        </div> */}
        {/* Material Dropdown */}
        {/* <div className="filter-group">
          <Select
            options={formatOptions(materials)}
            value={selectedMaterial ? { value: selectedMaterial, label: selectedMaterial } : null}
            onChange={(option) => onMaterialChange(option ? option.value : '')}
            isClearable
            placeholder="Material"
            className="react-select-container"
          />
        </div> */}
        
        {/* Product Line Dropdown */}
        {/* <div className="filter-group">
          <Select
            options={formatOptions(productlines)}
            value={selectedProductLine ? { value: selectedProductLine, label: selectedProductLine } : null}
            onChange={(option) => onProductlineChange(option ? option.value : '')}
            isClearable
            placeholder="Product Line"
            className="react-select-container"
          />
        </div> */}
      </div>
    </div>
  );
};

export default PriceCategoryFilter;
