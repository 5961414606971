// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.filter-pvm {
  display: flex;
  flex-direction: column; 
  align-items: center;
  /* padding: 10px; */
 
  
}

.filter-pvm  .filter-grid-pvm {
  display: flex;
  /* flex-direction: column;  */
  gap: 10px;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  background-color: #ffffff;
  /* max-width: 250px;  */
  
}

.filter-pvm  .filter-group-pvm {
  width: 100%; /* Ensures that each filter takes the full width */
  
}

.filter-group-pvm{
  margin-top: 5px;
  margin-bottom: 5px;
}

.filter-pvm .react-select-container {
  width: 100%; /* Ensures the react-select component stretches to fill the available width */
  
}
`, "",{"version":3,"sources":["webpack://./src/components/pvm/Pvmfilters/Pvmfilter.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;;;AAGrB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,SAAS;EACT,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,uBAAuB;;AAEzB;;AAEA;EACE,WAAW,EAAE,kDAAkD;;AAEjE;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW,EAAE,6EAA6E;;AAE5F","sourcesContent":["\n.filter-pvm {\n  display: flex;\n  flex-direction: column; \n  align-items: center;\n  /* padding: 10px; */\n \n  \n}\n\n.filter-pvm  .filter-grid-pvm {\n  display: flex;\n  /* flex-direction: column;  */\n  gap: 10px;\n  width: 100%;\n  font-size: 12px;\n  font-weight: 500;\n  background-color: #ffffff;\n  /* max-width: 250px;  */\n  \n}\n\n.filter-pvm  .filter-group-pvm {\n  width: 100%; /* Ensures that each filter takes the full width */\n  \n}\n\n.filter-group-pvm{\n  margin-top: 5px;\n  margin-bottom: 5px;\n}\n\n.filter-pvm .react-select-container {\n  width: 100%; /* Ensures the react-select component stretches to fill the available width */\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
