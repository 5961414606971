import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PricingDataTable.css'; 
import { BASE_URL } from '../../common/baseUrl';
import CustomerBar from '../BarCharts/CustomerBar';

const PricingDataTable = ({ selectedYear, selectedBusiness, selectedPricingGroup, onItemClick, onPricingGroupClick }) => {
    const [aggregatedData, setAggregatedData] = useState([]);

    useEffect(() => {
        fetchAggregatedData();
    }, [selectedYear, selectedBusiness, selectedPricingGroup]);

    const fetchAggregatedData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/pricinggroup-summary`, {
                params: {
                    year: selectedYear,
                    business: selectedBusiness,
                    pricing_group: selectedPricingGroup
                }
            });
            // Sort the aggregated data by total_opportunity in descending order
            const sortedData = response.data.sort((a, b) => b.total_opportunity - a.total_opportunity);
            setAggregatedData(sortedData); // Set the aggregated data
        } catch (error) {
            console.error('Error fetching aggregated pricing data:', error); // Log errors
        }
    };

    const formatCurrency = (value) => {
        return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    };

    const getMaxValue = (data, key) => {
        return Math.max(...data.map(item => item[key]));
    };

    const maxNetSales = getMaxValue(aggregatedData, 'total_net_sales');
    const maxOpportunity = getMaxValue(aggregatedData, 'total_opportunity');

    const handleRowClick = (item) => {
        onItemClick(item); // Pass the selected item to the parent component
    };

    const handlePricingGroupClick = (e, pricingGroup) => {
        e.stopPropagation(); // Prevent the row click event
        onPricingGroupClick(pricingGroup); // Update the selected pricing group
    };

    return (
        <div className="pricing-table-container">
        <div><p className='table-title'>How much is the pricing opportunity by product group?</p></div>
            <div className="pricing-table-wrapper">
                <table className="pricing-table">
                    <thead>
                        <tr>
                            <th>Pricing Group</th>
                            <th>Total Net Sales</th>
                            <th>Total Opportunity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {aggregatedData.map((data, index) => (
                            <tr key={index} onClick={() => handleRowClick(data)}>
                                <td onClick={(e) => handlePricingGroupClick(e, data.pricing_group)}
                                      style={{ cursor: 'pointer' }}
                                    >
                                    
                                    {data.pricing_group}
                                </td>
                                <td>
                                    <div className="bar-container-opp">
                                        <div
                                            className="bar-opp"
                                            style={{ width: `${(data.total_net_sales / maxNetSales) * 100}%` }}
                                        ></div>
                                        <div className="bar-label-opp">
                                            {formatCurrency(data.total_net_sales)}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="bar-container-opp">
                                        <div
                                            className="bar-opp bar-opportunity"
                                            style={{ width: `${(data.total_opportunity / maxOpportunity) * 100}%` }}
                                        ></div>
                                        <div className="bar-label-opp">
                                            {formatCurrency(data.total_opportunity)}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PricingDataTable;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
// import '../static/PricingDataTable.css'; 
// import { BASE_URL } from '../common/baseUrl';

// const PricingDataTable = ({ selectedYear, selectedBusiness, selectedPricingGroup, onItemClick, onPricingGroupClick }) => {
//     const [aggregatedData, setAggregatedData] = useState([]);

//     useEffect(() => {
//         fetchAggregatedData();
//     }, [selectedYear, selectedBusiness, selectedPricingGroup]);

//     const fetchAggregatedData = async () => {
//         try {
//             const response = await axios.get(`${BASE_URL}/api/pricinggroup-summary/`, {
//                 params: {
//                     year: selectedYear,
//                     business: selectedBusiness,
//                     pricing_group: selectedPricingGroup
//                 }
//             });
//             // Sort the aggregated data by total_opportunity in descending order
//             const sortedData = response.data.sort((a, b) => b.total_opportunity - a.total_opportunity);
//             setAggregatedData(sortedData); // Set the aggregated data
//         } catch (error) {
//             console.error('Error fetching aggregated pricing data:', error); // Log errors
//         }
//     };

//     const formatCurrency = (value) => {
//         return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
//     };

//     const getMaxValue = (data, key) => {
//         return Math.max(...data.map(item => item[key]));
//     };

//     const maxNetSales = getMaxValue(aggregatedData, 'total_net_sales');
//     const maxOpportunity = getMaxValue(aggregatedData, 'total_opportunity');

//     const handleRowClick = (item) => {
//         onItemClick(item); // Pass the selected item to the parent component
//     };

//     const handlePricingGroupClick = (params) => {
//         const pricingGroup = params.data.pricing_group;
//         onPricingGroupClick(pricingGroup); // Update the selected pricing group
//     };

//     const columns = [
//         {
//             headerName: 'Pricing Group',
//             field: 'pricing_group',
//             cellRendererFramework: (params) => (
//                 <span
//                     className="pricing-group-cell"
//                     onClick={(e) => {
//                         e.stopPropagation(); // Prevent row click
//                         handlePricingGroupClick(params);
//                     }}
//                 >
//                     {params.value}
//                 </span>
//             )
//         },
//         {
//             headerName: 'Total Net Sales',
//             field: 'total_net_sales',
//             valueFormatter: (params) => formatCurrency(params.value),
//             cellRendererFramework: (params) => (
//                 <div className="bar-container">
//                     <div
//                         className="bar"
//                         style={{ width: `${(params.value / maxNetSales) * 100}%`, backgroundColor: '#3498db' }}
//                     ></div>
//                     <div className="bar-label">
//                         {formatCurrency(params.value)}
//                     </div>
//                 </div>
//             )
//         },
//         {
//             headerName: 'Total Opportunity',
//             field: 'total_opportunity',
//             valueFormatter: (params) => formatCurrency(params.value),
//             cellRendererFramework: (params) => (
//                 <div className="bar-container">
//                     <div
//                         className="bar bar-opportunity"
//                         style={{ width: `${(params.value / maxOpportunity) * 100}%`, backgroundColor: '#e74c3c' }}
//                     ></div>
//                     <div className="bar-label">
//                         {formatCurrency(params.value)}
//                     </div>
//                 </div>
//             )
//         }
//     ];

//     return (
//         <div className="ag-theme-alpine pricing-table-container" style={{ height: 400, width: '100%' }}>
//             <div className='table-title'><p>How much is the pricing opportunity by product group?</p></div>
//             <AgGridReact
//                 rowData={aggregatedData}
//                 columnDefs={columns}
//                 domLayout='autoHeight'
//                 onRowClicked={(params) => handleRowClick(params.data)}
//             />
//         </div>
//     );
// };

// export default PricingDataTable;
