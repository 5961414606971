// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.summary-stats,
.filter {
  padding-right: 5px;
  width: 100%;
}

.stats-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  gap: 50px;
  background-color: #efefef;
}

.stat-item {
  /* margin-top: 8px; */
  display: grid;
  justify-content: space-evenly;
  padding: 5px;
  /* background-color: #fff; */
  /* border: 1px solid #ddd; */
  background-color: #efefef;
  border-radius: 4px;
  font-size: 0.9em;
}

.stat-label {
  /* font-weight: bold; */
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  /* font-family: "DM Sans", sans-serif; */
}

.stat-value {
  color: #333;
  /* font-weight: bold; */
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}


`, "",{"version":3,"sources":["webpack://./src/components/common/Totals/SummaryStats.css"],"names":[],"mappings":";AACA;;EAEE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uCAAuC;EACvC,SAAS;EACT,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,6BAA6B;EAC7B,YAAY;EACZ,4BAA4B;EAC5B,4BAA4B;EAC5B,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,WAAW;EACX,uBAAuB;EACvB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["\n.summary-stats,\n.filter {\n  padding-right: 5px;\n  width: 100%;\n}\n\n.stats-grid {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr ;\n  gap: 50px;\n  background-color: #efefef;\n}\n\n.stat-item {\n  /* margin-top: 8px; */\n  display: grid;\n  justify-content: space-evenly;\n  padding: 5px;\n  /* background-color: #fff; */\n  /* border: 1px solid #ddd; */\n  background-color: #efefef;\n  border-radius: 4px;\n  font-size: 0.9em;\n}\n\n.stat-label {\n  /* font-weight: bold; */\n  font-size: 11px;\n  font-weight: bold;\n  text-align: center;\n  /* font-family: \"DM Sans\", sans-serif; */\n}\n\n.stat-value {\n  color: #333;\n  /* font-weight: bold; */\n  font-weight: bold;\n  font-size: 18px;\n  text-align: center;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
