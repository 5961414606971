// import React, { useEffect, useState } from 'react';
// import Plot from 'react-plotly.js';
// import axios from 'axios';
// import { BASE_URL } from '../../common/baseUrl';

// const StackedBarchart = ({
//   selectedYear,
//   selectedBusiness,
//   selectedVertical,
//   selectedCustomer,
//   selectedMaterial,
//   selectedKeyaccount,
//   selectedProductLine,
// }) => {
//   const [chartData, setChartData] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     const fetchTotalsData = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get(`${BASE_URL}/api/price-category/`, {
//           params: {
//           year: selectedYear || undefined,
//           business: selectedBusiness || undefined,
//           vertical: selectedVertical || undefined,
//           customer: selectedCustomer || undefined,
//           material: selectedMaterial || undefined,
//           keyaccount: selectedKeyaccount || undefined,
//           productline: selectedProductLine || undefined,
//           },
//         });
//         console.log('API response stac:', response.data);
//         setChartData(response.data.monthly_summary);
//       } catch (error) {
//         console.error('Error fetching totals data:', error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     if (
//       selectedYear ||
//       selectedBusiness ||
//       selectedCustomer ||
//       selectedMaterial ||
//       selectedVertical ||
//       selectedKeyaccount ||
//       selectedProductLine
//     ) {
//       fetchTotalsData();
//     }
//   }, [
//     selectedYear,
//     selectedBusiness,
//     selectedVertical,
//     selectedCustomer,
//     selectedMaterial,
//     selectedKeyaccount,
//     selectedProductLine,
//   ]);

//   // Process the chart data
//   const processedData = chartData.reduce((acc, item) => {
//     const { month, month_customer_sales, price_change_category } = item;

//     if (!acc[month]) {
//       acc[month] = {};
//     }

//     if (!acc[month][price_change_category]) {
//       acc[month][price_change_category] = 0;
//     }

//     acc[month][price_change_category] += month_customer_sales;

//     return acc;
//   }, {});

//   console.log('Processed Data:', processedData);

//   const getColorForCategory = (category) => {
//     switch (category) {
//         case 'No Sales Last Year':
//             return { backgroundColor: '#eaed21' }; // Light Yellow
//           case 'Less than -9%':
//             return { backgroundColor: '#f55958' };
//           case '-9% to -6%':
//             return { backgroundColor: '#f2a1a0' }; // Light Red
//           case '-6% to -3%':
//             return { backgroundColor: '#f2a1a0' }; // Light Red
//           case '-3% to 0%':
//             return { backgroundColor: '#eff777' }; // Light Blue
//           case '0%':
//             return { backgroundColor: '#94f7a0' };
//           case '0% to 3%':
//             return { backgroundColor: '#eff777' }; // Light Purple
//           case '3% to 6%':
//             return { backgroundColor: '#93f59e' };
//           case '6% to 9%':
//             return { backgroundColor: '#61f271' };
//           case '9% to 12%':
//             return { backgroundColor: '#36f54b' };
//           case '12% to 15%':
//             return { backgroundColor: '#0cf225' };
//           case '15% to 18%':
//             return { backgroundColor: '#1dc430' };
//           case 'Greater than 18%':
//             return { backgroundColor: '#04bf19' };
//           case 'No Sales This Year':
//             return { backgroundColor: '#eaed21' }; // Light Purple
//       default:
//         return '#d62728';
//     }
//   };

//   // Prepare the traces for Plotly
//   const categories = Array.from(new Set(chartData.map(item => item.price_change_category)));
//   const months = Array.from(new Set(chartData.map(item => item.month)));

//   const traces = categories.map(category => {
//     return {
//       x: months,
//       y: months.map(month => processedData[month]?.[category] || 0),
//       type: 'bar',
//       name: category,
//       marker: { color: getColorForCategory(category) },
//     };
//   });

//   console.log('Traces:', traces);

//   // Function to assign colors based on category


//   return (
//     <div>
//       {isLoading ? (
//         <p>Loading...</p>
//       ) : (
//         <Plot
//           data={traces}
//           layout={{
//             title: 'Net Sales by Price Change Category',
//             barmode: 'stack',
//             xaxis: {
//               title: 'Month',
//               tickangle: -45,
//             },
//             height: 500, // Set the height
//             width: 1200 ,  // Set the width
//             yaxis: {
//               title: 'Net Sales',
//             },
//             showlegend: true,
//           }}
//           config={{ responsive: true }}
//         />
//       )}
//     </div>
//   );
// };

// export default StackedBarchart;






// import React, { useEffect, useState } from 'react';
// import Plot from 'react-plotly.js';
// import axios from 'axios';
// import { BASE_URL } from '../../common/baseUrl';

// const StackedBarchart = ({
//   selectedYear,
//   selectedBusiness,
//   selectedVertical,
//   selectedCustomer,
//   selectedMaterial,
//   selectedKeyaccount,
//   selectedProductLine,
// }) => {
//   const [chartData, setChartData] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     const fetchTotalsData = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get(`${BASE_URL}/api/price-category/`, {
//           params: {
//             year: selectedYear || undefined,
//             business: selectedBusiness || undefined,
//             vertical: selectedVertical || undefined,
//             customer: selectedCustomer || undefined,
//             material: selectedMaterial || undefined,
//             keyaccount: selectedKeyaccount || undefined,
//             productline: selectedProductLine || undefined,
//           },
//         });
//         console.log('API response:', response.data);
//         setChartData(response.data.monthly_summary);
//       } catch (error) {
//         console.error('Error fetching totals data:', error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     // Fetch data if any filters are selected
//     if (
//       selectedYear ||
//       selectedBusiness ||
//       selectedCustomer ||
//       selectedMaterial ||
//       selectedVertical ||
//       selectedKeyaccount ||
//       selectedProductLine
//     ) {
//       fetchTotalsData();
//     }
//   }, [
//     selectedYear,
//     selectedBusiness,
//     selectedVertical,
//     selectedCustomer,
//     selectedMaterial,
//     selectedKeyaccount,
//     selectedProductLine,
//   ]);

//   // Process the chart data into a format suitable for plotting
//   const processChartData = () => {
//     // Group data by month and category, filtering out null or undefined values
//     const dataByMonth = {};
//     chartData.forEach(({ month, month_customer_sales, price_change_category }) => {
//       if (month == null || price_change_category == null || month_customer_sales == null) return;

//       if (!dataByMonth[month]) {
//         dataByMonth[month] = {};
//       }
//       if (!dataByMonth[month][price_change_category]) {
//         dataByMonth[month][price_change_category] = 0;
//       }
//       dataByMonth[month][price_change_category] += month_customer_sales;
//     });

//     // Normalize data by calculating the percentage of total sales for each category in each month
//     const normalizedData = {};
//     Object.keys(dataByMonth).forEach((month) => {
//       const monthData = dataByMonth[month];
//       const totalSales = Object.values(monthData).reduce((sum, sales) => sum + (sales || 0), 0); // Sum sales while ignoring nulls

//       // Check for zero total sales to avoid division by zero
//       normalizedData[month] = {};
//       Object.keys(monthData).forEach((category) => {
//         normalizedData[month][category] = totalSales > 0 ? (monthData[category] / totalSales) : 0;
//       });
//     });

//     console.log('Normalized Data:', normalizedData);

//     return normalizedData;
//   };

//   // Prepare the data for Plotly traces
//   const preparePlotlyTraces = (normalizedData) => {
//     const categories = [...new Set(chartData.map(item => item.price_change_category).filter(Boolean))];
//     const months = [...new Set(chartData.map(item => item.month).filter(Boolean))];

//     return categories.map((category) => {
//       const yValues = months.map((month) => normalizedData[month]?.[category] || 0);
//       const textLabels = yValues.map(value => `${(value * 100).toFixed(1)}%`); // Convert to percentage

//       return {
//         x: months,
//         y: yValues,
//         text: textLabels,
//         textposition: 'inside',
//         textposition: 'inside', // Center text inside each bar segment
//         texttemplate: '%{text}', // Use text labels directly
//         type: 'bar',
//         name: category,
//         marker: { color: getColorForCategory(category) },
//       };
//     });
//   };

//   const getColorForCategory = (category) => {
//     switch (category) {
//       case 'No Sales Last Year':
//         return '#eaed21'; // Light Yellow
//       case 'Less than -9%':
//         return '#f55958';
//       case '-9% to -6%':
//         return '#f2a1a0'; // Light Red
//       case '-6% to -3%':
//         return '#f2a1a0'; // Light Red
//       case '-3% to 0%':
//         return '#eff777'; // Light Blue
//       case '0%':
//         return '#94f7a0';
//       case '0% to 3%':
//         return '#eff777'; // Light Purple
//       case '3% to 6%':
//         return '#93f59e';
//       case '6% to 9%':
//         return '#61f271';
//       case '9% to 12%':
//         return '#36f54b';
//       case '12% to 15%':
//         return '#0cf225';
//       case '15% to 18%':
//         return '#1dc430';
//       case 'Greater than 18%':
//         return '#04bf19';
//       case 'No Sales This Year':
//         return '#eaed21'; // Light Purple
//       default:
//         return '#d62728';
//     }
//   };

//   // Process and prepare traces for Plotly
//   const normalizedData = processChartData();
//   const traces = preparePlotlyTraces(normalizedData);

//   return (
//     <div>
//       {isLoading ? (
//         <p>Loading...</p>
//       ) : (
//         <Plot
//           data={traces}
//           layout={{
//             title: 'Customer Sales by Price Change Category',
//             barmode: 'stack',
//             xaxis: {
//             //   title: 'Month',
//               tickfont: {
//                 size: 10,
//                 color: 'grey',
//               },
//               automargin: true,
//             //   tickangle: -45,
//             },
//             height: 300,
//             width: '1250',
//             yaxis: {
//             //   title: 'Percentage of Net Sales',
//               tickformat: '.0%',
//               tickfont: {
//                 size: 10,
//                 color: 'grey',
//               },
//               automargin: true,
//             },

            
//             margin: {
//                 t: 30, // Reduce the top margin
//                 b: 40, // Bottom margin
//                 l: 50, // Left margin
//                 r: 50, // Right margin
//               },
//             showlegend: true,
//             legend: {
//               itemclick: 'toggleothers',  // Toggle only the clicked item
//               itemdoubleclick: 'toggle',  // Double-click to show/hide the clicked trace
//             },
//           }}
//           config={{ responsive: true }}
//         />
//       )}
//     </div>
//   );
// };

// export default StackedBarchart;







import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import axios from 'axios';
import { BASE_URL } from '../../common/baseUrl';

const StackedBarchart = ({
  selectedYear,
  selectedBusiness,
  selectedVertical,
  selectedCustomer,
  selectedMaterial,
  selectedKeyaccount,
  selectedProductLine,
}) => {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showOverallChart, setShowOverallChart] = useState(false); // New state for toggle

  useEffect(() => {
    const fetchTotalsData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/price-category`, {
          params: {
            year: selectedYear || undefined,
            business: selectedBusiness || undefined,
            vertical: selectedVertical || undefined,
            customer: selectedCustomer || undefined,
            material: selectedMaterial || undefined,
            keyaccount: selectedKeyaccount || undefined,
            productline: selectedProductLine || undefined,
          },
        });
        console.log('API response:', response.data);
        setChartData(response.data.monthly_summary);
      } catch (error) {
        console.error('Error fetching totals data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data if any filters are selected
    if (
      selectedYear ||
      selectedBusiness ||
      selectedCustomer ||
      selectedMaterial ||
      selectedVertical ||
      selectedKeyaccount ||
      selectedProductLine
    ) {
      fetchTotalsData();
    }
  }, [
    selectedYear,
    selectedBusiness,
    selectedVertical,
    selectedCustomer,
    selectedMaterial,
    selectedKeyaccount,
    selectedProductLine,
  ]);

  // Process the chart data into a format suitable for plotting
  const processChartData = () => {
    // Group data by month and category, filtering out null or undefined values
    const dataByMonth = {};
    chartData.forEach(({ month, month_customer_sales, price_change_category }) => {
      if (month == null || price_change_category == null || month_customer_sales == null) return;

      if (!dataByMonth[month]) {
        dataByMonth[month] = {};
      }
      if (!dataByMonth[month][price_change_category]) {
        dataByMonth[month][price_change_category] = 0;
      }
      dataByMonth[month][price_change_category] += month_customer_sales;
    });

    // Normalize data by calculating the percentage of total sales for each category in each month
    const normalizedData = {};
    Object.keys(dataByMonth).forEach((month) => {
      const monthData = dataByMonth[month];
      const totalSales = Object.values(monthData).reduce((sum, sales) => sum + (sales || 0), 0); // Sum sales while ignoring nulls

      // Check for zero total sales to avoid division by zero
      normalizedData[month] = {};
      Object.keys(monthData).forEach((category) => {
        normalizedData[month][category] = totalSales > 0 ? (monthData[category] / totalSales) : 0;
      });
    });

    console.log('Normalized Data:', normalizedData);

    return normalizedData;
  };

  // Prepare the data for Plotly traces
  const preparePlotlyTraces = (normalizedData) => {
    const categories = [...new Set(chartData.map(item => item.price_change_category).filter(Boolean))];
    const months = [...new Set(chartData.map(item => item.month).filter(Boolean))];

    return categories.map((category) => {
      const yValues = months.map((month) => normalizedData[month]?.[category] || 0);
      const textLabels = yValues.map(value => `${(value * 100).toFixed(1)}%`); // Convert to percentage

      return {
        x: months,
        y: yValues,
        text: textLabels,
        textposition: 'inside',
        texttemplate: '%{text}', // Use text labels directly
        type: 'bar',
        name: category,
        marker: { color: getColorForCategory(category) },
      };
    });
  };

  const getColorForCategory = (category) => {
    switch (category) {
      case 'No Sales Last Year':
        return '#90d9e8'; 
      case 'Less than -9%':
        return '#e3304b';
      case '-9% to -6%':
        return '#f55958'; 
      case '-6% to -3%':
        return '#f2a1a0'; 
      case '-3% to 0%':
        return '#05ecf0'; 
      case '0%':
        return '#cc93ed';
      case '0% to 3%':
        return '#33c1c8'; 
      case '3% to 6%':
        return '#759ef0';
      case '6% to 9%':
        return '#4866a1';
      case '9% to 12%':
        return '#04b0b3';
      case '12% to 15%':
        return '#8169f5';
      case '15% to 18%':
        return '#437afa';
      case 'Greater than 18%':
        return '#00b5ef';
      case 'No Sales This Year':
        return '#8ee3e6'; // Light Purple
      default:
        return '#d62728';
    }
  };

  // Process and prepare traces for Plotly
  const normalizedData = processChartData();
  const traces = preparePlotlyTraces(normalizedData);

  // Function to handle button click
  const toggleChart = () => {
    setShowOverallChart(!showOverallChart);
  };

  return (
    <div style={{ position: 'relative' }}>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <Plot
            data={traces}
            layout={{
              title:{
                text:'Customer Sales by Price Change Category',
                font:{
                    size:13
                }
              } ,
              barmode: 'stack',
              xaxis: {
                tickfont: {
                  size: 10,
                  color: 'grey',
                },
                automargin: true,
              },
              height: 220,
              width: '1250',
              yaxis: {
                tickformat: '.0%',
                tickfont: {
                  size: 10,
                  color: 'grey',
                },
                automargin: true,
              },
              margin: {
                t: 20, // Reduce the top margin
                b: 20, // Bottom margin
                l: 50, // Left margin
                r: 50, // Right margin
              },
              showlegend: true,
              legend: {
                itemclick: 'toggleothers',  // Toggle only the clicked item
                itemdoubleclick: 'toggle',  // Double-click to show/hide the clicked trace
              },
            }}
            config={{ responsive: true }}
          />
          {/* Button for toggling the chart */}
        </>
      )}
    </div>
  );
};

export default StackedBarchart;
