/**
 * Higher-order component that checks if the user is authenticated before rendering the wrapped component.
 * If the user is not authenticated, it redirects to the login page.
 *
 * @param {WithAuthTypes} props - The props for the component.
 * @returns {React.ReactNode} - The wrapped component or a redirect to the login page.
 */
const WithAuth = (props) => {
    const token = localStorage.getItem("accessToken");
  
    if (token) {
      return { ...props.children };
    } else {
      return handleLogout();
    }
  };
  
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    window.location.href = "/login";
  };
  
  export default WithAuth;
  