import { useState } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import classes from "./login.module.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import MicrosoftIcon from "./Microsoft";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import Logo from "../../components/common/Navbar/Logo.jpg"

const Login = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const handleNavigateToHome = () => navigate("/");

  const handleMicrosoftLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        const { accessToken } = response;
        localStorage.setItem("accessToken", accessToken);
        handleNavigateToHome();
      })
      .catch((err) => {
        console.error(err, "@err");
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <div className={classes.logoContainer}></div>
          <div className={classes.leftContainer}>
            <span className={classes.loginTitle}>Login</span>
            <span className={classes.loginSubTitle}>Get Stared today</span>
            <div
              className={classes.microsoftButton}
              onClick={handleMicrosoftLogin}
            >
              <MicrosoftIcon />
              <span>Sign in with Microsoft</span>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div className={classes.rightContainerParent}>
            <div className={classes.rightContainer}>
              <div>
                <img src={Logo} width={"200px"} />
              </div>
              <span className={classes.loginTitleRightCnt}>
                For Chemical and Industrial Companies
              </span>
              <span className={classes.loginDescription}>
                Increase revenues. Improve margins. Sell more of the right
                products at the right prices to the right customers at the right
                costs How? Segment Customers. Price to value. Optimize
                cost-to-serve and enhance cash flows. Plug leakages that eat up
                profits.
              </span>
              <div className={classes.imageCnt}></div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
