import React, { useState, useEffect } from 'react';
// import '../App.css';
import './PricingDetail.css';
import axios from 'axios';
import Filter from '../../components/common/Filter/Filter.jsx';
import SummaryStats from '../../components/common/Totals/SummaryStats.jsx';
import CustomBubbleChart from '../../components/opportunity_why/BubbleChart/CustomBubbleChart.jsx';
import Navbar from '../../components/common/Navbar/Navbar.jsx';
import DataTable from '../../components/opportunity_why/Table/DataTable.jsx';
import { BASE_URL } from '../../components/common/baseUrl.js';

function OpportunityWhy() {
    const [selectedYear, setSelectedYear] = useState('2023');
    const [selectedBusiness, setSelectedBusiness] = useState('');
    const [selectedPricingGroup, setSelectedPricingGroup] = useState('');
    const [selectedCustomerSize, setSelectedCustomerSize] = useState('');
    const [selectedSalesManager, setSelectedSalesManager] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedVertical, setSelectedVertical] = useState('')
    const [years, setYears] = useState([]);
    const [businesses, setBusinesses] = useState([]);
    const [pricingGroups, setPricingGroups] = useState([]);
    const [customerSizes, setCustomerSizes] = useState([]);
    const [salesManagers, setSalesManagers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchFilters();
    }, []);

    const fetchFilters = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/pricingdata`);
            const data = response.data;

            const uniqueYears = [''].concat([...new Set(data.map(item => item.year))]);
            const uniqueBusinesses = [''].concat([...new Set(data.map(item => item.business))]);
            const uniquePricingGroups = [''].concat([...new Set(data.map(item => item.pricing_group))]);
            const uniqueCustomerSizes = [''].concat([...new Set(data.map(item => item.customer_size))]);
            const uniqueSalesManagers = [''].concat([...new Set(data.map(item => item.sales_manager))]);

            setYears(uniqueYears);
            setBusinesses(uniqueBusinesses);
            setPricingGroups(uniquePricingGroups);
            setCustomerSizes(uniqueCustomerSizes);
            setSalesManagers(uniqueSalesManagers);
        } catch (error) {
            console.error('Error fetching filter data:', error);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className="PricingDetail">
            <Navbar />
            <div className="main-content-why">
                <div className="header-container-why">
                    <div className='title-filter-why'>
                        <div className='page-title-why'>
                           <p>Opportunity Why</p>
                        </div>
                        <div className="filter-container-why">
                            <Filter
                                years={years}
                                businesses={businesses}
                                pricingGroups={pricingGroups}
                                customerSizes={customerSizes}
                                salesManagers={salesManagers}
                                selectedYear={selectedYear}
                                selectedBusiness={selectedBusiness}
                                selectedPricingGroup={selectedPricingGroup}
                                selectedCustomerSize={selectedCustomerSize}
                                selectedSalesManager={selectedSalesManager}
                                onYearChange={setSelectedYear}
                                onBusinessChange={setSelectedBusiness}
                                onPricingGroupChange={setSelectedPricingGroup}
                                onCustomerSizeChange={setSelectedCustomerSize}
                                onSalesManagerChange={setSelectedSalesManager}
                                showCustomerSizeFilter={true}
                            />
                        </div>
                    </div>
                    <div className="summary-container-why">
                        <SummaryStats
                            selectedYear={selectedYear}
                            selectedBusiness={selectedBusiness}
                            selectedPricingGroup={selectedPricingGroup}
                            selectedCustomerSize={selectedCustomerSize}
                            selectedSalesManager={selectedSalesManager}
                        />
                    </div>
                </div>
                <div className="content-container-why">
                    <div className="chart-container-why">
                        <div className='text-lines'>
                            <p id='Main-Line'>Many customers are priced well below their pricing power</p>
                            <p id='Sub-Line'>Customers below the diagonal have opportunities to increase since their pocket margin does not reflect their pricing power</p>
                        </div>
                        <CustomBubbleChart
                            selectedYear={selectedYear}
                            selectedBusiness={selectedBusiness}
                            selectedPricingGroup={selectedPricingGroup}
                            selectedCustomerSize={selectedCustomerSize}
                            selectedSalesManager={selectedSalesManager}
                        />
                    </div>
                    <div className="data-table-container-why">
                        <DataTable
                            selectedYear={selectedYear}
                            selectedBusiness={selectedBusiness}
                            selectedPricingGroup={selectedPricingGroup}
                            selectedCustomerSize={selectedCustomerSize}
                            selectedSalesManager={selectedSalesManager}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OpportunityWhy;

