// import React, { useState, useEffect } from 'react';
// import Navbar from '../../components/common/Navbar/Navbar';
// import './PriceChange.css';
// import axios from 'axios';
// import { BASE_URL } from '../../components/common/baseUrl';
// import PriceCategoryFilter from '../../components/price_category/PriceCategoryFilter/PriceCategoryFilter';
// import PriceTotals from '../../components/price_category/PriceTotals/PriceTotals';
// import PriceCategoryTable from '../../components/price_category/PriceCategoryTable/PriceCategoryTable';
// import StackedBarchart from '../../components/price_category/StackedBarchart/StackedBarchart';
// import OverallStackedBarchart from '../../components/price_category/StackedBarchart/OverallStackedBarchart';

// function PriceChange() {
//     const [selectedYear, setSelectedYear] = useState('2023');
//     const [selectedBusiness, setSelectedBusiness] = useState('');
//     const [selectedVertical, setSelectedVertical] = useState('');
//     const [selectedCustomer, setSelectedCustomer] = useState(null);
//     const [selectedMaterial, setselectedMaterial] = useState('');
//     const [selectedProductLine, setselectedProductLine] = useState('');
//     const [selectedKeyaccount, setselectedKeyaccount] = useState(null);
//     const [years, setYears] = useState([]);
//     const [businesses, setBusinesses] = useState([]);
//     const [verticals, setVerticals] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);
//     const [customers, setCustomers] = useState([]);
//     const [materials, setMaterials] = useState([]);
//     const [keyaccounts, setKeyaccount] = useState([]);
//     const [productlines, setProductlines] = useState([]);

//     useEffect(() => {
//         fetchFilters();
//     }, []);

//     const fetchFilters = async () => {
//         setIsLoading(true);
//         try {
//             const [pricingDataRes, customersRes, materialRes] = await Promise.all([
//                 axios.get(`${BASE_URL}/api/pricingdata/`),
//                 axios.get(`${BASE_URL}/api/customers/`),
//                 axios.get(`${BASE_URL}/api/materials/`),
//             ]);

//             const pricingData = pricingDataRes.data;
//             const customersData = customersRes.data;
//             const materialData = materialRes.data;

//             const uniqueYears = [...new Set(pricingData.map(item => item.year))];
//             const uniqueBusinesses = [...new Set(pricingData.map(item => item.business))];
//             const uniqueVerticals = [''].concat([...new Set(customersData.map(item => item.ship_to_vertical))]);
//             const uniqueKeyaccounts = [''].concat([...new Set(customersData.map(item => item.key_account))]);
//             const uniqueCustomers = [''].concat([...new Set(customersData.map(item => item.customer_name))]);
//             const uniqueProductlines = [''].concat([...new Set(materialData.map(item => item.product_line))]);
//             const uniqueProducts = [''].concat([...new Set(materialData.map(item => item.product_name))]);

//             setYears(uniqueYears);
//             setBusinesses(uniqueBusinesses);
//             setVerticals(uniqueVerticals);
//             setKeyaccount(uniqueKeyaccounts);
//             setCustomers(uniqueCustomers);
//             setProductlines(uniqueProductlines);
//             setMaterials(uniqueProducts);
//         } catch (error) {
//             console.error('Error fetching filter data:', error);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const handleYearChange = (year) => {
//         setSelectedYear(year);
//     };

//     const handleBusinessChange = (business) => {
//         setSelectedBusiness(business);
//     };

//     const handleCustomerChange = (customer) => {
//         setSelectedCustomer(customer);
//     };

//     const handleVerticalChange = (vertical) => {
//         setSelectedVertical(vertical);
//     };

//     const handleMaterialChange = (product) => {
//         setselectedMaterial(product);
//     };

//     const handleKeyaccountChange = (keyaccount) => {
//         setselectedKeyaccount(keyaccount);
//     };

//     const handleProductlineChange = (productline) => {
//         setselectedProductLine(productline);
//     };

//     const handleFilterChange = (filterModel) => {
//         console.log('Filter Model:', filterModel);
//         if (filterModel.productline) {
//             setselectedProductLine(filterModel.productline.values[0]);
//         }
//         if (filterModel.key_account) {
//             setselectedKeyaccount(filterModel.key_account.values[0]);
//         }
//         if (filterModel.customer_name) {
//             setSelectedCustomer(filterModel.customer_name.values[0]);
//         }
//         if (filterModel.material_name) {
//             setselectedMaterial(filterModel.material_name.values[0]);
//         }
//     };

//     return (
//         <div className='price-category'>
//             <Navbar />
//             <div className='main-content-price-category'>
//                 <div className='head-container-price-category'>
//                     <div className='page-title-filter-price-category'>
//                         <div className='page-title-price-category'>
//                             <p>YoY Price View</p>
//                         </div>
//                         <div className='filter-price-category'>
//                             <PriceCategoryFilter
//                                 years={years}
//                                 businesses={businesses}
//                                 customers={customers}
//                                 materials={materials}
//                                 verticals={verticals}
//                                 keyaccounts={keyaccounts}
//                                 productlines={productlines}
//                                 selectedYear={selectedYear}
//                                 selectedBusiness={selectedBusiness}
//                                 selectedCustomer={selectedCustomer}
//                                 selectedMaterial={selectedMaterial}
//                                 selectedVertical={selectedVertical}
//                                 selectedKeyaccount={selectedKeyaccount}
//                                 selectedProductLine={selectedProductLine}
//                                 onYearChange={handleYearChange}
//                                 onBusinessChange={handleBusinessChange}
//                                 onCustomerChange={handleCustomerChange}
//                                 onMaterialChange={handleMaterialChange}
//                                 onVerticalChange={handleVerticalChange}
//                                 onKeyaccountChange={handleKeyaccountChange}
//                                 onProductlineChange={handleProductlineChange}
//                                 isLoading={isLoading}
//                             />
//                         </div>
//                     </div>
//                     <div className='totals-price-category'>
//                         <PriceTotals
//                             selectedYear={selectedYear}
//                             selectedBusiness={selectedBusiness}
//                             selectedCustomer={selectedCustomer}
//                             selectedMaterial={selectedMaterial}
//                             selectedVertical={selectedVertical}
//                             selectedKeyaccount={selectedKeyaccount}
//                             selectedProductLine={selectedProductLine}
//                         />
//                     </div>
//                 </div>
//                 <div className='table-container-price-category'>
//                     <StackedBarchart
//                         selectedYear={selectedYear}
//                         selectedBusiness={selectedBusiness}
//                         selectedCustomer={selectedCustomer}
//                         selectedMaterial={selectedMaterial}
//                         selectedVertical={selectedVertical}
//                         selectedKeyaccount={selectedKeyaccount}
//                         selectedProductLine={selectedProductLine}
//                         onProductLineChange={handleProductlineChange}
//                         onKeyaccountChange={handleKeyaccountChange}
//                         onCustomerChange={handleCustomerChange}
//                         onMaterialChange={handleMaterialChange}
//                         onFilterChange={handleFilterChange}
//                     />
//                 </div>
//                 <div className='table-container-price-category'>
//                     <OverallStackedBarchart
//                         selectedYear={selectedYear}
//                         selectedBusiness={selectedBusiness}
//                         selectedCustomer={selectedCustomer}
//                         selectedMaterial={selectedMaterial}
//                         selectedVertical={selectedVertical}
//                         selectedKeyaccount={selectedKeyaccount}
//                         selectedProductLine={selectedProductLine}
//                         onProductLineChange={handleProductlineChange}
//                         onKeyaccountChange={handleKeyaccountChange}
//                         onCustomerChange={handleCustomerChange}
//                         onMaterialChange={handleMaterialChange}
//                         onFilterChange={handleFilterChange}
//                     />
//                 </div>
//                 <div className='table-container-price-category'>
//                     <PriceCategoryTable
//                         selectedYear={selectedYear}
//                         selectedBusiness={selectedBusiness}
//                         selectedCustomer={selectedCustomer}
//                         selectedMaterial={selectedMaterial}
//                         selectedVertical={selectedVertical}
//                         selectedKeyaccount={selectedKeyaccount}
//                         selectedProductLine={selectedProductLine}
//                         onProductLineChange={handleProductlineChange}
//                         onKeyaccountChange={handleKeyaccountChange}
//                         onCustomerChange={handleCustomerChange}
//                         onMaterialChange={handleMaterialChange}
//                         onFilterChange={handleFilterChange}
//                     />
//                 </div>

//             </div>
//         </div>
//     );
// }

// export default PriceChange;







// import React, { useState, useEffect } from 'react';
// import Navbar from '../../components/common/Navbar/Navbar';
// import './PriceChange.css';
// import axios from 'axios';
// import { BASE_URL } from '../../components/common/baseUrl';
// import PriceCategoryFilter from '../../components/price_category/PriceCategoryFilter/PriceCategoryFilter';
// import PriceTotals from '../../components/price_category/PriceTotals/PriceTotals';
// import PriceCategoryTable from '../../components/price_category/PriceCategoryTable/PriceCategoryTable';
// import StackedBarchart from '../../components/price_category/StackedBarchart/StackedBarchart';
// import OverallStackedBarchart from '../../components/price_category/StackedBarchart/OverallStackedBarchart';

// function PriceChange() {
//     const [selectedYear, setSelectedYear] = useState('2023');
//     const [selectedBusiness, setSelectedBusiness] = useState('');
//     const [selectedVertical, setSelectedVertical] = useState('');
//     const [selectedCustomer, setSelectedCustomer] = useState(null);
//     const [selectedMaterial, setselectedMaterial] = useState('');
//     const [selectedProductLine, setselectedProductLine] = useState('');
//     const [selectedKeyaccount, setselectedKeyaccount] = useState(null);
//     const [years, setYears] = useState([]);
//     const [businesses, setBusinesses] = useState([]);
//     const [verticals, setVerticals] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);
//     const [customers, setCustomers] = useState([]);
//     const [materials, setMaterials] = useState([]);
//     const [keyaccounts, setKeyaccount] = useState([]);
//     const [productlines, setProductlines] = useState([]);
//     const [showOverallChart, setShowOverallChart] = useState(false); // New state for chart toggle

//     useEffect(() => {
//         fetchFilters();
//     }, []);

//     const fetchFilters = async () => {
//         setIsLoading(true);
//         try {
//             const [pricingDataRes, customersRes, materialRes] = await Promise.all([
//                 axios.get(`${BASE_URL}/api/pricingdata/`),
//                 axios.get(`${BASE_URL}/api/customers/`),
//                 axios.get(`${BASE_URL}/api/materials/`),
//             ]);

//             const pricingData = pricingDataRes.data;
//             const customersData = customersRes.data;
//             const materialData = materialRes.data;

//             const uniqueYears = [...new Set(pricingData.map(item => item.year))];
//             const uniqueBusinesses = [...new Set(pricingData.map(item => item.business))];
//             const uniqueVerticals = [''].concat([...new Set(customersData.map(item => item.ship_to_vertical))]);
//             const uniqueKeyaccounts = [''].concat([...new Set(customersData.map(item => item.key_account))]);
//             const uniqueCustomers = [''].concat([...new Set(customersData.map(item => item.customer_name))]);
//             const uniqueProductlines = [''].concat([...new Set(materialData.map(item => item.product_line))]);
//             const uniqueProducts = [''].concat([...new Set(materialData.map(item => item.product_name))]);

//             setYears(uniqueYears);
//             setBusinesses(uniqueBusinesses);
//             setVerticals(uniqueVerticals);
//             setKeyaccount(uniqueKeyaccounts);
//             setCustomers(uniqueCustomers);
//             setProductlines(uniqueProductlines);
//             setMaterials(uniqueProducts);
//         } catch (error) {
//             console.error('Error fetching filter data:', error);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const handleYearChange = (year) => {
//         setSelectedYear(year);
//     };

//     const handleBusinessChange = (business) => {
//         setSelectedBusiness(business);
//     };

//     const handleCustomerChange = (customer) => {
//         setSelectedCustomer(customer);
//     };

//     const handleVerticalChange = (vertical) => {
//         setSelectedVertical(vertical);
//     };

//     const handleMaterialChange = (product) => {
//         setselectedMaterial(product);
//     };

//     const handleKeyaccountChange = (keyaccount) => {
//         setselectedKeyaccount(keyaccount);
//     };

//     const handleProductlineChange = (productline) => {
//         setselectedProductLine(productline);
//     };

//     const handleFilterChange = (filterModel) => {
//         console.log('Filter Model:', filterModel);
//         if (filterModel.productline) {
//             setselectedProductLine(filterModel.productline.values[0]);
//         }
//         if (filterModel.key_account) {
//             setselectedKeyaccount(filterModel.key_account.values[0]);
//         }
//         if (filterModel.customer_name) {
//             setSelectedCustomer(filterModel.customer_name.values[0]);
//         }
//         if (filterModel.material_name) {
//             setselectedMaterial(filterModel.material_name.values[0]);
//         }
//     };

//     const toggleChart = () => {
//         setShowOverallChart(!showOverallChart);
//     };

//     return (
//         <div className='price-category'>
//             <Navbar />
//             <div className='main-content-price-category'>
//                 <div className='head-container-price-category'>
//                     <div className='page-title-filter-price-category'>
//                         <div className='page-title-price-category'>
//                             <p>YoY Price View</p>
//                         </div>
//                         <div className='filter-price-category'>
//                             <PriceCategoryFilter
//                                 years={years}
//                                 businesses={businesses}
//                                 customers={customers}
//                                 materials={materials}
//                                 verticals={verticals}
//                                 keyaccounts={keyaccounts}
//                                 productlines={productlines}
//                                 selectedYear={selectedYear}
//                                 selectedBusiness={selectedBusiness}
//                                 selectedCustomer={selectedCustomer}
//                                 selectedMaterial={selectedMaterial}
//                                 selectedVertical={selectedVertical}
//                                 selectedKeyaccount={selectedKeyaccount}
//                                 selectedProductLine={selectedProductLine}
//                                 onYearChange={handleYearChange}
//                                 onBusinessChange={handleBusinessChange}
//                                 onCustomerChange={handleCustomerChange}
//                                 onMaterialChange={handleMaterialChange}
//                                 onVerticalChange={handleVerticalChange}
//                                 onKeyaccountChange={handleKeyaccountChange}
//                                 onProductlineChange={handleProductlineChange}
//                                 isLoading={isLoading}
//                             />
//                         </div>
//                     </div>
//                     <div className='totals-price-category'>
//                         <PriceTotals
//                             selectedYear={selectedYear}
//                             selectedBusiness={selectedBusiness}
//                             selectedCustomer={selectedCustomer}
//                             selectedMaterial={selectedMaterial}
//                             selectedVertical={selectedVertical}
//                             selectedKeyaccount={selectedKeyaccount}
//                             selectedProductLine={selectedProductLine}
//                         />
//                     </div>
//                 </div>
                
//                 <button className='toggle-chart-button' onClick={toggleChart}>
//                     {showOverallChart ? 'High Level' : 'Detailed'}
//                 </button>
//                 <div className='stack-container-price-category'>
//                     {showOverallChart ? (
//                         <OverallStackedBarchart
//                             selectedYear={selectedYear}
//                             selectedBusiness={selectedBusiness}
//                             selectedCustomer={selectedCustomer}
//                             selectedMaterial={selectedMaterial}
//                             selectedVertical={selectedVertical}
//                             selectedKeyaccount={selectedKeyaccount}
//                             selectedProductLine={selectedProductLine}
//                             onProductLineChange={handleProductlineChange}
//                             onKeyaccountChange={handleKeyaccountChange}
//                             onCustomerChange={handleCustomerChange}
//                             onMaterialChange={handleMaterialChange}
//                             onFilterChange={handleFilterChange}
//                         />
//                     ) : (
//                         <StackedBarchart
//                             selectedYear={selectedYear}
//                             selectedBusiness={selectedBusiness}
//                             selectedCustomer={selectedCustomer}
//                             selectedMaterial={selectedMaterial}
//                             selectedVertical={selectedVertical}
//                             selectedKeyaccount={selectedKeyaccount}
//                             selectedProductLine={selectedProductLine}
//                             onProductLineChange={handleProductlineChange}
//                             onKeyaccountChange={handleKeyaccountChange}
//                             onCustomerChange={handleCustomerChange}
//                             onMaterialChange={handleMaterialChange}
//                             onFilterChange={handleFilterChange}
//                         />
//                     )}
//                 </div>
//                 <div className='table-container-price-category'>
//                     <PriceCategoryTable
//                         selectedYear={selectedYear}
//                         selectedBusiness={selectedBusiness}
//                         selectedCustomer={selectedCustomer}
//                         selectedMaterial={selectedMaterial}
//                         selectedVertical={selectedVertical}
//                         selectedKeyaccount={selectedKeyaccount}
//                         selectedProductLine={selectedProductLine}
//                         onProductLineChange={handleProductlineChange}
//                         onKeyaccountChange={handleKeyaccountChange}
//                         onCustomerChange={handleCustomerChange}
//                         onMaterialChange={handleMaterialChange}
//                         onFilterChange={handleFilterChange}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default PriceChange;








import React, { useState, useEffect } from 'react';
import Navbar from '../../components/common/Navbar/Navbar';
import './PriceChange.css';
import axios from 'axios';
import { BASE_URL } from '../../components/common/baseUrl';
import PriceCategoryFilter from '../../components/price_category/PriceCategoryFilter/PriceCategoryFilter';
import PriceTotals from '../../components/price_category/PriceTotals/PriceTotals';
import PriceCategoryTable from '../../components/price_category/PriceCategoryTable/PriceCategoryTable';
import StackedBarchart from '../../components/price_category/StackedBarchart/StackedBarchart';
import OverallStackedBarchart from '../../components/price_category/StackedBarchart/OverallStackedBarchart';
import OverallCategoryTable from '../../components/price_category/PriceCategoryTable/OverallCategoryTable';

function PriceChange() {
    const [selectedYear, setSelectedYear] = useState('2023');
    const [selectedBusiness, setSelectedBusiness] = useState('');
    const [selectedVertical, setSelectedVertical] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedMaterial, setselectedMaterial] = useState('');
    const [selectedProductLine, setselectedProductLine] = useState('');
    const [selectedKeyaccount, setselectedKeyaccount] = useState(null);
    const [years, setYears] = useState([]);
    const [businesses, setBusinesses] = useState([]);
    const [verticals, setVerticals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [keyaccounts, setKeyaccount] = useState([]);
    const [productlines, setProductlines] = useState([]);
    const [showOverallChart, setShowOverallChart] = useState(false); // State for chart toggle

    useEffect(() => {
        fetchFilters();
    }, []);

    const fetchFilters = async () => {
        setIsLoading(true);
        try {
            const [pricingDataRes, customersRes, materialRes] = await Promise.all([
                axios.get(`${BASE_URL}/pricingdata`),
                axios.get(`${BASE_URL}/customers`),
                axios.get(`${BASE_URL}/materials`),
            ]);

            const pricingData = pricingDataRes.data;
            const customersData = customersRes.data;
            const materialData = materialRes.data;

            const uniqueYears = [...new Set(pricingData.map(item => item.year))];
            const uniqueBusinesses = [...new Set(pricingData.map(item => item.business))];
            const uniqueVerticals = [''].concat([...new Set(customersData.map(item => item.ship_to_vertical))]);
            const uniqueKeyaccounts = [''].concat([...new Set(customersData.map(item => item.key_account))]);
            const uniqueCustomers = [''].concat([...new Set(customersData.map(item => item.customer_name))]);
            const uniqueProductlines = [''].concat([...new Set(materialData.map(item => item.product_line))]);
            const uniqueProducts = [''].concat([...new Set(materialData.map(item => item.product_name))]);

            setYears(uniqueYears);
            setBusinesses(uniqueBusinesses);
            setVerticals(uniqueVerticals);
            setKeyaccount(uniqueKeyaccounts);
            setCustomers(uniqueCustomers);
            setProductlines(uniqueProductlines);
            setMaterials(uniqueProducts);
        } catch (error) {
            console.error('Error fetching filter data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleYearChange = (year) => {
        setSelectedYear(year);
    };

    const handleBusinessChange = (business) => {
        setSelectedBusiness(business);
    };

    const handleCustomerChange = (customer) => {
        setSelectedCustomer(customer);
    };

    const handleVerticalChange = (vertical) => {
        setSelectedVertical(vertical);
    };

    const handleMaterialChange = (product) => {
        setselectedMaterial(product);
    };

    const handleKeyaccountChange = (keyaccount) => {
        setselectedKeyaccount(keyaccount);
    };

    const handleProductlineChange = (productline) => {
        setselectedProductLine(productline);
    };

    const handleFilterChange = (filterModel) => {
        console.log('Filter Model:', filterModel);
        if (filterModel.productline) {
            setselectedProductLine(filterModel.productline.values[0]);
        }
        if (filterModel.key_account) {
            setselectedKeyaccount(filterModel.key_account.values[0]);
        }
        if (filterModel.customer_name) {
            setSelectedCustomer(filterModel.customer_name.values[0]);
        }
        if (filterModel.material_name) {
            setselectedMaterial(filterModel.material_name.values[0]);
        }
    };

    const handleChartToggleChange = (event) => {
        setShowOverallChart(event.target.value === 'overall');
    };

    return (
        <div className='price-category'>
            <Navbar />
            <div className='main-content-price-category'>
                <div className='head-container-price-category'>
                    <div className='page-title-filter-price-category'>
                        <div className='page-title-price-category'>
                            <p>YoY Price View</p>
                        </div>
                        <div className='filter-price-category'>
                            <PriceCategoryFilter
                                years={years}
                                businesses={businesses}
                                customers={customers}
                                materials={materials}
                                verticals={verticals}
                                keyaccounts={keyaccounts}
                                productlines={productlines}
                                selectedYear={selectedYear}
                                selectedBusiness={selectedBusiness}
                                selectedCustomer={selectedCustomer}
                                selectedMaterial={selectedMaterial}
                                selectedVertical={selectedVertical}
                                selectedKeyaccount={selectedKeyaccount}
                                selectedProductLine={selectedProductLine}
                                onYearChange={handleYearChange}
                                onBusinessChange={handleBusinessChange}
                                onCustomerChange={handleCustomerChange}
                                onMaterialChange={handleMaterialChange}
                                onVerticalChange={handleVerticalChange}
                                onKeyaccountChange={handleKeyaccountChange}
                                onProductlineChange={handleProductlineChange}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                    <div className='totals-price-category'>
                        <PriceTotals
                            selectedYear={selectedYear}
                            selectedBusiness={selectedBusiness}
                            selectedCustomer={selectedCustomer}
                            selectedMaterial={selectedMaterial}
                            selectedVertical={selectedVertical}
                            selectedKeyaccount={selectedKeyaccount}
                            selectedProductLine={selectedProductLine}
                        />
                    </div>
                </div>
                

                <div className='stack-container-price-category'>
                     {/* Radio buttons for chart toggle */}
                <div className="radio-options">
                    <label>
                        <input
                            type="radio"
                            name="chartToggle"
                            value="detailed"
                            checked={!showOverallChart}
                            onChange={handleChartToggleChange}
                        />
                        Detailed
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="chartToggle"
                            value="overall"
                            checked={showOverallChart}
                            onChange={handleChartToggleChange}
                        />
                        High Level
                    </label>
                </div>
                    {showOverallChart ? (
                        <OverallStackedBarchart
                            selectedYear={selectedYear}
                            selectedBusiness={selectedBusiness}
                            selectedCustomer={selectedCustomer}
                            selectedMaterial={selectedMaterial}
                            selectedVertical={selectedVertical}
                            selectedKeyaccount={selectedKeyaccount}
                            selectedProductLine={selectedProductLine}
                            onProductLineChange={handleProductlineChange}
                            onKeyaccountChange={handleKeyaccountChange}
                            onCustomerChange={handleCustomerChange}
                            onMaterialChange={handleMaterialChange}
                            onFilterChange={handleFilterChange}
                        />
                    ) : (
                        <StackedBarchart
                            selectedYear={selectedYear}
                            selectedBusiness={selectedBusiness}
                            selectedCustomer={selectedCustomer}
                            selectedMaterial={selectedMaterial}
                            selectedVertical={selectedVertical}
                            selectedKeyaccount={selectedKeyaccount}
                            selectedProductLine={selectedProductLine}
                            onProductLineChange={handleProductlineChange}
                            onKeyaccountChange={handleKeyaccountChange}
                            onCustomerChange={handleCustomerChange}
                            onMaterialChange={handleMaterialChange}
                            onFilterChange={handleFilterChange}
                        />
                    )}
                </div>
                <div className='table-container-price-category'>
                {showOverallChart ? (
                    <OverallCategoryTable
                        selectedYear={selectedYear}
                        selectedBusiness={selectedBusiness}
                        selectedCustomer={selectedCustomer}
                        selectedMaterial={selectedMaterial}
                        selectedVertical={selectedVertical}
                        selectedKeyaccount={selectedKeyaccount}
                        selectedProductLine={selectedProductLine}
                        onProductLineChange={handleProductlineChange}
                        onKeyaccountChange={handleKeyaccountChange}
                        onCustomerChange={handleCustomerChange}
                        onMaterialChange={handleMaterialChange}
                        onFilterChange={handleFilterChange}
                    />
                ) : (

                    <PriceCategoryTable
                        selectedYear={selectedYear}
                        selectedBusiness={selectedBusiness}
                        selectedCustomer={selectedCustomer}
                        selectedMaterial={selectedMaterial}
                        selectedVertical={selectedVertical}
                        selectedKeyaccount={selectedKeyaccount}
                        selectedProductLine={selectedProductLine}
                        onProductLineChange={handleProductlineChange}
                        onKeyaccountChange={handleKeyaccountChange}
                        onCustomerChange={handleCustomerChange}
                        onMaterialChange={handleMaterialChange}
                        onFilterChange={handleFilterChange}
                        />
                )}
                </div>
            </div>
        </div>
    );
}

export default PriceChange;
