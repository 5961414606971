// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */
.content-container {
    display: flex;
    width: 100%;
}

.data-table-container, .chart-container {
    flex: 1 1;
    margin: 10px;
}

/* src/components/DataTable.css */
.data-table {
    overflow-x: auto;
    height: 530px;
    width: 600px;
    margin: 10px;
}

.data-table table {
    width: 100%;
    border-collapse: collapse;
}

.data-table th, .data-table td {
    border: 1px solid #ddd;
    padding: 3px;
}

.data-table th {
    background-color: #f2f2f2;
    text-align: left;
}

.small-font {
    font-size: 10px; /* Specific font size for headers and cells */
}

.selected {
    background-color: #d3d3d3;
  }



  `, "",{"version":3,"sources":["webpack://./src/components/opportunity_why/Table/DataTable.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,SAAO;IACP,YAAY;AAChB;;AAEA,iCAAiC;AACjC;IACI,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,eAAe,EAAE,6CAA6C;AAClE;;AAEA;IACI,yBAAyB;EAC3B","sourcesContent":["/* src/App.css */\n.content-container {\n    display: flex;\n    width: 100%;\n}\n\n.data-table-container, .chart-container {\n    flex: 1;\n    margin: 10px;\n}\n\n/* src/components/DataTable.css */\n.data-table {\n    overflow-x: auto;\n    height: 530px;\n    width: 600px;\n    margin: 10px;\n}\n\n.data-table table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.data-table th, .data-table td {\n    border: 1px solid #ddd;\n    padding: 3px;\n}\n\n.data-table th {\n    background-color: #f2f2f2;\n    text-align: left;\n}\n\n.small-font {\n    font-size: 10px; /* Specific font size for headers and cells */\n}\n\n.selected {\n    background-color: #d3d3d3;\n  }\n\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
