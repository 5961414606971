// export const BASE_URL = "http://127.0.0.1:8000/api";

// export const BASE_URL = "http://127.0.0.1:8000/api/v1/pricing";

// export const BASE_URL = "http://16.170.49.140:8000/api/v1/pricing";

export const BASE_URL = "https://scheduling.insightshigh.com/api/v1/pricing"






