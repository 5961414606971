import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../common/baseUrl';

const TopBottomVerticals = ({ selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedBusiness, selectedVertical }) => {
  const [verticalImpacts, setVerticalImpacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchVerticalImpacts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/pvm-calculations`, {
          params: {
            period: selectedPeriod,
            base_period: selectedBasePeriod,
            comparison_period: selectedCompPeriod,
            business: selectedBusiness,
            ship_to_vertical: selectedVertical,
          },
        });
        return response.data.pvm_results || [];
      } catch (error) {
        console.error('Error fetching data:', error);
        return [];
      }
    };

    const fetchData = async () => {
      setIsLoading(true);
      const impacts = await fetchVerticalImpacts();
      setVerticalImpacts(impacts);
      setIsLoading(false);
    };

    if (selectedBasePeriod && selectedCompPeriod) {
      fetchData();
    }
  }, [selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedBusiness, selectedVertical]);

  const getTopBottomVerticals = (impactData, impactType) => {
    const sortedData = [...impactData].sort((a, b) => b[impactType] - a[impactType]);
    return {
      top3: sortedData.slice(0, 3),
      bottom3: sortedData.slice(-3),
    };
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!Array.isArray(verticalImpacts) || verticalImpacts.length === 0) {
    return <p>No data available</p>;
  }

  const impactTypes = [
    'pure_price_impact',
    'fx_impact',
    'vol_mix_impact',
    'new_volume_impact',
    'lost_volume_impact'
  ];

  const renderTable = (impactData, impactType, title) => {
    const { top3, bottom3 } = getTopBottomVerticals(impactData, impactType);

    return (
      <div key={impactType}>
        <h3>{title}</h3>
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Vertical</th>
              <th>Impact Value</th>
            </tr>
          </thead>
          <tbody>
            {/* Render top verticals */}
            {top3.map((data, index) => (
              <tr key={`top-${index}`}>
                <td>{index + 1}</td>
                <td>{data.ship_to_vertical || 'N/A'}</td>
                <td>{data[impactType]}</td>
              </tr>
            ))}
            {/* Render bottom verticals */}
            {bottom3.map((data, index) => (
              <tr key={`bottom-${index}`}>
                <td>{bottom3.length - index}</td>
                <td>{data.ship_to_vertical || 'N/A'}</td>
                <td>{data[impactType]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      {impactTypes.map((impactType) => (
        <div key={impactType}>
          {renderTable(verticalImpacts, impactType, `${impactType.replace(/_/g, ' ')} Impact`)}
        </div>
      ))}
    </div>
  );
};

export default TopBottomVerticals;
