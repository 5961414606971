

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Plot from 'react-plotly.js';
// import { BASE_URL } from '../../common/baseUrl';

// const CascadingWaterfall = ({
//   selectedPeriod,
//   selectedBasePeriod,
//   selectedCompPeriod,
//   selectedBusiness,
//   selectedVertical,
//   showBreakdown,  
// }) => {
//   const [totals, setTotals] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const generateYearsArray = (startYear, endYear) => {
//     let years = [];
//     for (let year = startYear; year <= endYear; year++) {
//       years.push(year);
//     }
//     return years;
//   };

//   useEffect(() => {
//     const fetchTotalsForYear = async (baseYear, compYear) => {
//       try {
//         const response = await axios.get(`${BASE_URL}/api/pvm-calculations/`, {
//           params: {
//             period: selectedPeriod,
//             base_period: baseYear,
//             comparison_period: compYear,
//             business: selectedBusiness,
//             ship_to_vertical: selectedVertical,
//           },
//         });
//         return response.data.totals || {};
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setError('Failed to fetch data');
//         return null;
//       }
//     };

//     const fetchAllYearsData = async () => {
//       setIsLoading(true);
//       const years = generateYearsArray(selectedBasePeriod, selectedCompPeriod);
//       let allTotals = [];

//       for (let i = 0; i < years.length - 1; i++) {
//         const baseYear = years[i];
//         const compYear = years[i + 1];
//         const totalsForYear = await fetchTotalsForYear(baseYear, compYear);

//         if (totalsForYear) {
//           allTotals.push({
//             ...totalsForYear,
//             baseYear,
//             compYear,
//           });
//         }
//       }

//       setTotals(allTotals);
//       setIsLoading(false);
//     };

//     if (selectedBasePeriod && selectedCompPeriod) {
//       fetchAllYearsData();
//     } else {
//       setIsLoading(false); // Stop loading if periods are not selected
//     }
//   }, [selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedBusiness, selectedVertical]);

//   if (isLoading) {
//     return <p>Loading...</p>;
//   }

//   if (error) {
//     return <p>{error}</p>;
//   }

//   if (!Array.isArray(totals) || totals.length === 0) {
//     return <p>No data available</p>;
//   }

//   const formatCurrency = (value) => {
//     const sign = value < 0 ? '-' : '';
//     const absValue = Math.abs(value);

//     if (absValue >= 1000000000) {
//       const billions = absValue / 1000000000;
//       return `${sign}$${billions.toFixed(billions >= 10 ? 1 : 2)}B`;
//     } else if (absValue >= 1000000) {
//       const millions = absValue / 1000000;
//       return `${sign}$${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
//     } else {
//       return `${sign}$${absValue.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
//     }
//   };

//   const formatPercentage = (impactValue, baseNs) => {
//     if (baseNs === 0) return 'N/A';
//     const percentChange = (impactValue / baseNs) * 100;
//     return `${percentChange.toFixed(1)}%`;
//   };

//   const trimLabel = (label, baseYear, compYear) => {
//     const trimmedLabel = label.length > 10 ? label.slice(0, 10) : label;
//     const shortBaseYear = baseYear.toString().slice(-2);
//     const shortCompYear = compYear.toString().slice(-2);
//     return `${trimmedLabel} <br> (${shortBaseYear}-${shortCompYear})`;
//   };

//   const xValues = [];
//   const yValues = [];
//   const textValues = [];
//   const measure = [];

//   // Add the first year (base period) NS value explicitly
//   xValues.push(`${selectedBasePeriod} NS`);
//   yValues.push(totals[0]?.total_base_ns || 0);
//   textValues.push(formatCurrency(totals[0]?.total_base_ns || 0));
//   measure.push('relative');

//   totals.forEach((total) => {
//     const baseNs = total.total_base_ns || 0;

//     if (showBreakdown) {
//       // When breakdown is selected, show detailed impacts
//       xValues.push(
//         trimLabel('Pure Price', total.baseYear, total.compYear),
//         trimLabel('FX & Other', total.baseYear, total.compYear),
//         trimLabel('Vol', total.baseYear, total.compYear),
//         trimLabel('New Vol', total.baseYear, total.compYear),
//         trimLabel('Lost Vol', total.baseYear, total.compYear),
//         trimLabel('mix', total.baseYear, total.compYear),
//         `${total.compYear} NS`
//       );

//       yValues.push(
//         total.total_pure_price_impact,
//         total.total_other_price_impact,
//         total.total_volume_impact,
//         total.total_new_vol_impact,
//         total.total_lost_vol_impact,
//         total.total_mix_impact,
//         total.total_comp_ns
//       );

//       textValues.push(
//         formatCurrency(total.total_pure_price_impact),
//         formatCurrency(total.total_other_price_impact),
//         formatCurrency(total.total_volume_impact),
//         formatCurrency(total.total_new_vol_impact),
//         formatCurrency(total.total_lost_vol_impact),
//         formatCurrency(total.total_mix_impact),
//         `${formatCurrency(total.total_comp_ns)} <br> (${formatPercentage(total.total_comp_ns - baseNs, baseNs)})`
//       );

//       measure.push('relative', 'relative','relative',  'relative', 'relative', 'relative', 'total');
//     } else {
//       // When total PVM is selected, show total vol+mix impact
//       xValues.push(
//         trimLabel('Price', total.baseYear, total.compYear),
//         trimLabel('Volume', total.baseYear, total.compYear),
//         trimLabel('Mix', total.baseYear, total.compYear),
//         `${total.compYear} NS`
//       );

//       yValues.push(
//         total.total_price_impact,
//         total.overall_total_vol_impact, 
//         total.total_mix_impact,
//         total.total_comp_ns
//       );

//       textValues.push(
//         formatCurrency(total.total_price_impact),
//         formatCurrency(total.overall_total_vol_impact),
//         formatCurrency(total.total_mix_impact),
//         `${formatCurrency(total.total_comp_ns)} <br> (${formatPercentage(total.total_comp_ns - baseNs, baseNs)})`
//       );

//       measure.push('relative', 'relative', 'relative', 'total');
//     }
//   });

//   const baseNsY = yValues[0]; 
//   const compNsY = yValues[yValues.length - 1]; 


//   const data = [
//     {
//       type: 'waterfall',
//       orientation: 'v',
//       measure: measure,
//       x: xValues,
//       y: yValues,
//       text: textValues,
//       textposition: 'outside',
//       textfont: {
//         size: 12,
//       },
//       decreasing: { marker: { color: '#779ECB' } },
//       increasing: { marker: { color: '#779ECB' } },
//       totals: { marker: { color: '#434443' } },
//     },
//   ];

//   const layout = {
//     title: {
//       text: `Price-Volume-Mix Waterfall From ${selectedBasePeriod} to ${selectedCompPeriod} ${
//         selectedBusiness ? `for ${selectedBusiness}` : 'Across All Businesses'
//       }${
//         selectedVertical ? ` and for ${selectedVertical}` : ' And For All Verticals'
//       }`,
//       font: {
//         size: 12,
//         weight: 500, 
//       },
//     },
//     width: 800,
//     height: 550,
//     margin: {
//       t: 50,
//       b: 50,
//       l: 50,
//       r: 50,
//     },
//     xaxis: {
//       tickfont: {
//         size: 10,
//       },
//     },

  //   // shapes: [
  //   //   {
  //   //     type: 'line',
  //   //     x0: xValues[0],   // Start from the first x-value
  //   //     y0: baseNsY,       // Set y0 to a constant value, like 0 or baseNsY
  //   //     x1: xValues[xValues.length - 1], // End at the last x-value
  //   //     y1: baseNsY,       // Set y1 to the same value as y0 for a horizontal line
  //   //     line: {
  //   //       color: 'black',
  //   //       width: 0.5,
  //   //       dash: 'dashdot',
  //   //     },
  //   //   },

  //   //   ...totals.map((total, index) => {
  //   //     const baseYear = total.baseYear;
  //   //     const compYear = total.compYear;
  //   //     const yCompNs = yValues[measure.indexOf('total', index * 6)]; // Comp NS for this period
  //   //     return {
  //   //       type: 'line',
  //   //       x0: `${compYear} NS`, // x-value for the Comp NS
  //   //       y0: yCompNs,           // y-value at the Comp NS
  //   //       x1: `${compYear} NS`,  // Same x-value, vertical line
  //   //       y1: baseNsY,           // y-value at the baseline
  //   //       line: {
  //   //         color: 'black',
  //   //         width: 0.5,
  //   //         dash: 'dashdot', // Optional: make it a dashed line
  //   //       },
  //   //     };
  //   //   }),
  //   // ],
  // };

//   return <Plot data={data} layout={layout} />;
// };

// export default CascadingWaterfall;







import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import { BASE_URL } from '../../common/baseUrl';

const CascadingWaterfall = ({
  selectedPeriod,
  selectedBasePeriod,
  selectedCompPeriod,
  selectedBusiness,
  selectedVertical,
  showBreakdown,  
}) => {
  const [totals, setTotals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Modified function to generate years in reverse order
  const generateYearsArray = (startYear, endYear) => {
    let years = [];
    for (let year = startYear; year >= endYear; year--) {
      years.push(year);
    }
    return years;
  };

  useEffect(() => {
    const fetchTotalsForYear = async (baseYear, compYear) => {
      try {
        const response = await axios.get(`${BASE_URL}/pvm-calculations`, {
          params: {
            period: selectedPeriod,
            base_period: baseYear,
            comparison_period: compYear,
            business: selectedBusiness,
            ship_to_vertical: selectedVertical,
          },
        });
        return response.data.totals || {};
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data');
        return null;
      }
    };

    const fetchAllYearsData = async () => {
      setIsLoading(true);
      const years = generateYearsArray(selectedBasePeriod, selectedCompPeriod);
      let allTotals = [];

      // Iterating in reverse (descending) order
      for (let i = 0; i < years.length - 1; i++) {
        const baseYear = years[i];
        const compYear = years[i + 1];  // Now compYear is always less than baseYear
        const totalsForYear = await fetchTotalsForYear(baseYear, compYear);

        if (totalsForYear) {
          allTotals.push({
            ...totalsForYear,
            baseYear,
            compYear,
          });
        }
      }

      // Reverse the order to go from oldest to latest
      setTotals(allTotals.reverse());
      setIsLoading(false);
    };

    if (selectedBasePeriod && selectedCompPeriod) {
      fetchAllYearsData();
    } else {
      setIsLoading(false); // Stop loading if periods are not selected
    }
  }, [selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedBusiness, selectedVertical]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!Array.isArray(totals) || totals.length === 0) {
    return <p>No data available</p>;
  }

  const formatCurrency = (value) => {
    const sign = value < 0 ? '-' : '';
    const absValue = Math.abs(value);

    if (absValue >= 1000000000) {
      const billions = absValue / 1000000000;
      return `${sign}$${billions.toFixed(billions >= 10 ? 1 : 2)}B`;
    } else if (absValue >= 1000000) {
      const millions = absValue / 1000000;
      return `${sign}$${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
    } else {
      return `${sign}$${absValue.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    }
  };

  const formatPercentage = (impactValue, baseNs) => {
    if (baseNs === 0) return 'N/A';
    const percentChange = (impactValue / baseNs) * 100;
    return `${percentChange.toFixed(1)}%`;
  };

  const trimLabel = (label, baseYear, compYear) => {
    const trimmedLabel = label.length > 10 ? label.slice(0, 10) : label;
    const shortBaseYear = baseYear.toString().slice(-2);
    const shortCompYear = compYear.toString().slice(-2);
    return `${trimmedLabel} <br> (${shortBaseYear}-${shortCompYear})`;
  };

  const xValues = [];
  const yValues = [];
  const textValues = [];
  const measure = [];

  // Add the first year (comp period) NS value explicitly
  xValues.push(`${selectedCompPeriod} NS`);
  yValues.push(totals[0]?.total_comp_ns || 0);
  textValues.push(formatCurrency(totals[0]?.total_comp_ns || 0));
  measure.push('relative');

  totals.forEach((total) => {
    const baseNs = total.total_base_ns || 0;

    if (showBreakdown) {
      // When breakdown is selected, show detailed impacts
      xValues.push(
        trimLabel('Pure Price',total.compYear,total.baseYear),
        trimLabel('FX & Other',total.compYear,total.baseYear),
        trimLabel('Vol',total.compYear,total.baseYear),
        trimLabel('New Vol',total.compYear,total.baseYear),
        trimLabel('Lost Vol',total.compYear,total.baseYear),
        trimLabel('mix',total.compYear,total.baseYear),
        `${total.baseYear} NS`
      );

      yValues.push(
        total.total_pure_price_impact ,
        total.total_fx_other_impact ,
        total.total_volume_impact  ,
        total.total_new_vol_impact  ,
        total.total_lost_vol_impact ,
        total.total_mix_impact  ,
        total.total_base_ns
      );

      textValues.push(
        formatCurrency(total.total_pure_price_impact ),
        formatCurrency(total.total_fx_other_impact  ),
        formatCurrency(total.total_volume_impact  ),
        formatCurrency(total.total_new_vol_impact  ),
        formatCurrency(total.total_lost_vol_impact  ),
        formatCurrency(total.total_mix_impact  ),
        `${formatCurrency(total.total_base_ns)} <br> (${formatPercentage(total.total_base_ns - total.total_comp_ns, total.total_comp_ns)})`
      );

      measure.push('relative', 'relative','relative',  'relative', 'relative', 'relative', 'total');
    } else {
      // When total PVM is selected, show total vol+mix impact
      xValues.push(
        trimLabel('Price', total.compYear, total.baseYear ),
        trimLabel('Volume', total.compYear, total.baseYear ),
        trimLabel('Mix', total.compYear, total.baseYear ),
        `${total.baseYear} NS`
      );

      yValues.push(
        total.total_price_impact ,
        total.overall_total_vol_impact , 
        total.total_mix_impact  ,
        total.total_base_ns
      );

      textValues.push(
        formatCurrency(total.total_price_impact  ),
        formatCurrency(total.overall_total_vol_impact ),
        formatCurrency(total.total_mix_impact  ),
        `${formatCurrency(total.total_base_ns)} <br> (${formatPercentage(total.total_base_ns - total.total_comp_ns, total.total_comp_ns)})`
      );

      measure.push('relative', 'relative', 'relative', 'total');
    }
  });

  const data = [
    {
      type: 'waterfall',
      orientation: 'v',
      measure: measure,
      x: xValues,
      y: yValues,
      text: textValues,
      textposition: 'outside',
      textfont: {
        size: 12,
      },
      decreasing: { marker: { color: '#779ECB' } },
      increasing: { marker: { color: '#779ECB' } },
      totals: { marker: { color: '#434443' } },
    },
  ];

  const layout = {
    title: {
      text: `Price-Volume-Mix Waterfall From ${selectedCompPeriod} to ${selectedBasePeriod} ${
        selectedBusiness ? `for ${selectedBusiness}` : 'Across All Businesses'
      }${
        selectedVertical ? ` and for ${selectedVertical}` : ' And For All Verticals'
      }`,
      font: {
        size: 12,
        weight: 500, 
      },
    },
    width: 800,
    height: 550,
    margin: {
      t: 50,
      b: 50,
      l: 50,
      r: 50,
    },
    xaxis: {
      tickfont: {
        size: 10,
      },
    },


    //     shapes: [
    //   {
    //     type: 'line',
    //     x0: xValues[0],   // Start from the first x-value
    //     y0: baseNsY,       // Set y0 to a constant value, like 0 or baseNsY
    //     x1: xValues[xValues.length - 1], // End at the last x-value
    //     y1: baseNsY,       // Set y1 to the same value as y0 for a horizontal line
    //     line: {
    //       color: 'black',
    //       width: 0.5,
    //       dash: 'dashdot',
    //     },
    //   },

    //   ...totals.map((total, index) => {
    //     const baseYear = total.baseYear;
    //     const compYear = total.compYear;
    //     const yCompNs = yValues[measure.indexOf('total', index * 6)]; // Comp NS for this period
    //     return {
    //       type: 'line',
    //       x0: `${compYear} NS`, // x-value for the Comp NS
    //       y0: yCompNs,           // y-value at the Comp NS
    //       x1: `${compYear} NS`,  // Same x-value, vertical line
    //       y1: baseNsY,           // y-value at the baseline
    //       line: {
    //         color: 'black',
    //         width: 0.5,
    //         dash: 'dashdot', // Optional: make it a dashed line
    //       },
    //     };
    //   }),
    // ],
  };
    
  

  return <Plot data={data} layout={layout} />;
};

export default CascadingWaterfall;
