import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import './PriceCategoryTable.css';
import debounce from 'lodash.debounce';
import { BASE_URL } from '../../common/baseUrl';

const OverallCategoryTable = ({
  selectedYear,
  selectedBusiness,
  selectedVertical,
  selectedCustomer,
  selectedMaterial,
  selectedKeyaccount,
  selectedProductLine,
  onProductLineChange,
  onKeyaccountChange,
  onCustomerChange,
  onMaterialChange,
}) => {
  const [priceCategoryValues, setPriceCategoryValues] = useState([]);
  const [pinnedRowData, setPinnedRowData] = useState([]);
  const gridRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTotalsData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${BASE_URL}/price-category`, {
        params: {
          year: selectedYear || undefined,
          business: selectedBusiness || undefined,
          vertical: selectedVertical || undefined,
          customer: selectedCustomer || undefined,
          material: selectedMaterial || undefined,
          keyaccount: selectedKeyaccount || undefined,
          productline: selectedProductLine || undefined,
        },
      });
      console.log('API Response:', response.data);
      setPriceCategoryValues(response.data.yearly_summary || []);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedYear) {
      fetchTotalsData();
    } else {
      setPriceCategoryValues([]);
    }
  }, [
    selectedYear,
    selectedBusiness,
    selectedVertical,
    selectedCustomer,
    selectedMaterial,
    selectedKeyaccount,
    selectedProductLine,
  ]);

  useEffect(() => {
    const calculateTotals = () => {
      const totals = {
        key_account: 'Totals',
        customer_name: '',
        material_name: '',
        base_ns: 0,
        base_customer_sales: 0,
        base_vol: 0,
        base_avg: 0,
        prev_avg: 0,
        price_change_percent: 0,
      };

      let baseAvgCount = 0;
      let prevAvgCount = 0;
      let priceChangePercentCount = 0;

      priceCategoryValues.forEach((row) => {
        totals.base_ns += row.base_ns || 0;
        totals.base_customer_sales += row.base_customer_sales || 0;
        totals.base_vol += row.base_vol || 0;

        if (row.base_avg != null) {
          totals.base_avg += row.base_avg;
          baseAvgCount += 1;
        }

        if (row.prev_avg != null) {
          totals.prev_avg += row.prev_avg;
          prevAvgCount += 1;
        }

        if (row.price_change_percent != null) {
          totals.price_change_percent += row.price_change_percent;
          priceChangePercentCount += 1;
        }
      });

      totals.base_avg = baseAvgCount ? totals.base_avg / baseAvgCount : 0;
      totals.prev_avg = prevAvgCount ? totals.prev_avg / prevAvgCount : 0;
      totals.price_change_percent = priceChangePercentCount
        ? totals.price_change_percent / priceChangePercentCount
        : 0;

      setPinnedRowData([totals]);
    };

    calculateTotals();
  }, [priceCategoryValues]);


  const debouncedOnFilterChanged = useCallback(
    debounce(() => {
      if (gridRef.current) {
        const filterModel = gridRef.current.api.getFilterModel();
        const filteredNodes = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((node) => filteredNodes.push(node));

        if (filteredNodes.length > 0) {
          const firstRowData = filteredNodes[0].data;

          // Get filter values if filters are applied
          const keyAccountValue = firstRowData.key_account || null;
          const customerValue = firstRowData.customer_name || null;
          const materialValue = firstRowData.material_name || null;

          // Determine which values to pass to parent based on active filters
          if (filterModel['material_name']) {
            onMaterialChange(materialValue);
            onKeyaccountChange(keyAccountValue);
            onCustomerChange(customerValue);
          } else if (filterModel['customer_name']) {
            onCustomerChange(customerValue);
            onKeyaccountChange(keyAccountValue);
          } else if (filterModel['key_account']) {
            onKeyaccountChange(keyAccountValue);
          }

          console.log('Values passed to parent:', {
            keyAccountValue,
            customerValue,
            materialValue,
          });
        }
      }
    }, 1500),
    [onProductLineChange, onKeyaccountChange, onCustomerChange, onMaterialChange]
  );

  const handleRowClick = (event) => {
    const clickedKeyAccount = event.data.key_account;
    const clickedCustomer = event.data.customer_name;
    const clickedMaterial = event.data.material_name;

    // Toggle selection: if the clicked item is already selected, deselect it
    if (
      selectedKeyaccount === clickedKeyAccount &&
      selectedCustomer === clickedCustomer &&
      selectedMaterial === clickedMaterial
    ) {
      onKeyaccountChange(null); // Deselect key account
      onCustomerChange(null); // Deselect customer
      onMaterialChange(null); // Deselect material
    } else {
      onKeyaccountChange(clickedKeyAccount); // Select new key account
      onCustomerChange(clickedCustomer); // Select new customer
      onMaterialChange(clickedMaterial); // Select new material
    }
  };

  const clearFilters = () => {
    gridRef.current.api.setFilterModel(null);
    onKeyaccountChange(null);
    onCustomerChange(null);
    onMaterialChange(null);
  };

  const HorizontalBarRenderer = (params) => {
    const isPinnedRow = params.node.rowPinned;
    const value = params.value || 0;
    if (isPinnedRow) {
        const formattedValue = `$${value.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })}`;
        return <span>{formattedValue}</span>;
      }

    const maxValue = Math.max(
      ...priceCategoryValues.map((item) => item[params.colDef.field])
    );
    const percentage = (value / maxValue) * 100;

    let barColor;
    let displayValue;

    switch (params.colDef.field) {
      case 'base_vol':
        barColor = 'rgb(130, 175, 227)';
        displayValue = value.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        });
        break;
      case 'base_ns':
      case 'base_customer_sales':
      case 'base_avg':
        barColor = 'rgb(130, 175, 227)';
        displayValue = `$${value.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })}`;
        break;
      default:
        displayValue = `$${value.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })}`;
        break;
    }

    return (
      <div className="bar-cell">
        <span className="bar-value">{displayValue}</span>
        <div
          className="bar"
          style={{ width: `${percentage}%`, backgroundColor: barColor }}
        ></div>
      </div>
    );
  };

  const getCategoryStyle = (params) => {
    switch (params.value) {
        case 'No Sales Last Year':
            return { backgroundColor: '#a4a8ab'}; 
          case 'Less than -3%':
            return { backgroundColor: '#f58e9c'};
          case '-3% to 0%':
            return { backgroundColor: '#05ecf0'}; 
          case '0%':
            return { backgroundColor: '#cc93ed'};
          case '0% to 3%':
            return { backgroundColor: '#33c1c8'}; 
          case 'Greater than 3%':
            return { backgroundColor: '#2099f5'};
          case 'No Sales This Year':
            return { backgroundColor: '#8ee3e6'}; 
        //   default:
        //     return { backgroundColor: '#d62728'};
    }
  };

  const percentFormatter = (params) => {
    if (params.value != null) {
      return `${(params.value * 100).toFixed(1)}%`;
    }
    return '';
  };

  const netSalesHeader = `${selectedYear} Net Sales`;
  const customerSalesHeader = `${selectedYear} Customer Sales`;
  const volumeHeader = `${selectedYear} Volume`;
  const baseAvgHeader = `${selectedYear} Avg Price`;
  const prevAvgHeader = `${selectedYear - 1} Avg Price`;

//   // Function to determine row height dynamically
//   const getRowHeight = (params) => {
//     // Example: adjust based on content or add custom logic
//     return params.node.rowPinned ? 40 : 25; // Change these values for resizing
//   };

  const getRowHeight = (params) => {
    if (params.node.rowPinned) {
      // Set the height for the pinned (total) row; adjust as needed
      return 25; // Example height for the total row
    }
    // Set the height for the regular rows
    return 25; // Example height for regular rows
  };

  const columnDefs = [
    { headerName: 'Key Account', field: 'key_account',filter: 'agTextColumnFilter', width: 120 },
    { headerName: 'Customer Name', field: 'customer_name',filter: 'agTextColumnFilter', width: 160 },
    { headerName: 'Material Name', field: 'material_name',filter: 'agTextColumnFilter', width: 160 },
    // {
    //   headerName: 'Detailed Price Change Category',
    //   field: 'price_change_category',
    //   width: 180,
    //   cellStyle: getCategoryStyle,
    // },
    {
      headerName: 'Price Change Category',
      field: 'overall_price_change_category',
      width: 160,
      cellStyle: getCategoryStyle,
    },
    {
      headerName: netSalesHeader,
      field: 'base_ns',
      width: 120,
      cellRenderer: HorizontalBarRenderer,
    },
    {
      headerName: customerSalesHeader,
      field: 'base_customer_sales',
      width: 120,
      cellRenderer: HorizontalBarRenderer,
    },
    {
      headerName: volumeHeader,
      field: 'base_vol',
      width: 120,
      cellRenderer: HorizontalBarRenderer,
    },
    {
      headerName: baseAvgHeader,
      field: 'base_avg',
      width: 120,
      cellRenderer: HorizontalBarRenderer,
    },
    {
      headerName: prevAvgHeader,
      field: 'prev_avg',
      width: 120,
      cellRenderer: HorizontalBarRenderer,
    },
    {
      headerName: 'Price Change Percent',
      field: 'price_change_percent',
      width: 120,
      valueFormatter: percentFormatter,
    },
  ];

  return (
    <div className="ag-theme-alpine" style={{ height: '260px', width: '100%' }}>
        {/* <div className="title-button">
        <div className="clear-button">
          <button onClick={clearFilters} className="clear-filters-button">
            Clear Filters
          </button>
        </div>
      </div> */}
     {isLoading ? (
        <p>Loading...</p>
      ) : (
      <AgGridReact
        ref={gridRef}
        rowData={priceCategoryValues}
        columnDefs={columnDefs}
        pinnedBottomRowData={pinnedRowData}
        getRowHeight={getRowHeight}
        rowSelection="single"
        defaultColDef={{
              sortable: true,
              filter: true,
              resizable: true,       
           }}
        animateRows={true}
        onFilterChanged={debouncedOnFilterChanged}
        onRowClicked={handleRowClick}
      />
        )}
    </div>
  );
};

export default OverallCategoryTable;

