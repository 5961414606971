// import React from 'react';
// import './Filter.css';

// const Filter = ({
//   years = [2020, 2021, 2022, 2023],
//   businesses = [],
//   pricingGroups = [],
//   customerSizes = [],
//   salesManagers = [],
//   selectedYear,
//   selectedBusiness,
//   selectedPricingGroup,
//   selectedCustomerSize,
//   selectedSalesManager,
//   onYearChange,
//   onBusinessChange,
//   onPricingGroupChange,
//   onCustomerSizeChange,
//   onSalesManagerChange,
//   showCustomerSizeFilter = false,
//   showSalesManagerFilter = false
// }) => {
//   return (
//     <div className="filter">
//       <div className='filter-grid'>
//         <div className="filter-group">
//           {/* <label id='nav-label' htmlFor="year">Year:</label> */}
//           <select id="year" value={selectedYear} onChange={(e) => onYearChange(e.target.value)}>
//             <option value="">Year</option>
//             {years.map(year => (
//               <option key={year} value={year}>{year}</option>
//             ))}
//           </select>
//         </div>
//         <div className="filter-group">
//           {/* <label id='nav-label' htmlFor="business">Business:</label> */}
//           <select id="business" value={selectedBusiness} onChange={(e) => onBusinessChange(e.target.value)}>
//             <option value="">Business</option>
//             {businesses.map(business => (
//               <option key={business} value={business}>{business}</option>
//             ))}
//           </select>
//         </div>
//         <div className="filter-group right">
//           {/* <label id='nav-label' htmlFor="pricingGroup">Pricing Group:</label> */}
//           <select id="pricingGroup" value={selectedPricingGroup} onChange={(e) => onPricingGroupChange(e.target.value)}>
//             <option value="">Pricing Group</option>
//             {pricingGroups.map(pricingGroup => (
//               <option key={pricingGroup} value={pricingGroup}>{pricingGroup}</option>
//             ))}
//           </select>
//         </div>
//         {showCustomerSizeFilter && (
//           <div className="filter-group right">
//             {/* <label id='nav-label' htmlFor="customerSize">Customer Size:</label> */}
//             <select id="customerSize" value={selectedCustomerSize} onChange={(e) => onCustomerSizeChange(e.target.value)}>
//               <option value="">Customer Size</option>
//               {customerSizes.map(size => (
//                 <option key={size} value={size}>{size}</option>
//               ))}
//             </select>
//           </div>
//         )}
//         {showSalesManagerFilter && (
//           <div className="filter-group right">
//             <label id='nav-label' htmlFor="customerSize">Sales Manager:</label>
//             <select id="SalesManager" value={selectedSalesManager} onChange={(e) => onSalesManagerChange(e.target.value)}>
//               <option value="">All</option>
//               {salesManagers.map(salesManager => (
//                 <option key={salesManager} value={salesManager}>{salesManager}</option>
//               ))}
//             </select>
//           </div>
//         )}
//         </div>
//     </div>
//   );
// };

// export default Filter;




// import React from 'react';
// import Select from 'react-select';
// import './Filter.css';
// // import '../cm_bridge/CmFilter/CmFilter.css';

// const Filter = ({
//   years = [2020, 2021, 2022, 2023],
//   businesses = [],
//   pricingGroups = [],
//   customerSizes = [],
//   salesManagers = [],
//   selectedYear,
//   selectedBusiness,
//   selectedPricingGroup,
//   selectedCustomerSize,
//   selectedSalesManager,
//   onYearChange,
//   onBusinessChange,
//   onPricingGroupChange,
//   onCustomerSizeChange,
//   onSalesManagerChange,
//   showCustomerSizeFilter = false,
//   showSalesManagerFilter = false
// }) => {

//   const createOptions = (array) => array.map(item => ({ value: item, label: item }));

//   return (
//     <div className="filter-cm">
//       <div className='filter-grid'>
//         <div className="filter-group">
//           <Select
//             id="year"
//             options={createOptions(years)}
//             value={createOptions([selectedYear])[0] || null}
//             onChange={(selectedOption) => onYearChange(selectedOption?.value)}
//             placeholder="Year"
//             className="react-select-container"
//             isClearable
//           />
//         </div>
//         <div className="filter-group">
//           <Select
//             id="business"
//             options={createOptions(businesses)}
//             value={createOptions([selectedBusiness])[0] || null}
//             onChange={(selectedOption) => onBusinessChange(selectedOption?.value)}
//             placeholder="Business"
//             className="react-select-container"
//             isClearable
//           />
//         </div>
//         <div className="filter-group right">
//           <Select
//             id="pricingGroup"
//             options={createOptions(pricingGroups)}
//             value={createOptions([selectedPricingGroup])[0] || null}
//             onChange={(selectedOption) => onPricingGroupChange(selectedOption?.value)}
//             placeholder="Pricing Group"
//             className="react-select-container"
//             isClearable
//           />
//         </div>
//         {showCustomerSizeFilter && (
//           <div className="filter-group right">
//             <Select
//               id="customerSize"
//               options={createOptions(customerSizes)}
//               value={createOptions([selectedCustomerSize])[0] || null}
//               onChange={(selectedOption) => onCustomerSizeChange(selectedOption?.value)}
//               placeholder="Customer Size"
//               className="react-select-container"
//               isClearable
//             />
//           </div>
//         )}
//         {showSalesManagerFilter && (
//           <div className="filter-group right">
//             <Select
//               id="SalesManager"
//               options={createOptions(salesManagers)}
//               value={createOptions([selectedSalesManager])[0] || null}
//               onChange={(selectedOption) => onSalesManagerChange(selectedOption?.value)}
//               placeholder="Sales Manager"
//               className="react-select-container"
//               isClearable
//             />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Filter;










import React from 'react';
import Select from 'react-select';
import '../../cm_bridge/CmFilter/CmFilter.css';
import './Filter.css';

const Filter = ({
  years = [2020, 2021, 2022, 2023],
  businesses = [],
  pricingGroups = [],
  customerSizes = [],
  customers = [],
  materials = [],
  verticals = [],
  keyaccounts = [],
  productlines = [],
  selectedYear,
  selectedBusiness,
  selectedPricingGroup,
  selectedCustomerSize,
  selectedCustomer,
  selectedMaterial,
  selectedVertical,
  selectedKeyaccount,
  selectedProductLine,
  onYearChange,
  onBusinessChange,
  onPricingGroupChange,
  onCustomerChange,
  onVerticalChange,
  onKeyaccountChange,
  onProductlineChange,
  onMaterialChange,
  onCustomerSizeChange,
  showCustomerSizeFilter = false,
}) => {
  // Convert arrays to the format required by react-select
  const formatOptions = (options) => options.map(option => ({ value: option, label: option }));

  return (
    <div className="filter-cm">
      <div className='filter-grid-cm'>
        {/* Year Dropdown */}
        <div className="filter-group">
          <Select
            options={formatOptions(years)}
            value={selectedYear ? { value: selectedYear, label: selectedYear } : null}
            onChange={(option) => onYearChange(option ? option.value : '')}
            isClearable
            placeholder="Year"
            className="react-select-container"
          />
        </div>
        {/* Business Dropdown */}
        <div className="filter-group">
          <Select
            options={formatOptions(businesses)}
            value={selectedBusiness ? { value: selectedBusiness, label: selectedBusiness } : null}
            onChange={(option) => onBusinessChange(option ? option.value : '')}
            isClearable
            placeholder="Business"
            className="react-select-container"
          />
        </div>
        {/* Pricing Group Dropdown */}
        <div className="filter-group">
          <Select
            options={formatOptions(pricingGroups)}
            value={selectedPricingGroup ? { value: selectedPricingGroup, label: selectedPricingGroup } : null}
            onChange={(option) => onPricingGroupChange(option ? option.value : '')}
            isClearable
            placeholder="Pricing Group"
            className="react-select-container"
          />
        </div>

          {showCustomerSizeFilter && <div className="filter-group">
            <Select
              options={formatOptions(customerSizes)}
              value={selectedCustomerSize ? { value: selectedCustomerSize, label: selectedCustomerSize } : null}
              onChange={(option) => onCustomerSizeChange(option ? option.value : '')}
              isClearable
              placeholder="Customer Size"
              className="react-select-container"
            />
          </div>
           }
      </div>
    </div>
  );
};

export default Filter;
