import React from "react";
import Navbar from "../../components/common/Navbar/Navbar";


const Home = () => {
    return (
        <div className="Home">
            <Navbar />
            <div className="main-content">
                <div className="header-container">
                    <div className="summary-container">
                      <h3>Home Page Comming soon</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;