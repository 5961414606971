


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../static/Pvmtable.css';
// import { BASE_URL } from '../common/baseUrl';

// const Pvmtable = ({ selectedYear, selectedBusiness, selectedBasePeriod, selectedCompPeriod, selectedVertical}) => {
//     const [tableData, setTableData] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);
//     const [expandedBusiness, setExpandedBusiness] = useState({});
//     const [expandedVertical, setExpandedVertical] = useState({});

//     useEffect(() => {
//         const fetchTableData = async () => {
//             setIsLoading(true);
//             try {
//                 const response = await axios.get(`${BASE_URL}/api/pvm-calculations/`, {
//                     params: {
//                         base_period: selectedBasePeriod,
//                         comparison_period: selectedCompPeriod,
//                         business: selectedBusiness,
//                         ship_to_vertical: selectedVertical
//                     },
//                 });

//                 if (Array.isArray(response.data.pvm_results)) {
//                     const sortedData = response.data.pvm_results.sort((a, b) => b.base_ns - a.base_ns);
//                     setTableData(sortedData);
//                 } else {
//                     console.error('Expected an array but got:', typeof response.data.pvm_results);
//                 }
                
//             } catch (error) {
//                 console.error('Error fetching table data:', error);
//             } finally {
//                 setIsLoading(false);
//             }
//         };

//         if (selectedBasePeriod && selectedCompPeriod) {
//             fetchTableData();
//         }
//     }, [selectedYear, selectedBusiness, selectedBasePeriod, selectedCompPeriod, selectedVertical]);

//     const formatCurrency = (value) => {
//         return `$${parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
//     };

//     const formatNumber = (value) => {
//         return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
//     };
    

//     const calculateTotals = (items) => {
//         return items.reduce((totals, item) => {
//             totals.base_ns += item.base_ns;
//             totals.comp_ns += item.comp_ns;
//             totals.base_vol += item.base_vol;
//             totals.comp_vol += item.comp_vol;
//             totals.price_impact += item.price_impact;
//             totals.pure_price_impact += item.pure_price_impact;
//             totals.fx_other_impact += item.fx_other_impact;
//             totals.vol_mix_impact += item.vol_mix_impact;
//             totals.new_volume_impact += item.new_volume_impact;
//             totals.lost_volume_impact += item.lost_volume_impact;
//             totals.total_volume_mix_impact += item.total_volume_mix_impact;
//             return totals;
//         }, {
//             base_ns: 0,
//             comp_ns: 0,
//             base_vol: 0,
//             comp_vol: 0,
//             price_impact: 0,
//             pure_price_impact: 0,
//             fx_other_impact: 0,
//             vol_mix_impact: 0,
//             new_volume_impact: 0,
//             lost_volume_impact: 0,
//             total_volume_mix_impact: 0,
//         });
//     };

//     const toggleBusinessExpansion = (business) => {
//         setExpandedBusiness((prev) => ({
//             ...prev,
//             [business]: !prev[business],
//         }));
//     };

//     const toggleVerticalExpansion = (business, vertical) => {
//         setExpandedVertical((prev) => ({
//             ...prev,
//             [business]: {
//                 ...prev[business],
//                 [vertical]: !prev[business]?.[vertical],
//             },
//         }));
//     };

//     const renderRows = (data) => {
//         const groupedByBusiness = data.reduce((acc, item) => {
//             if (!acc[item.business]) {
//                 acc[item.business] = {};
//             }
//             if (!acc[item.business][item.ship_to_vertical]) {
//                 acc[item.business][item.ship_to_vertical] = [];
//             }
//             acc[item.business][item.ship_to_vertical].push(item);
//             return acc;
//         }, {});

//         return Object.keys(groupedByBusiness).map((business) => {
//             const businessTotals = calculateTotals(
//                 Object.values(groupedByBusiness[business]).flat()
//             );
//             return (
//                 <React.Fragment key={business}>
//                     <tr onClick={() => toggleBusinessExpansion(business)}>
//                         <td className="small-font" colSpan="2" style={{ fontWeight: 'bold', cursor: 'pointer' }}>
//                             {business}
//                         </td>
//                         <td className="small-font">{formatCurrency(businessTotals.comp_ns)}</td>
//                         <td className="small-font">{formatCurrency(businessTotals.base_ns)}</td>
//                         {/* <td className="small-font">{formatNumber(businessTotals.base_vol)}</td>
//                         <td className="small-font">{formatNumber(businessTotals.comp_vol)}</td> */}
//                         <td className="small-font">{formatCurrency(businessTotals.price_impact)}</td>
//                         <td className="small-font">{formatCurrency(businessTotals.pure_price_impact)}</td>
//                         <td className="small-font">{formatCurrency(businessTotals.fx_other_impact)}</td>
//                         <td className="small-font">{formatCurrency(businessTotals.vol_mix_impact)}</td>
//                         <td className="small-font">{formatCurrency(businessTotals.new_volume_impact)}</td>
//                         <td className="small-font">{formatCurrency(businessTotals.lost_volume_impact)}</td>
//                         <td className="small-font">{formatCurrency(businessTotals.total_volume_mix_impact)}</td>
//                     </tr>
//                     {expandedBusiness[business] &&
//                         Object.keys(groupedByBusiness[business]).map((vertical) => {
//                             const verticalTotals = calculateTotals(groupedByBusiness[business][vertical]);
//                             return (
//                                 <React.Fragment key={vertical}>
//                                     <tr onClick={() => toggleVerticalExpansion(business, vertical)}>
//                                         <td className="small-font" colSpan="2" style={{ paddingLeft: '20px', fontWeight: 'bold', cursor: 'pointer' }}>
//                                             {vertical}
//                                         </td>
//                                         <td className="small-font">{formatCurrency(verticalTotals.comp_ns)}</td>
//                                         <td className="small-font">{formatCurrency(verticalTotals.base_ns)}</td>
//                                         {/* <td className="small-font">{formatNumber(verticalTotals.base_vol)}</td>
//                                         <td className="small-font">{formatNumber(verticalTotals.comp_vol)}</td> */}
//                                         <td className="small-font">{formatCurrency(verticalTotals.price_impact)}</td>
//                                         <td className="small-font">{formatCurrency(verticalTotals.pure_price_impact)}</td>
//                                         <td className="small-font">{formatCurrency(verticalTotals.fx_other_impact)}</td>
//                                         <td className="small-font">{formatCurrency(verticalTotals.vol_mix_impact)}</td>
//                                         <td className="small-font">{formatCurrency(verticalTotals.new_volume_impact)}</td>
//                                         <td className="small-font">{formatCurrency(verticalTotals.lost_volume_impact)}</td>
//                                         <td className="small-font">{formatCurrency(verticalTotals.total_volume_mix_impact)}</td>
//                                     </tr>
//                                     {expandedVertical[business]?.[vertical] &&
//                                         groupedByBusiness[business][vertical].map((row, index) => (
//                                             <tr key={index}>
//                                                 <td className="small-font" style={{ paddingLeft: '40px' }}>{row.ship_to}</td>
//                                                 <td className="small-font">{row.material}</td>
//                                                 <td className="small-font">{formatCurrency(row.comp_ns)}</td>
//                                                 <td className="small-font">{formatCurrency(row.base_ns)}</td>
//                                                 {/* <td className="small-font">{formatNumber(row.base_vol)}</td>
//                                                 <td className="small-font">{formatNumber(row.comp_vol)}</td> */}
//                                                 <td className="small-font">{formatCurrency(row.price_impact)}</td>
//                                                 <td className="small-font">{formatCurrency(row.pure_price_impact)}</td>
//                                                 <td className="small-font">{formatCurrency(row.fx_other_impact)}</td>
//                                                 <td className="small-font">{formatCurrency(row.vol_mix_impact)}</td>
//                                                 <td className="small-font">{formatCurrency(row.new_volume_impact)}</td>
//                                                 <td className="small-font">{formatCurrency(row.lost_volume_impact)}</td>
//                                                 <td className="small-font">{formatCurrency(row.total_volume_mix_impact)}</td>
//                                             </tr>
//                                         ))}
//                                 </React.Fragment>
//                             );
//                         })}
//                 </React.Fragment>
//             );
//         });
//     };

//     return (
//         <div className="pvm-data-table">
//             {isLoading ? (
//                 <p>Loading...</p>
//             ) : (
//                 <table>
//                     <thead>
//                         <tr>
//                             <th className="small-font">Ship To</th>
//                             <th className="small-font">Material</th>
//                             <th className="small-font">Comp NS</th>
//                             <th className="small-font">Base NS</th>
//                             {/* <th className="small-font">Base Vol</th>
//                             <th className="small-font">Comp Vol</th> */}
//                             <th className="small-font">Price Impact</th>
//                             <th className="small-font">Pure Price Impact</th>
//                             <th className="small-font">FX Other Impact</th>
//                             <th className="small-font">Vol Mix Impact</th>
//                             <th className="small-font">New Volume Impact</th>
//                             <th className="small-font">Lost Volume Impact</th>
//                             <th className="small-font">Total Vol Mix Impact</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {renderRows(tableData)}
//                     </tbody>
//                 </table>
//             )}
//         </div>
//     );
// };

// export default Pvmtable;










// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../static/Pvmtable.css';
// import { BASE_URL } from '../common/baseUrl';

// const Pvmtable = ({ selectedYear, selectedBusiness, selectedBasePeriod, selectedCompPeriod, selectedVertical }) => {
//     const [tableData, setTableData] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);
//     const [expandedBusiness, setExpandedBusiness] = useState({});

//     useEffect(() => {
//         const fetchTableData = async () => {
//             setIsLoading(true);
//             try {
//                 const response = await axios.get(`${BASE_URL}/api/pvm-calculations/`, {
//                     params: {
//                         base_period: selectedBasePeriod,
//                         comparison_period: selectedCompPeriod,
//                         business: selectedBusiness,
//                         ship_to_vertical: selectedVertical
//                     },
//                 });

//                 if (Array.isArray(response.data.pvm_results)) {
//                     const sortedData = response.data.pvm_results.sort((a, b) => b.base_ns - a.base_ns);
//                     setTableData(sortedData);
//                 } else {
//                     console.error('Expected an array but got:', typeof response.data.pvm_results);
//                 }

//             } catch (error) {
//                 console.error('Error fetching table data:', error);
//             } finally {
//                 setIsLoading(false);
//             }
//         };

//         if (selectedBasePeriod && selectedCompPeriod) {
//             fetchTableData();
//         }
//     }, [selectedYear, selectedBusiness, selectedBasePeriod, selectedCompPeriod, selectedVertical]);

//     const formatCurrency = (value) => {
//         return `$${parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
//     };

//     const formatNumber = (value) => {
//         return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
//     };

//     const calculateTotals = (items) => {
//         return items.reduce((totals, item) => {
//             totals.base_ns += item.base_ns;
//             totals.comp_ns += item.comp_ns;
//             totals.price_impact += item.price_impact;
//             totals.pure_price_impact += item.pure_price_impact;
//             totals.fx_other_impact += item.fx_other_impact;
//             totals.vol_mix_impact += item.vol_mix_impact;
//             totals.new_volume_impact += item.new_volume_impact;
//             totals.lost_volume_impact += item.lost_volume_impact;
//             totals.total_volume_mix_impact += item.total_volume_mix_impact;
//             return totals;
//         }, {
//             base_ns: 0,
//             comp_ns: 0,
//             price_impact: 0,
//             pure_price_impact: 0,
//             fx_other_impact: 0,
//             vol_mix_impact: 0,
//             new_volume_impact: 0,
//             lost_volume_impact: 0,
//             total_volume_mix_impact: 0,
//         });
//     };

//     const toggleBusinessExpansion = (business) => {
//         setExpandedBusiness((prev) => ({
//             ...prev,
//             [business]: !prev[business],
//         }));
//     };

//     const renderRows = (data) => {
//         const groupedByBusiness = data.reduce((acc, item) => {
//             if (!acc[item.business]) {
//                 acc[item.business] = {};
//             }
//             if (!acc[item.business][item.ship_to_vertical]) {
//                 acc[item.business][item.ship_to_vertical] = [];
//             }
//             acc[item.business][item.ship_to_vertical].push(item);
//             return acc;
//         }, {});

//         return Object.keys(groupedByBusiness).map((business) => {
//             const businessTotals = calculateTotals(
//                 Object.values(groupedByBusiness[business]).flat()
//             );
//             return (
//                 <React.Fragment key={business}>
//                     <tr onClick={() => toggleBusinessExpansion(business)}>
//                         <td className="small-font" colSpan="2" style={{ fontWeight: 'bold', cursor: 'pointer' }}>
//                             {business}
//                         </td>
//                         <td className="small-font">{formatCurrency(businessTotals.comp_ns)}</td>
//                         <td className="small-font">{formatCurrency(businessTotals.pure_price_impact)}</td>
//                         <td className="small-font">{formatCurrency(businessTotals.fx_other_impact)}</td>
//                         <td className="small-font">{formatCurrency(businessTotals.total_volume_mix_impact)}</td>
//                         <td className="small-font">{formatCurrency(businessTotals.base_ns)}</td>
//                     </tr>
//                     {expandedBusiness[business] &&
//                         Object.keys(groupedByBusiness[business]).map((vertical) => {
//                             const verticalTotals = calculateTotals(groupedByBusiness[business][vertical]);
//                             return (
//                                 <React.Fragment key={vertical}>
//                                     <tr>
//                                         <td className="small-font" colSpan="2" style={{ paddingLeft: '20px', fontWeight: 'bold' }}>
//                                             {vertical}
//                                         </td>
//                                         <td className="small-font">{formatCurrency(verticalTotals.comp_ns)}</td>
//                                         <td className="small-font">{formatCurrency(verticalTotals.pure_price_impact)}</td>
//                                         <td className="small-font">{formatCurrency(verticalTotals.fx_other_impact)}</td>
//                                         <td className="small-font">{formatCurrency(verticalTotals.total_volume_mix_impact)}</td>
//                                         <td className="small-font">{formatCurrency(verticalTotals.base_ns)}</td>
//                                     </tr>
//                                 </React.Fragment>
//                             );
//                         })}
//                 </React.Fragment>
//             );
//         });
//     };

//     return (
//         <div className="pvm-data-table">
//             {isLoading ? (
//                 <p>Loading...</p>
//             ) : (
//                 <table>
//                     <thead>
//                         <tr>
//                             <th className="small-font">Business </th>
//                             <th className="small-font">Vertical</th>
//                             <th className="small-font">Comp NS</th>
//                             <th className="small-font">Pure Price Impact</th>
//                             <th className="small-font">FX Other Impact</th>
//                             <th className="small-font">Total Vol Mix Impact</th>
//                             <th className="small-font">Base NS</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {renderRows(tableData)}
//                     </tbody>
//                 </table>
//             )}
//         </div>
//     );
// };

// export default Pvmtable;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Pvmtable.css';
import { BASE_URL } from '../../common/baseUrl';

const Pvmtable = ({ selectedYear, selectedPeriod, selectedBusiness, selectedBasePeriod, selectedCompPeriod, selectedVertical }) => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [expandedBusiness, setExpandedBusiness] = useState({});

    useEffect(() => {
        const fetchTableData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${BASE_URL}/pvm-calculations`, {
                    params: {
                        period:selectedPeriod,
                        base_period: selectedBasePeriod,
                        comparison_period: selectedCompPeriod,
                        business: selectedBusiness,
                        ship_to_vertical: selectedVertical
                    },
                });

                if (Array.isArray(response.data.pvm_results)) {
                    const sortedData = response.data.pvm_results.sort((a, b) => b.base_ns - a.base_ns);
                    setTableData(sortedData);
                } else {
                    console.error('Expected an array but got:', typeof response.data.pvm_results);
                }

            } catch (error) {
                console.error('Error fetching table data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (selectedPeriod && selectedBasePeriod && selectedCompPeriod) {
            fetchTableData();
        }
    }, [selectedYear, selectedPeriod, selectedBusiness, selectedBasePeriod, selectedCompPeriod, selectedVertical]);

    const formatCurrency = (value) => {
        return `$${parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    };

    const formatNumber = (value) => {
        return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    };

    const calculateTotals = (items) => {
        return items.reduce((totals, item) => {
            totals.base_ns += item.base_ns;
            totals.comp_ns += item.comp_ns;
            totals.price_impact += item.price_impact;
            totals.pure_price_impact += item.pure_price_impact;
            totals.fx_other_impact += item.fx_other_impact;
            totals.vol_mix_impact += item.vol_mix_impact;
            totals.new_volume_impact += item.new_volume_impact;
            totals.lost_volume_impact += item.lost_volume_impact;
            totals.total_volume_mix_impact += item.total_volume_mix_impact;
            return totals;
        }, {
            base_ns: 0,
            comp_ns: 0,
            price_impact: 0,
            pure_price_impact: 0,
            fx_other_impact: 0,
            vol_mix_impact: 0,
            new_volume_impact: 0,
            lost_volume_impact: 0,
            total_volume_mix_impact: 0,
        });
    };

    const calculateOverallTotals = (data) => {
        return data.reduce((totals, item) => {
            totals.base_ns += item.base_ns;
            totals.comp_ns += item.comp_ns;
            totals.pure_price_impact += item.pure_price_impact;
            totals.fx_other_impact += item.fx_other_impact;
            totals.total_volume_mix_impact += item.total_volume_mix_impact;
            return totals;
        }, {
            base_ns: 0,
            comp_ns: 0,
            pure_price_impact: 0,
            fx_other_impact: 0,
            total_volume_mix_impact: 0,
        });
    };

    const overallTotals = calculateOverallTotals(tableData);

    const maxBaseNS = overallTotals.base_ns;
    const maxCompNS = overallTotals.comp_ns;
    const maxPurePriceImpact = overallTotals.pure_price_impact;
    const maxFXOtherImpact = overallTotals.fx_other_impact;
    const maxTotalVolMixImpact = overallTotals.total_volume_mix_impact;

    const getBarColor = (value) => {
        return value >= 0 ? '#4caf50' : '#f44336'; // Green for positive, red for negative
    };

    const renderRows = (data) => {
        const groupedByBusiness = data.reduce((acc, item) => {
            if (!acc[item.business]) {
                acc[item.business] = {};
            }
            if (!acc[item.business][item.ship_to_vertical]) {
                acc[item.business][item.ship_to_vertical] = [];
            }
            acc[item.business][item.ship_to_vertical].push(item);
            return acc;
        }, {});

        return Object.keys(groupedByBusiness).map((business) => {
            const businessTotals = calculateTotals(
                Object.values(groupedByBusiness[business]).flat()
            );
            return (
                <React.Fragment key={business}>
                    <tr onClick={() => toggleBusinessExpansion(business)}>
                        <td className="small-font" colSpan="2" style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                            {business}
                        </td>
                        <td>
                            <div className="bar-container">
                                <div
                                    className="bar"
                                    style={{
                                        width: `${(businessTotals.comp_ns / maxCompNS) * 100}%`,
                                        backgroundColor: getBarColor(businessTotals.comp_ns)
                                    }}
                                ></div>
                                <div className="bar-label">
                                    {formatCurrency(businessTotals.comp_ns)}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="bar-container">
                                <div
                                    className="bar"
                                    style={{
                                        width: `${(businessTotals.pure_price_impact / maxPurePriceImpact) * 100}%`,
                                        backgroundColor: getBarColor(businessTotals.pure_price_impact)
                                    }}
                                ></div>
                                <div className="bar-label">
                                    {formatCurrency(businessTotals.pure_price_impact)}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="bar-container">
                                <div
                                    className="bar"
                                    style={{
                                        width: `${(businessTotals.fx_other_impact / maxFXOtherImpact) * 100}%`,
                                        backgroundColor: getBarColor(businessTotals.fx_other_impact)
                                    }}
                                ></div>
                                <div className="bar-label">
                                    {formatCurrency(businessTotals.fx_other_impact)}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="bar-container">
                                <div
                                    className="bar"
                                    style={{
                                        width: `${(businessTotals.total_volume_mix_impact / maxTotalVolMixImpact) * 100}%`,
                                        backgroundColor: getBarColor(businessTotals.total_volume_mix_impact)
                                    }}
                                ></div>
                                <div className="bar-label">
                                    {formatCurrency(businessTotals.total_volume_mix_impact)}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="bar-container">
                                <div
                                    className="bar"
                                    style={{
                                        width: `${(businessTotals.base_ns / maxBaseNS) * 100}%`,
                                        backgroundColor: getBarColor(businessTotals.base_ns)
                                    }}
                                ></div>
                                <div className="bar-label">
                                    {formatCurrency(businessTotals.base_ns)}
                                </div>
                            </div>
                        </td>
                    </tr>
                    {expandedBusiness[business] &&
                        Object.keys(groupedByBusiness[business]).map((vertical) => {
                            const verticalTotals = calculateTotals(groupedByBusiness[business][vertical]);
                            return (
                                <React.Fragment key={vertical}>
                                    <tr>
                                        <td className="small-font" colSpan="2" style={{ paddingLeft: '20px', fontWeight: 'bold' }}>
                                            {vertical}
                                        </td>
                                        <td>
                                            <div className="bar-container">
                                                <div
                                                    className="bar"
                                                    style={{
                                                        width: `${(verticalTotals.comp_ns / maxCompNS) * 100}%`,
                                                        backgroundColor: getBarColor(verticalTotals.comp_ns)
                                                    }}
                                                ></div>
                                                <div className="bar-label">
                                                    {formatCurrency(verticalTotals.comp_ns)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="bar-container">
                                                <div
                                                    className="bar"
                                                    style={{
                                                        width: `${(verticalTotals.pure_price_impact / maxPurePriceImpact) * 100}%`,
                                                        backgroundColor: getBarColor(verticalTotals.pure_price_impact)
                                                    }}
                                                ></div>
                                                <div className="bar-label">
                                                    {formatCurrency(verticalTotals.pure_price_impact)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="bar-container">
                                                <div
                                                    className="bar"
                                                    style={{
                                                        width: `${(verticalTotals.fx_other_impact / maxFXOtherImpact) * 100}%`,
                                                        backgroundColor: getBarColor(verticalTotals.fx_other_impact)
                                                    }}
                                                ></div>
                                                <div className="bar-label">
                                                    {formatCurrency(verticalTotals.fx_other_impact)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="bar-container">
                                                <div
                                                    className="bar"
                                                    style={{
                                                        width: `${(verticalTotals.total_volume_mix_impact / maxTotalVolMixImpact) * 100}%`,
                                                        backgroundColor: getBarColor(verticalTotals.total_volume_mix_impact)
                                                    }}
                                                ></div>
                                                <div className="bar-label">
                                                    {formatCurrency(verticalTotals.total_volume_mix_impact)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="bar-container">
                                                <div
                                                    className="bar"
                                                    style={{
                                                        width: `${(verticalTotals.base_ns / maxBaseNS) * 100}%`,
                                                        backgroundColor: getBarColor(verticalTotals.base_ns)
                                                    }}
                                                ></div>
                                                <div className="bar-label">
                                                    {formatCurrency(verticalTotals.base_ns)}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                </React.Fragment>
            );
        });
    };

    const toggleBusinessExpansion = (business) => {
        setExpandedBusiness(prev => ({
            ...prev,
            [business]: !prev[business]
        }));
    };

    return (
        <div className="pvm-data-table">
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <table className="pvm-table">
                    <thead>
                        <tr>
                            <th>Business</th>
                            <th>Vertical</th>
                            <th>Comparison NS</th>
                            <th>Pure Price Impact</th>
                            <th>FX & Other Impact</th>
                            <th>Volume & Mix Impact</th>
                            <th>Base Period NS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderRows(tableData)}
                    </tbody>
                    <tfoot>
                    <tr className='total-row'>
                        <td colSpan="2">Total</td>
                        <td>{formatCurrency(overallTotals.comp_ns)}</td>
                        <td>{formatCurrency(overallTotals.pure_price_impact)}</td>
                        <td>{formatCurrency(overallTotals.fx_other_impact)}</td>
                        <td>{formatCurrency(overallTotals.total_volume_mix_impact)}</td>
                        <td>{formatCurrency(overallTotals.base_ns)}</td>
                    </tr>
                </tfoot>
                </table>
            )}
        </div>
    );
};

export default Pvmtable;
