// import React from 'react';
// import Select from 'react-select';
// import '../static/Filter.css';

// const CmFilters = ({
//   years = [2020, 2021, 2022, 2023],
//   businesses = [],
//   customers = [],
//   materials = [],
//   verticals = [],
//   keyaccounts = [],
//   productlines = [],
//   selectedYear,
//   selectedBusiness,
//   selectedCustomer,
//   selectedMaterial,
//   selectedVertical,
//   selectedKeyaccount,
//   selectedProductLine,
//   onYearChange,
//   onBusinessChange,
//   onCustomerChange,
//   onVerticalChange,
//   onKeyaccountChange,
//   onProductlineChange,
//   onMaterialChange

// }) => {
//   return (
//     <div className="filter">
//       <div className='filter-grid'>
//         <div className="filter-group">
//           <select id="year" value={selectedYear} onChange={(e) => onYearChange(e.target.value)}>
//             <option value="">Year</option>
//             {years.map(year => (
//               <option key={year} value={year}>{year}</option>
//             ))}
//           </select>
//         </div>
//         <div className="filter-group">
//           <select id="business" value={selectedBusiness} onChange={(e) => onBusinessChange(e.target.value)}>
//             <option value="">Business</option>
//             {businesses.map(business => (
//               <option key={business} value={business}>{business}</option>
//             ))}
//           </select>
//         </div>
//         <div className="filter-group">
//           <select id="customer" value={selectedCustomer} onChange={(e) => onCustomerChange(e.target.value)}>
//             <option value="">Customer</option>
//             {customers.map(customer => (
//               <option key={customer} value={customer}>{customer}</option>
//             ))}
//           </select>
//         </div>
//         <div className="filter-group">
//           <select id="material" value={selectedMaterial} onChange={(e) => onMaterialChange(e.target.value)}>
//             <option value="">Materials</option>
//             {materials.map(Material => (
//               <option key={Material} value={Material}>{Material}</option>
//             ))}
//           </select>
//         </div>
//         {/* <div className="filter-group">
//           <select id="vertical" value={selectedVertical} onChange={(e) => onVerticalChange(e.target.value)}>
//             <option value="">Vertical</option>
//             {verticals.map(vertical => (
//               <option key={vertical} value={vertical}>{vertical}</option>
//             ))}
//           </select>
//         </div> */}


//          <div className="filter-group">
//           <select id="keyaccount" value={selectedKeyaccount} onChange={(e) => onKeyaccountChange(e.target.value)}>
//             <option value="">Key Account</option>
//             {keyaccounts.map(keyaccount => (
//               <option key={keyaccount} value={keyaccount}>{keyaccount}</option>
//             ))}
//           </select>
//         </div>
//         {/* <div className="filter-group">
//           <select id="productline" value={selectedProductLine} onChange={(e) => onProductlineChange(e.target.value)}>
//             <option value="">Product Line</option>
//             {productlines.map(productline => (
//               <option key={productline} value={productline}>{productline}</option>
//             ))}
//           </select>
//         </div> */}

//         </div>
//     </div>
//   );
// };

// export default CmFilters;








import React from 'react';
import Select from 'react-select';
import './CmFilter.css';

const CmFilters = ({
  years = [2020, 2021, 2022, 2023],
  businesses = [],
  customers = [],
  materials = [],
  verticals = [],
  keyaccounts = [],
  productlines = [],
  selectedYear,
  selectedBusiness,
  selectedCustomer,
  selectedMaterial,
  selectedVertical,
  selectedKeyaccount,
  selectedProductLine,
  onYearChange,
  onBusinessChange,
  onCustomerChange,
  onVerticalChange,
  onKeyaccountChange,
  onProductlineChange,
  onMaterialChange
}) => {
  // Convert arrays to the format required by react-select
  const formatOptions = (options) => options.map(option => ({ value: option, label: option }));

  return (
    <div className="filter-cm">
      <div className='filter-grid-cm'>
        {/* Year Dropdown */}
        <div className="filter-group">
          <Select
            options={formatOptions(years)}
            value={selectedYear ? { value: selectedYear, label: selectedYear } : null}
            onChange={(option) => onYearChange(option ? option.value : '')}
            isClearable
            placeholder="Year"
            className="react-select-container"
          />
        </div>
        {/* Business Dropdown */}
        <div className="filter-group">
          <Select
            options={formatOptions(businesses)}
            value={selectedBusiness ? { value: selectedBusiness, label: selectedBusiness } : null}
            onChange={(option) => onBusinessChange(option ? option.value : '')}
            isClearable
            placeholder="Business"
            className="react-select-container"
          />
        </div>
        {/* Key Account Dropdown */}
        {/* <div className="filter-group">
          <Select
            options={formatOptions(keyaccounts)}
            value={selectedKeyaccount ? { value: selectedKeyaccount, label: selectedKeyaccount } : null}
            onChange={(option) => onKeyaccountChange(option ? option.value : '')}
            isClearable
            placeholder="Key Account"
            className="react-select-container"
          />
        </div> */}
        {/* Customer Dropdown */}
        {/* <div className="filter-group">
          <Select
            options={formatOptions(customers)}
            value={selectedCustomer ? { value: selectedCustomer, label: selectedCustomer } : null}
            onChange={(option) => onCustomerChange(option ? option.value : '')}
            isClearable
            placeholder="Customer"
            className="react-select-container"
          />
        </div> */}
        {/* Material Dropdown */}
        {/* <div className="filter-group">
          <Select
            options={formatOptions(materials)}
            value={selectedMaterial ? { value: selectedMaterial, label: selectedMaterial } : null}
            onChange={(option) => onMaterialChange(option ? option.value : '')}
            isClearable
            placeholder="Material"
            className="react-select-container"
          />
        </div> */}
        
        {/* Product Line Dropdown */}
        {/* <div className="filter-group">
          <Select
            options={formatOptions(productlines)}
            value={selectedProductLine ? { value: selectedProductLine, label: selectedProductLine } : null}
            onChange={(option) => onProductlineChange(option ? option.value : '')}
            isClearable
            placeholder="Product Line"
            className="react-select-container"
          />
        </div> */}
      </div>
    </div>
  );
};

export default CmFilters;
