import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SummaryStats.css';
import { BASE_URL } from '../baseUrl';

const SummaryStats = ({ selectedYear, selectedBusiness, selectedPricingGroup, selectedCustomerSize, 
  selectedSalesManager,selectedVertical, selectedCustomer,selectedMaterial, selectedKeyaccount, selectedProductLine }) => {
  const [summaryData, setSummaryData] = useState({
    totalNetSales: 0,
    totalCM: 0,
    totalVol: 0,
    totalOpportunity: 0,
  });

  useEffect(() => {
    console.log('useEffect called with dependencies:', { selectedYear, selectedBusiness, selectedPricingGroup, 
      selectedCustomerSize, selectedSalesManager, selectedVertical, selectedCustomer,selectedMaterial, selectedKeyaccount, selectedProductLine});
    fetchSummaryData();
  }, [selectedYear, selectedBusiness, selectedPricingGroup, selectedCustomerSize, selectedSalesManager,selectedVertical, selectedCustomer,selectedMaterial, selectedKeyaccount, selectedProductLine]);

  const fetchSummaryData = async () => {
    try {
      console.log('Fetching summary data with params:', {
        year: selectedYear,
        business: selectedBusiness,
        pricing_group: selectedPricingGroup,
        customer_size: selectedCustomerSize,
        sales_manager: selectedSalesManager,
        vertical: selectedVertical,
        customer: selectedCustomer,
        material:selectedMaterial,
        keyaccount: selectedKeyaccount,
        productline: selectedProductLine
      });
      const response = await axios.get(`${BASE_URL}/pricingdata-summary-stats`, {
        params: {
          year: selectedYear,
          business: selectedBusiness,
          pricing_group: selectedPricingGroup,
          customer_size: selectedCustomerSize,
          sales_manager: selectedSalesManager,
          vertical: selectedVertical,
          customer: selectedCustomer,
          material:selectedMaterial,
          keyaccount: selectedKeyaccount,
          productline: selectedProductLine
        }
      });
      const data = response.data;

      console.log('Received data:', data);

      setSummaryData({
        totalNetSales: data.totalNetSales || 0,
        totalCM: data.totalCM || 0,
        totalVol: data.totalVol || 0,
        totalOpportunity: data.totalOpportunity || 0,
      });
    } catch (error) {
      console.error('Error fetching summary data:', error);
    }
  };

  // const formatCurrency = (value) => {
  //   return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
  // };


  const formatCurrency = (value) => {
     
    if (value >= 1000000000) {
      const billions = value / 1000000000;
      return `$${billions.toFixed(billions >= 10 ? 1 : 2)}B`;
    }

    else if (value >= 1000000) {
      // For values greater than or equal to 1,000,000, format as $X.XM
      const millions = value / 1000000;
      return `$${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
    } 
    else {
      // For values less than 1,000,000, format with commas
      return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    }
  };

//   const formatNumber = (value) => {
//     return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
// };

const formatNumber = (value) => {
  if (value > 1000000){
    const millions = value/1000000;
    return `${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
  }
  return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
};



  return (
    <div className="summary-stats">
      <div className="stats-grid">
        <div className="stat-item">
          <span className="stat-value">{formatCurrency(summaryData.totalNetSales)}</span>
          <span className="stat-label">Net Sales</span>
        </div>
        <div className="stat-item">
          <span className="stat-value">{formatCurrency(summaryData.totalCM)}</span>
          <span className="stat-label">CM</span>
        </div>
        <div className="stat-item">
          <span className="stat-value">{formatNumber(summaryData.totalVol)}</span>
          <span className="stat-label">Volume</span>
        </div>
        <div className="stat-item">
          <span className="stat-value">{formatCurrency(summaryData.totalOpportunity)}</span>
          <span className="stat-label">Opportunity</span>
        </div>
      </div>
    </div>
  );
};

export default SummaryStats;
