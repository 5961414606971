// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollable-container {
    width: 900px;
    overflow-x: auto;
  }
  
  .chart-container {
    height: 300px;
    width: 900px;
  }

  
.chart-section {
  /* margin-bottom: 10px;  */
  width: 100%; /* Adjust as needed */
}

.chart-container {
  height: 350px;
  width: 100%; /* Adjust width as needed */
}

.text-line{
  font-size: 13px;
  font-weight: 500;
  margin: 8px;
  margin-left: 0;
}

.chart-title{
  font-size: 13px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/components/opportunity_how_much/BarCharts/CustomerBar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,YAAY;EACd;;;AAGF;EACE,0BAA0B;EAC1B,WAAW,EAAE,qBAAqB;AACpC;;AAEA;EACE,aAAa;EACb,WAAW,EAAE,2BAA2B;AAC1C;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".scrollable-container {\n    width: 900px;\n    overflow-x: auto;\n  }\n  \n  .chart-container {\n    height: 300px;\n    width: 900px;\n  }\n\n  \n.chart-section {\n  /* margin-bottom: 10px;  */\n  width: 100%; /* Adjust as needed */\n}\n\n.chart-container {\n  height: 350px;\n  width: 100%; /* Adjust width as needed */\n}\n\n.text-line{\n  font-size: 13px;\n  font-weight: 500;\n  margin: 8px;\n  margin-left: 0;\n}\n\n.chart-title{\n  font-size: 13px;\n  font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
