
import React, { useState, useEffect } from 'react';
// import '../App.css';
import './Pvm.css';
import axios from 'axios';
import Navbar from '../../components/common/Navbar/Navbar.jsx';
import Pvmfilter from '../../components/pvm/Pvmfilters/Pvmfilter.jsx';
import Pvmtotals from '../../components/pvm/Pvmtotals/Pvmtotals.jsx';
import Waterfallchart from '../../components/pvm/Waterfallchart.jsx';
import CmWaterfall from '../../components/pvm/CmWaterfall.jsx';
import { BASE_URL } from '../../components/common/baseUrl.js';
import CascadingWaterfall from '../../components/pvm/CascadingWaterfall/CascadingWaterfall.jsx';
import Pvmtable from '../../components/pvm/Table/Pvmtable.jsx';
import TopBottomCustomers from '../../components/pvm/Topbottom/TopBottomCustomers.jsx';
import TopBottomVerticals from '../../components/pvm/TopBottomVerticals.jsx';
import TopBottomMaterials from '../../components/pvm/Topbottom/TopBottomMaterials.jsx';
import TopBottomKeyAccount from '../../components/pvm/Topbottom/TopBottomKeyAccount.jsx';
import TopBottomProductLines from '../../components/pvm/Topbottom/TopBottomProductLines.jsx';

function Pvm() {
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedBusiness, setSelectedBusiness] = useState('');
    const [selectedBasePeriod, setSelectedBasePeriod] = useState('');
    const [selectedCompPeriod, setSelectedCompPeriod] = useState('');
    const [selectedVertical, setSelectedVertical] = useState('');
    const [selectedperiod, setSelectedPeriod] = useState('');
    const [years, setYears] = useState([]);
    const [quarters, setQuarters] = useState([]);
    const [months, setMonths] = useState([]);
    const [businesses, setBusinesses] = useState([]);
    const [verticals, setVerticals] = useState([]);
    const [selectedOption, setSelectedOption] = useState('total');
    const [isLoading, setIsLoading] = useState(true);
    // const [selectedChartType, setSelectedChartType] = useState('NS'); 
    const [selectedChartType, setSelectedChartType] = useState('Key Account');

    useEffect(() => {
        fetchFilters();
    }, []);

    const fetchFilters = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/pricingdata`);
            const data = response.data;

            const response_customer = await axios.get(`${BASE_URL}/customers`);
            const data_cust = response_customer.data;

            const response_calendar = await axios.get(`${BASE_URL}/calendar`);
            const data_cal = response_calendar.data;

            const uniqueYears = [''].concat([...new Set(data.map(item => item.year))]);
            const uniqueBusinesses = [''].concat([...new Set(data.map(item => item.business))]);
            const uniqueVerticals = [''].concat([...new Set(data_cust.map(item => item.ship_to_vertical))]);
            const uniqueQuarter = [''].concat([...new Set(data_cal.map(item => item.quarter_year))]);
            const uniqueMonth = [''].concat([...new Set(data_cal.map(item => item.month_year))]);

            setYears(uniqueYears);
            setQuarters(uniqueQuarter);
            setMonths(uniqueMonth);
            setBusinesses(uniqueBusinesses);
            setVerticals(uniqueVerticals);
        } catch (error) {
            console.error('Error fetching filter data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
    setSelectedBasePeriod(''); // Reset base period when period changes
    setSelectedCompPeriod(''); // Reset comp period when period changes
  };
    
    const onBasePeriodChange = (basePeriod) => {
        setSelectedBasePeriod(basePeriod);
    };

    const onCompPeriodChange = (compPeriod) => {
        setSelectedCompPeriod(compPeriod);
    };

    const onChartTypeChange = (chartType) => {
        setSelectedChartType(chartType);
    };

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    return (
        <div className="PricingDetail">
            <Navbar />
            <div className="main-content-pvm">
                <div className="header-container-pvm">
                    <div className='title-filter-pvm'>
                        <div className='page-title-pvm'>
                          <p>Price Volume Mix</p>
                        </div>
                        <div className="filter-container-pvm">
                        <Pvmfilter
                            years={years}
                            quarters={quarters}
                            months={months}
                            businesses={businesses}
                            verticals={verticals}
                            selectedperiod={selectedperiod}
                            selectedbaseperiod={selectedBasePeriod}
                            selectedcompperiod={selectedCompPeriod}
                            selectedVertical={selectedVertical}
                            selectedBusiness={selectedBusiness}
                            onPeriodChange={handlePeriodChange}
                            onBasePeriodChange={onBasePeriodChange}
                            onCompPeriodChange={onCompPeriodChange}
                            onBusinessChange={setSelectedBusiness}
                            onVerticalChange={setSelectedVertical}
                        />
                    </div>

                </div>
                    
                </div>
                <div className="content-container-pvm">
                   <div className='waterfall'>
                      <div className="radio-options">
                            <label>
                                <input
                                    type="radio"
                                    value="total"
                                    checked={selectedOption === 'total'}
                                    onChange={handleOptionChange}
                                />
                                Show Total
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="breakdown"
                                    checked={selectedOption === 'breakdown'}
                                    onChange={handleOptionChange}
                                />
                                Show Breakdown
                            </label>
                        </div>


                     <div className="chart-container-pvm">
                       <CascadingWaterfall
                         selectedYear={selectedYear}
                         selectedPeriod={selectedperiod}
                         selectedBusiness={selectedBusiness}
                         selectedBasePeriod={selectedBasePeriod}
                         selectedCompPeriod={selectedCompPeriod}
                         selectedVertical={selectedVertical}
                         showTotal={selectedOption === 'total'} 
                         showBreakdown={selectedOption === 'breakdown'}
                         />
                     </div>
                  </div>


                   <div className='tabs-table'>
                   <div className="tabs">
                            <button className={`tab ${selectedChartType === 'Key Account' ? 'active' : ''}`} onClick={() => onChartTypeChange('Key Account')}>Key Account</button>
                            <button className={`tab ${selectedChartType === 'Product Line' ? 'active' : ''}`} onClick={() => onChartTypeChange('Product Line')}>Product Line</button>
                            <button className={`tab ${selectedChartType === 'Customers' ? 'active' : ''}`} onClick={() => onChartTypeChange('Customers')}>Customers</button>
                            <button className={`tab ${selectedChartType === 'Materials' ? 'active' : ''}`} onClick={() => onChartTypeChange('Materials')}>Materials</button>
                        </div>
                     
                    <div className='table-container-pvm'>

                        {selectedChartType === 'Key Account' && (
                            <TopBottomKeyAccount
                                selectedYear={selectedYear}
                                selectedPeriod={selectedperiod}
                                selectedBusiness={selectedBusiness}
                                selectedBasePeriod={selectedBasePeriod}
                                selectedCompPeriod={selectedCompPeriod}
                                selectedVertical={selectedVertical}
                                showTotal={selectedOption === 'total'} 
                                showBreakdown={selectedOption === 'breakdown'}
                            />
                        )} 

                        {selectedChartType === 'Product Line' && (
                            <TopBottomProductLines
                                selectedYear={selectedYear}
                                selectedPeriod={selectedperiod}
                                selectedBusiness={selectedBusiness}
                                selectedBasePeriod={selectedBasePeriod}
                                selectedCompPeriod={selectedCompPeriod}
                                selectedVertical={selectedVertical}
                                showTotal={selectedOption === 'total'} 
                                showBreakdown={selectedOption === 'breakdown'}
                            />
                        )} 

                        {selectedChartType === 'Customers' && (
                            <TopBottomCustomers
                                selectedYear={selectedYear}
                                selectedPeriod={selectedperiod}
                                selectedBusiness={selectedBusiness}
                                selectedBasePeriod={selectedBasePeriod}
                                selectedCompPeriod={selectedCompPeriod}
                                selectedVertical={selectedVertical}
                                showTotal={selectedOption === 'total'} 
                                showBreakdown={selectedOption === 'breakdown'}
                            />
                        )} 

                        {selectedChartType === 'Materials' && (
                            <TopBottomMaterials
                                selectedYear={selectedYear}
                                selectedPeriod={selectedperiod}
                                selectedBusiness={selectedBusiness}
                                selectedBasePeriod={selectedBasePeriod}
                                selectedCompPeriod={selectedCompPeriod}
                                selectedVertical={selectedVertical}
                                showTotal={selectedOption === 'total'} 
                                showBreakdown={selectedOption === 'breakdown'}
                            />
                        )} 
                        
                    </div>
                  </div>
                </div>      
            </div>
        </div>
    );
}

export default Pvm;
