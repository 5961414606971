// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './DataTable.css';
// import { BASE_URL } from '../../common/baseUrl';

// const DataTable = ({ selectedYear, selectedBusiness, selectedPricingGroup, selectedCustomerSize }) => {
//     const [tableData, setTableData] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);

//     useEffect(() => {
//         const fetchTableData = async () => {
//             setIsLoading(true);
//             try {
//                 const response = await axios.get(`${BASE_URL}/customer-product-score-data/`, {
//                     params: {
//                         year: selectedYear,
//                         business: selectedBusiness,
//                         pricing_group: selectedPricingGroup,
//                         customer_size: selectedCustomerSize,
//                     },
//                 });
//                 const sortedData = response.data.sort((a, b) => b.opportunity - a.opportunity);
//                 setTableData(sortedData);
//             } catch (error) {
//                 console.error('Error fetching table data:', error);
//             } finally {
//                 setIsLoading(false);
//             }
//         };

//         fetchTableData();
//     }, [selectedYear, selectedBusiness, selectedPricingGroup, selectedCustomerSize]);

//     const formatCurrency = (value) => {
//         return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
//     };

//     const formatPercentage = (value) => {
//         return `${(value * 100).toFixed(1)}%`;
//     };

    
//     return (
//         <div className="data-table">
//             {isLoading ? (
//                 <p>Loading...</p>
//             ) : (
//                 <table>
//                     <thead>
//                         <tr>
//                             <th className="small-font">Customer Name</th>
//                             <th className="small-font">Product Name</th>
//                             <th className="small-font">Overall Score</th>
//                             <th className="small-font">Risk Score</th>
//                             <th className="small-font">Opportunity</th>
//                             <th className="small-font">Net Sales</th>
//                             <th className="small-font">PM Percent</th>
//                             {/* <th className="small-font">Ship To Vertical</th> */}
//                             <th className="small-font">Pricing Group</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {tableData.map((row, index) => (
//                             <tr key={index}>
//                                 <td className="small-font">{row.customer_name}</td>
//                                 <td className="small-font">{row.product_name}</td>
//                                 <td className="small-font">{row.overall_score}</td>
//                                 <td className="small-font">{row.risk_score}</td>
//                                 <td className="small-font">{formatCurrency(row.opportunity)}</td>
//                                 <td className="small-font">{formatCurrency(row.net_sales)}</td>
//                                 <td className="small-font">{formatPercentage(row.pm_percent)}</td>
//                                 {/* <td className="small-font">{row.ship_to_vertical}</td> */}
//                                 <td className="small-font">{row.pricing_group}</td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             )}
//         </div>
//     );
// };

// export default DataTable;






import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react'; // Import AgGridReact component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import './DataTable.css';
import { BASE_URL } from '../../common/baseUrl';

const DataTable = ({ selectedYear, selectedBusiness, selectedPricingGroup, selectedCustomerSize }) => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [columnDefs] = useState([
        { headerName: "Customer Name", field: "customer_name", sortable: true, filter: true },
        { headerName: "Product Name", field: "product_name", sortable: true, filter: true },
        { headerName: "Overall Score", field: "overall_score", sortable: true, filter: true },
        { headerName: "Risk Score", field: "risk_score", sortable: true, filter: true },
        { headerName: "Opportunity", field: "opportunity", sortable: true, filter: true, valueFormatter: (params) => formatCurrency(params.value) },
        { headerName: "Net Sales", field: "net_sales", sortable: true, filter: true, valueFormatter: (params) => formatCurrency(params.value) },
        { headerName: "PM Percent", field: "pm_percent", sortable: true, filter: true, valueFormatter: (params) => formatPercentage(params.value) },
        { headerName: "Pricing Group", field: "pricing_group", sortable: true, filter: true },
    ]);

    const defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
    };

    useEffect(() => {
        const fetchTableData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${BASE_URL}/customer-product-score-data`, {
                    params: {
                        year: selectedYear,
                        business: selectedBusiness,
                        pricing_group: selectedPricingGroup,
                        customer_size: selectedCustomerSize,
                    },
                });
                const sortedData = response.data.sort((a, b) => b.opportunity - a.opportunity);
                setTableData(sortedData);
            } catch (error) {
                console.error('Error fetching table data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTableData();
    }, [selectedYear, selectedBusiness, selectedPricingGroup, selectedCustomerSize]);

    const formatCurrency = (value) => {
        return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    };

    const formatPercentage = (value) => {
        return `${(value * 100).toFixed(1)}%`;
    };

    return (
        <div className="ag-theme-alpine" style={{ height: 540, width: '100%' }}>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <AgGridReact
                    rowData={tableData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    // pagination={true}
                    // paginationPageSize={10}
                />
            )}
        </div>
    );
};

export default DataTable;







// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './static/DataTable.css';

// const DataTable = ({ selectedYear, selectedBusiness, selectedPricingGroup, selectedCustomerSize, selectedData }) => {
//   const [tableData, setTableData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const fetchTableData = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get('http://localhost:8000/api/customer-product-score-data/', {
//           params: {
//             year: selectedYear,
//             business: selectedBusiness,
//             pricing_group: selectedPricingGroup,
//             customer_size: selectedCustomerSize,
//           },
//         });
//         setTableData(response.data);
//       } catch (error) {
//         console.error('Error fetching table data:', error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchTableData();
//   }, [selectedYear, selectedBusiness, selectedPricingGroup, selectedCustomerSize]);

//   const filteredData = selectedData ? [selectedData] : tableData;

//   return (
//     <div className="data-table">
//       {isLoading ? (
//         <p>Loading...</p>
//       ) : (
//         <table>
//           <thead>
//             <tr>
//               <th className="small-font">Customer Name</th>
//               <th className="small-font">Product Name</th>
//               <th className="small-font">Overall Score</th>
//               <th className="small-font">Risk Score</th>
//               <th className="small-font">Opportunity</th>
//               <th className="small-font">Net Sales</th>
//               <th className="small-font">PM Percent</th>
//               <th className="small-font">Ship To Vertical</th>
//               <th className="small-font">Pricing Group</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredData.map((row, index) => (
//               <tr key={index}>
//                 <td className="small-font">{row.customer_name}</td>
//                 <td className="small-font">{row.product_name}</td>
//                 <td className="small-font">{row.overall_score}</td>
//                 <td className="small-font">{row.risk_score}</td>
//                 <td className="small-font">{row.opportunity}</td>
//                 <td className="small-font">{row.net_sales}</td>
//                 <td className="small-font">{row.pm_percent}</td>
//                 <td className="small-font">{row.ship_to_vertical}</td>
//                 <td className="small-font">{row.pricing_group}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       )}
//     </div>
//   );
// };

// export default DataTable;
