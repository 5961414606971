// import React from 'react'
// import axios from 'axios'
// import Plot from 'react-plotly.js'
// import { BASE_URL } from '../common/baseUrl'
// import { useState , useEffect} from 'react'


// const CmWaterfall = ({selectedYear, selectedBusiness }) => {
//   const [totals, setTotals] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);


//   useEffect(() => {
//     const fetchTotalsData = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get(`${BASE_URL}/api/cm-bridge-calculations/`, {
//           params: {
//             year: selectedYear,
//             business: selectedBusiness,
//           },
//         });
//         setTotals(response.data); // Access the correct structure
//       } catch (error) {
//         console.error('Error fetching totals data:', error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     if (selectedYear || selectedBusiness) {
//       fetchTotalsData();
//     }
//   }, [selectedYear, selectedBusiness]);


//   const formatCurrency = (value) => {
//     const sign = value < 0 ? '-' : '';
//     const absValue = Math.abs(value);

//     if (absValue >= 1000000000) {
//       const billions = absValue / 1000000000;
//       return `${sign}$${billions.toFixed(billions >= 10 ? 1 : 2)}B`;
//     } else if (absValue >= 1000000) {
//       const millions = absValue / 1000000;
//       return `${sign}$${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
//     } else {
//       return `${sign}$${absValue.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
//     }
//   };

//   const data = [
//     {
//       type: 'waterfall',
//       orientation: 'v',
//       measure: [
//         'total',
//         'relative',
//         'relative',
//         'total',
//         'relative',
//         'relative',
//         'total',
//         'relative',
//         'total',
//         'relative',
//         'relative',
//         'total'
//       ],

//       x: [
//         'customer_sales',
//         'freight_revenue',
//         'sales_adjustements',
//         'net_sales',
//         'freight_costs',
//         'vc_total',
//         'cm',
//         'intercompany_margin',
//         'pocket_margin',
//         'fixed_cost',
//         'depr_cost',
//         'gross_profit'
//       ],
//       text: [
//         formatCurrency(totals.customer_sales),
//         formatCurrency(totals.freight_revenue),
//         formatCurrency(totals.sales_adjustements),
//         formatCurrency(totals.net_sales),
//         formatCurrency(totals.freight_costs),
//         formatCurrency(totals.vc_total),
//         formatCurrency(totals.cm),
//         formatCurrency(totals.intercompany_margin),
//         formatCurrency(totals.pocket_margin),
//         formatCurrency(totals.fixed_cost),
//         formatCurrency(totals.depr_cost),
//         formatCurrency(totals.gross_profit),
//       ],
//       y: [
//         formatCurrency(totals.customer_sales),
//         formatCurrency(totals.freight_revenue),
//         formatCurrency(totals.sales_adjustements),
//         formatCurrency(totals.net_sales),
//         formatCurrency(totals.freight_costs),
//         formatCurrency(totals.vc_total),
//         formatCurrency(totals.cm),
//         formatCurrency(totals.intercompany_margin),
//         formatCurrency(totals.pocket_margin),
//         formatCurrency(totals.fixed_cost),
//         formatCurrency(totals.depr_cost),
//         formatCurrency(totals.gross_profit),
//       ],
//       decreasing: { marker: { color: 'red' } },
//       increasing: { marker: { color: 'green' } },
//       totals: { marker: { color: 'blue' } },

//       // decreasing: { marker: { color: 'red' }, name: 'Decrease' },
//       // increasing: { marker: { color: 'green' }, name: 'Increase' },
//       // totals: { marker: { color: 'blue' }, name: 'Total' },
//     },
//   ];

//   const layout = {
//     title: 'CM Waterfall',
//     // xaxis: { title: 'Metrics' },
//     // yaxis: { title: 'Values' },
//     showlegend: true,
//     width: 800, // Set the width here
//     height: 500, // Set the height here
//     margin: {
//       t: 30, // Reduce the top margin
//       b: 80, // Bottom margin
//       l: 50, // Left margin
//       r: 50, // Right margin
//     },
//   };


//   return <Plot data={data} layout={layout} />;
// }

// export default CmWaterfall




import React from 'react'
import axios from 'axios'
import Plot from 'react-plotly.js'
import { BASE_URL } from '../../common/baseUrl'
import { useState, useEffect } from 'react'

const CmWaterfall = ({ selectedYear, selectedBusiness,selectedVertical, selectedCustomer, selectedMaterial, selectedKeyaccount, selectedProductLine,  }) => {
  const [totals, setTotals] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTotalsData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/cm-bridge-calculations`, {
          params: {
            year: selectedYear,
            business: selectedBusiness,
            vertical:selectedVertical,
            customer: selectedCustomer,
            material:selectedMaterial,
            keyaccount: selectedKeyaccount,
            productline: selectedProductLine
          },
        });
        setTotals(response.data.totals);
      } catch (error) {
        console.error('Error fetching totals data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedYear || selectedBusiness || selectedCustomer || selectedMaterial|| selectedVertical || selectedKeyaccount || selectedProductLine)  {
      fetchTotalsData();
    }
  }, [selectedYear, selectedBusiness, selectedVertical, selectedCustomer, selectedMaterial, selectedKeyaccount, selectedProductLine ]);

  const formatCurrency = (value) => {
    const sign = value < 0 ? '-' : '';
    const absValue = Math.abs(value);

    if (absValue >= 1000000000) {
      const billions = absValue / 1000000000;
      return `${sign}$${billions.toFixed(billions >= 10 ? 1 : 2)}B`;
    } else if (absValue >= 1000000) {
      const millions = absValue / 1000000;
      return `${sign}$${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
    } else {
      return `${sign}$${absValue.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    }
  };

  const formatPercentage = (impactValue, net_sales) => {
    if (net_sales === 0) return 'N/A';
    const percentChange = (impactValue / net_sales) * 100;
    return `${percentChange.toFixed(1)}%`;
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!totals) {
    return <div>No data available</div>;
  }

  const data = [
    {
      type: 'waterfall',
      orientation: 'v',
      textposition: 'outside',
      measure: [
        'relative',
        'relative',
        'relative',
        'total',
        'relative',
        'relative',
        'total',
        'relative',
        'total',
        'relative',
        'relative',
        'total',
      ],
      x: [
        'Customer<br>Sales',
        'Freight<br>Revenue',
        'Sales<br>Adjustements',
        'Net<br>Sales',
        'Freight<br>Cost',
        'Variable<br>Cost',
        'Contribtion<br> Margin',
        'Intercompany<br>Margin',
        'Pocket<br>Margin',
        'Fixed<br>Cost',
        'Depr<br>Cost',
        'Gross<br>Profit',
      ],
      text: [
        formatCurrency(totals.total_customer_sales),
        formatCurrency(totals.total_freight_revenue),
        formatCurrency(totals.total_sales_adjustements*-1),
        formatCurrency(totals.total_net_sales),
        formatCurrency(totals.total_freight_costs*-1),
        formatCurrency(totals.total_vc_total),
        // formatCurrency(totals.total_cm),
        `${formatCurrency(totals.total_cm)} <br> (${formatPercentage(totals.total_cm, totals.total_net_sales)})`,
        formatCurrency(totals.total_intercompany_margin),
        // formatCurrency(totals.total_pocket_margin),
        `${formatCurrency(totals.total_pocket_margin)} <br> (${formatPercentage(totals.total_pocket_margin, totals.total_net_sales)})`,
        formatCurrency(totals.total_fixed_cost),
        formatCurrency(totals.total_depr_cost),
        // formatCurrency(totals.total_pocket_margin - totals.total_fixed_cost - totals.total_depr_cost),
        `${formatCurrency(totals.total_gross_profit)} <br> (${formatPercentage(totals.total_gross_profit, totals.total_net_sales)})`,
        
      ],
      y: [
        totals.total_customer_sales,
        totals.total_freight_revenue,
        totals.total_sales_adjustements*-1,
        totals.total_net_sales,
        totals.total_freight_costs*-1,
        totals.total_vc_total,
        totals.total_cm,
        totals.total_intercompany_margin,
        totals.total_pocket_margin,
        totals.total_fixed_cost*-1,
        totals.total_depr_cost*-1,
        totals.total_gross_profit,
      ],
      decreasing: { marker: { color: '#779ECB' } },
      increasing: { marker: { color: '#779ECB' } },
      totals: { marker: { color: '#434443' } },
      connector: {
        line: {
          width: 0.2, // Set the width of the connecting lines here
          color: 'black', // Optional: set the color of the connecting lines
        },
      },
    },
  ];



  const layout = {
    title: {
      text: `CM Bridge For ${selectedYear} ${
        selectedBusiness ? `For ${selectedBusiness}` : 'Across All Businesses'
      }${
        selectedKeyaccount ? ` and For ${selectedKeyaccount}` : ' And For All Key Accounts'
      }`,
      font: {
        size: 12,
        weight: 500, 
      },
      x: 0, // Position the title to the left
      y: 1, // Position the title to the top
      xanchor: 'left', // Anchor the title to the left
      yanchor: 'top', // Anchor the title to the top
    },

    width: 1250,
    height: 230, 
    xaxis: {
      tickfont: {
        size: 10,
        color: '#b8b8b8',
      },
      automargin: true, // Enables automatic margin for better label display
    },
    yaxis: {
      tickfont: {
        size: 10,
        color: '#b8b8b8',
      },
      // automargin: true, // Automatically adjusts margins based on axis labels
      // range: [0, Math.max(...data[0].y) * 1.3], // Increased range to add extra space at the top
      automargin: true,
      range: [Math.min(...data[0].y) * 1.3, Math.max(...data[0].y) * 1.3], // Adjusting for both negative and positive values
    },
    margin: {
      t: 20, 
      b: 20,
      l: 30,
      r: 30,
    },
  };
  
  return <Plot data={data} layout={layout} />;
  
}

export default CmWaterfall;









// import React from 'react';
// import Plot from 'react-plotly.js';

// const CmWaterfall = ({
//   totals, 
//   selectedYear,
//   selectedBusiness,
//   selectedKeyaccount
// }) => {
//   // Format functions for currency and percentage
//   const formatCurrency = (value) => {
//     if (value === undefined || value === null) return 'N/A';
//     const sign = value < 0 ? '-' : '';
//     const absValue = Math.abs(value);

//     if (absValue >= 1000000000) {
//       const billions = absValue / 1000000000;
//       return `${sign}$${billions.toFixed(billions >= 10 ? 1 : 2)}B`;
//     } else if (absValue >= 1000000) {
//       const millions = absValue / 1000000;
//       return `${sign}$${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
//     } else {
//       return `${sign}$${absValue.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
//     }
//   };

//   const formatPercentage = (impactValue, net_sales) => {
//     if (net_sales === 0 || impactValue === undefined || net_sales === undefined) return 'N/A';
//     const percentChange = (impactValue / net_sales) * 100;
//     return `${percentChange.toFixed(1)}%`;
//   };

//   // Display loading or no data message based on totals availability
//   if (!totals) {
//     return <div>No data available</div>;
//   }

//   // Extract values from totals
//   const {
//     total_customer_sales,
//     total_freight_revenue,
//     total_sales_adjustements,
//     total_net_sales,
//     total_freight_costs,
//     total_vc_total,
//     total_cm,
//     total_intercompany_margin,
//     total_pocket_margin,
//     total_fixed_cost,
//     total_depr_cost,
//     total_gross_profit
//   } = totals;

//   // Waterfall chart data configuration
//   const data = [
//     {
//       type: 'waterfall',
//       orientation: 'v',
//       textposition: 'outside',
//       measure: [
//         'relative',
//         'relative',
//         'relative',
//         'total',
//         'relative',
//         'relative',
//         'total',
//         'relative',
//         'total',
//         'relative',
//         'relative',
//         'total',
//       ],
//       x: [
//         'Customer<br>Sales',
//         'Freight<br>Revenue',
//         'Sales<br>Adjustments',
//         'Net<br>Sales',
//         'Freight<br>Cost',
//         'Variable<br>Cost',
//         'Contribution<br>Margin',
//         'Intercompany<br>Margin',
//         'Pocket<br>Margin',
//         'Fixed<br>Cost',
//         'Depreciation<br>Cost',
//         'Gross<br>Profit',
//       ],
//       text: [
//         formatCurrency(total_customer_sales),
//         formatCurrency(total_freight_revenue),
//         formatCurrency(total_sales_adjustements * -1),
//         formatCurrency(total_net_sales),
//         formatCurrency(total_freight_costs * -1),
//         formatCurrency(total_vc_total),
//         `${formatCurrency(total_cm)} <br> (${formatPercentage(total_cm, total_net_sales)})`,
//         formatCurrency(total_intercompany_margin),
//         `${formatCurrency(total_pocket_margin)} <br> (${formatPercentage(total_pocket_margin, total_net_sales)})`,
//         formatCurrency(total_fixed_cost * -1),
//         formatCurrency(total_depr_cost * -1),
//         `${formatCurrency(total_gross_profit)} <br> (${formatPercentage(total_gross_profit, total_net_sales)})`,
//       ],
//       y: [
//         total_customer_sales,
//         total_freight_revenue,
//         total_sales_adjustements * -1,
//         total_net_sales,
//         total_freight_costs * -1,
//         total_vc_total,
//         total_cm,
//         total_intercompany_margin,
//         total_pocket_margin,
//         total_fixed_cost * -1,
//         total_depr_cost * -1,
//         total_gross_profit,
//       ],
//       decreasing: { marker: { color: '#779ECB' } },
//       increasing: { marker: { color: '#77C4D3' } },
//       totals: { marker: { color: '#434443' } },
//       connector: {
//         line: {
//           width: 0.5,
//           color: 'black',
//         },
//       },
//     },
//   ];

//   // Waterfall chart layout configuration
//   const layout = {
//     title: {
//       text: `CM Bridge for ${selectedYear} ${selectedBusiness ? `For ${selectedBusiness}` : 'Across All Businesses'}${selectedKeyaccount ? ` and For ${selectedKeyaccount}` : ' And For All Key Accounts'}`,
//       font: {
//         size: 14,
//         weight: 'bold',
//       },
//       x: 0,
//       y: 1,
//       xanchor: 'left',
//       yanchor: 'top',
//     },
//     width: 1200,
//     height: 400,
//     xaxis: {
//       tickfont: {
//         size: 12,
//         color: 'grey',
//       },
//       automargin: true,
//     },
//     yaxis: {
//       tickfont: {
//         size: 12,
//         color: 'grey',
//       },
//       automargin: true,
//       range: [0, Math.max(...data[0].y) * 1.2],
//     },
//     margin: {
//       t: 30,
//       b: 40,
//       l: 40,
//       r: 40,
//     },
//   };

//   return <Plot data={data} layout={layout} />;
// };

// export default CmWaterfall;
