import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CmTotals.css';
import { BASE_URL } from '../../common/baseUrl';

const CmTotals = ({ selectedYear, selectedBusiness, selectedPricingGroup,selectedVertical, selectedCustomer,selectedMaterial, selectedKeyaccount, selectedProductLine }) => {
  const [summaryData, setSummaryData] = useState({
    total_net_sales: 0,
    total_cm: 0,
    total_volume_tons: 0,
    total_pocket_margin: 0,
    total_gross_profit:0,
  });

  useEffect(() => {
    console.log('useEffect called with dependencies:', { selectedYear, selectedBusiness, selectedPricingGroup, 
       selectedVertical, selectedCustomer,selectedMaterial, selectedKeyaccount, selectedProductLine});
    fetchSummaryData();
  }, [selectedYear, selectedBusiness, selectedPricingGroup, selectedVertical, selectedCustomer,selectedMaterial, selectedKeyaccount, selectedProductLine]);

  const fetchSummaryData = async () => {
    try {
      console.log('Fetching summary data with params:', {
        year: selectedYear,
        business: selectedBusiness,
        pricing_group: selectedPricingGroup,
        vertical: selectedVertical,
        customer: selectedCustomer,
        material:selectedMaterial,
        keyaccount: selectedKeyaccount,
        productline: selectedProductLine
      });
      const response = await axios.get(`${BASE_URL}/cm-bridge-calculations`, {
        params: {
          year: selectedYear,
          business: selectedBusiness,
          pricing_group: selectedPricingGroup,
          vertical: selectedVertical,
          customer: selectedCustomer,
          material:selectedMaterial,
          keyaccount: selectedKeyaccount,
          productline: selectedProductLine
        }
      });
      const data = response.data.totals;

      console.log('Received data:', data);

      setSummaryData({
        total_net_sales: data.total_net_sales || 0,
        total_cm: data.total_cm || 0,
        // cmPercent: formatPercentage(totalCM,totalNetSales) || 0,
        total_volume_tons: data.total_volume_tons || 0,
        total_pocket_margin: data.total_pocket_margin || 0,
        total_gross_profit: data.total_gross_profit || 0,
        // pmPercent: formatPercentage(totalPocketMargin, totalNetSales) || 0,
      });
    } catch (error) {
      console.error('Error fetching summary data:', error);
    }
  };


  const formatPercentage = (impactValue, net_sales) => {
    if (net_sales === 0) return 'N/A';
    const percentChange = (impactValue / net_sales) * 100;
    return `${percentChange.toFixed(1)}%`;
  };


  const formatCurrency = (value) => {
     
    if (value >= 1000000000) {
      const billions = value / 1000000000;
      return `$${billions.toFixed(billions >= 10 ? 1 : 2)}B`;
    }

    else if (value >= 1000000) {
      // For values greater than or equal to 1,000,000, format as $X.XM
      const millions = value / 1000000;
      return `$${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
    } 
    else {
      // For values less than 1,000,000, format with commas
      return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    }
  };



const formatNumber = (value) => {
  if (value > 1000000){
    const millions = value/1000000;
    return `${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
  }
  return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 });
};



  return (
    <div className="summary-stats-cm">
      <div className="stats-grid-cm">
        <div className="stat-item-cm">
          <span className="stat-value-cm">{formatCurrency(summaryData.total_net_sales)}</span>
          <span className="stat-label-cm">Net Sales</span>
        </div>
        <div className="stat-item-cm">
          <span className="stat-value-cm">{formatCurrency(summaryData.total_cm)}</span>
          <span className="stat-label-cm">Contribution Margin</span>
        </div>
        <div className="stat-item-cm">
          <span className="stat-value-cm">{formatPercentage(summaryData.total_cm, summaryData.total_net_sales)}</span>
          <span className="stat-label-cm">Contribution Margin %</span>
        </div>
        <div className="stat-item-cm">
          <span className="stat-value-cm">{formatNumber(summaryData.total_volume_tons)}</span>
          <span className="stat-label-cm">Volume</span>
        </div>
        <div className="stat-item-cm">
          <span className="stat-value-cm">{formatCurrency(summaryData.total_pocket_margin)}</span>
          <span className="stat-label-cm">Pocket Margin</span>
        </div>
        <div className="stat-item-cm">
          <span className="stat-value-cm">{formatPercentage(summaryData.total_pocket_margin, summaryData.total_net_sales)}</span> 
          <span className="stat-label-cm">Pocket Margin %</span>
        </div>
        <div className="stat-item-cm">
          <span className="stat-value-cm">{formatCurrency(summaryData.total_gross_profit)}</span>
          <span className="stat-label-cm">Gross Profit</span>
        </div>
        <div className="stat-item-cm">
          <span className="stat-value-cm">{formatPercentage(summaryData.total_gross_profit, summaryData.total_net_sales)}</span> 
          <span className="stat-label-cm">Gross Profit %</span>
        </div>
      </div>
    </div>
  );
};

export default CmTotals;
