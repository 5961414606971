// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .bubble-chart{
    border: 1px solid;
} */

/* .tooltip {
    position: absolute;
    text-align: center;
    width: auto;
    height: auto;
    padding: 8px;
    background: white;
    border: 1px solid black;
    pointer-events: none;
  }
   */`, "",{"version":3,"sources":["webpack://./src/components/opportunity_why/BubbleChart/BubbleChart.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;;;;;;;;;;IAUI","sourcesContent":["/* .bubble-chart{\n    border: 1px solid;\n} */\n\n/* .tooltip {\n    position: absolute;\n    text-align: center;\n    width: auto;\n    height: auto;\n    padding: 8px;\n    background: white;\n    border: 1px solid black;\n    pointer-events: none;\n  }\n   */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
