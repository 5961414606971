
// import React, { useState, useEffect } from 'react';
// import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
// import 'ag-grid-community/styles/ag-theme-material.css';
// import axios from 'axios';
// import { BASE_URL } from '../../common/baseUrl';
// import './CmTable.css';

// const CmTable = ({
//   selectedYear,
//   selectedBusiness,
//   selectedVertical,
//   selectedCustomer,
//   selectedMaterial,
//   selectedKeyaccount,
//   selectedProductLine,
//   onKeyaccountChange,
//   onCustomerChange,
//   onMaterialChange,
// }) => {
//   const [cmValues, setCmValues] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const fetchTotalsData = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get(`${BASE_URL}/api/cm-bridge-calculations/`, {
//           params: {
//             year: selectedYear,
//             business: selectedBusiness,
//             vertical: selectedVertical,
//             customer: selectedCustomer,
//             material: selectedMaterial,
//             keyaccount: selectedKeyaccount,
//             productline: selectedProductLine,
//           },
//         });
//         // Update state with the fetched data
//         setCmValues(response.data.customer_material_level_data || []); // Ensure it defaults to an empty array if data is undefined
//       } catch (error) {
//         console.error('Error fetching totals data:', error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     // Fetch data only when all relevant params are set
//     if (
//       selectedYear ||
//       selectedBusiness ||
//       selectedCustomer ||
//       selectedMaterial ||
//       selectedVertical ||
//       selectedKeyaccount ||
//       selectedProductLine
//     ) {
//       fetchTotalsData();
//     }
//   }, [
//     selectedYear,
//     selectedBusiness,
//     selectedVertical,
//     selectedCustomer,
//     selectedMaterial,
//     selectedKeyaccount,
//     selectedProductLine,
//   ]);

  // const handleRowClick = (event) => {
  //   const clickedKeyAccount = event.data.key_account;
  //   const clickedCustomer = event.data.customer_name;
  //   const clickedMaterial = event.data.material_name;

  //   // Toggle selection: if the clicked item is already selected, deselect it
  //   if (
  //     selectedKeyaccount === clickedKeyAccount &&
  //     selectedCustomer === clickedCustomer &&
  //     selectedMaterial === clickedMaterial
  //   ) {
  //     onKeyaccountChange(null); // Deselect key account
  //     onCustomerChange(null); // Deselect customer
  //     onMaterialChange(null); // Deselect material
  //   } else {
  //     onKeyaccountChange(clickedKeyAccount); // Select new key account
  //     onCustomerChange(clickedCustomer); // Select new customer
  //     onMaterialChange(clickedMaterial); // Select new material
  //   }
  // };

//   // Define the column definitions for AG Grid
//   const columnDefs = [
//     { headerName: 'Key Account', field: 'key_account' },
//     { headerName: 'Customer Name', field: 'customer_name' },
//     { headerName: 'Material Name', field: 'material_name' },
//     {
//       headerName: 'Volume',
//       field: 'volume_tons',
//       valueFormatter: (params) =>
//         params.value.toLocaleString(undefined, { maximumFractionDigits: 1 }),
//     },
//     {
//       headerName: 'Net Sales',
//       field: 'net_sales',
//       valueFormatter: (params) =>
//         '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
//     },
    
//     {
//       headerName: 'CM',
//       field: 'cm',
//       valueFormatter: (params) =>
//         '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
//     },
//     {
//       headerName: 'CM %',
//       field: 'cm_percentage',
//       valueGetter: (params) => {
//         const cm = params.data.cm || 0;
//         const netSales = params.data.net_sales || 0;
//         return netSales ? ((cm / netSales) * 100).toFixed(2) + '%' : '0%';
//       },
//     },
//     {
//       headerName: 'CM Per Unit',
//       field: 'cm_per_unit',
//       valueFormatter: (params) =>
//         '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
//       valueGetter: (params) => {
//         const cm = params.data.cm || 0;
//         const volume = params.data.volume_tons || 0;
//         return volume ? (cm / volume).toFixed(1) : 0;
        
//       },
//     },
//     {
//       headerName: 'Pocket Margin',
//       field: 'pocket_margin',
//       valueFormatter: (params) =>
//         '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
//     },
//     {
//       headerName: 'PM %',
//       field: 'pm_percentage',
//       valueGetter: (params) => {
//         const pocketMargin = params.data.pocket_margin || 0;
//         const netSales = params.data.net_sales || 0;
//         return netSales ? ((pocketMargin / netSales) * 100).toFixed(2) + '%' : '0%';
//       },
//     },
//     {
//       headerName: 'PM Per Unit',
//       field: 'pm_per_unit',
//       valueFormatter: (params) =>
//         '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
//       valueGetter: (params) => {
//         const pocketMargin = params.data.pocket_margin || 0;
//         const volume = params.data.volume_tons || 0;
//         return volume ? (pocketMargin / volume).toFixed(1) : 0;
//       },
//     },
//     {
//       headerName: 'Gross Profit',
//       field: 'gross_profit',
//       valueFormatter: (params) =>
//         '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
//     },
//   ];

//   return (
//     <div className="ag-theme-alpine" style={{ height: 350, width: 1300 }}>
//       {isLoading ? (
//         <p>Loading...</p>
//       ) : (
//         <AgGridReact
//           rowData={cmValues}
//           columnDefs={columnDefs}
//           defaultColDef={{
//             sortable: true,
//             filter: true,
//             resizable: true,
//             width: 120,
//           }}
//           onRowClicked={handleRowClick}
//         />
//       )}
//     </div>
//   );
// };

// export default CmTable;









// import React, { useState, useEffect, useRef, useCallback }  from 'react';
// import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
// import 'ag-grid-community/styles/ag-theme-material.css';
// import axios from 'axios';
// import { BASE_URL } from '../../common/baseUrl';
// import './CmTable.css';

// const CmTable = ({
//   selectedYear,
//   selectedBusiness,
//   selectedVertical,
//   selectedCustomer,
//   selectedMaterial,
//   selectedKeyaccount,
//   selectedProductLine,
//   onKeyaccountChange,
//   onCustomerChange,
//   onMaterialChange,
// }) => {
//   const [cmValues, setCmValues] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const gridRef = useRef(); 

//   useEffect(() => {
//     const fetchTotalsData = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get(`${BASE_URL}/api/cm-bridge-calculations/`, {
//           params: {
//             year: selectedYear,
//             business: selectedBusiness,
//             vertical: selectedVertical,
//             customer: selectedCustomer,
//             material: selectedMaterial,
//             keyaccount: selectedKeyaccount,
//             productline: selectedProductLine,
//           },
//         });
//         // Update state with the fetched data
//         setCmValues(response.data.customer_material_level_data || []); // Ensure it defaults to an empty array if data is undefined
//       } catch (error) {
//         console.error('Error fetching totals data:', error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     // Fetch data only when all relevant params are set
//     if (
//       selectedYear ||
//       selectedBusiness ||
//       selectedCustomer ||
//       selectedMaterial ||
//       selectedVertical ||
//       selectedKeyaccount ||
//       selectedProductLine
//     ) {
//       fetchTotalsData();
//     }
//   }, [selectedYear, selectedBusiness, selectedVertical, selectedCustomer, selectedMaterial, selectedKeyaccount, selectedProductLine]);

//   // Handle filter changes
//   const onFilterChanged = () => {
//     // Access grid API to get the current filtered rows
//     const filteredNodes = gridRef.current.api.getModel().rowsToDisplay;
//     if (filteredNodes.length > 0) {
//       // Get the first filtered row data (can be modified as needed)
//       const firstFilteredRow = filteredNodes[0].data;

//       // Pass the filtered values to the parent component
//       onKeyaccountChange(firstFilteredRow.key_account || null);
//       // onCustomerChange(firstFilteredRow.customer_name || null);
//       // onMaterialChange(firstFilteredRow.material_name || null);
//     }
//   };

//   // Define the column definitions for AG Grid
//   const columnDefs = [
//     { headerName: 'Key Account', field: 'key_account' },
//     { headerName: 'Customer Name', field: 'customer_name' },
//     { headerName: 'Material Name', field: 'material_name' },
//     {
//       headerName: 'Volume',
//       field: 'volume_tons',
//       valueFormatter: (params) =>
//         params.value.toLocaleString(undefined, { maximumFractionDigits: 1 }),
//     },
//     {
//       headerName: 'Net Sales',
//       field: 'net_sales',
//       valueFormatter: (params) =>
//         '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
//     },
    
//     {
//       headerName: 'CM',
//       field: 'cm',
//       valueFormatter: (params) =>
//         '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
//     },
//     {
//       headerName: 'CM %',
//       field: 'cm_percentage',
//       valueGetter: (params) => {
//         const cm = params.data.cm || 0;
//         const netSales = params.data.net_sales || 0;
//         return netSales ? ((cm / netSales) * 100).toFixed(2) + '%' : '0%';
//       },
//     },
//     {
//       headerName: 'CM Per Unit',
//       field: 'cm_per_unit',
//       valueFormatter: (params) =>
//         '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
//       valueGetter: (params) => {
//         const cm = params.data.cm || 0;
//         const volume = params.data.volume_tons || 0;
//         return volume ? (cm / volume).toFixed(1) : 0;
        
//       },
//     },
//     {
//       headerName: 'Pocket Margin',
//       field: 'pocket_margin',
//       valueFormatter: (params) =>
//         '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
//     },
//     {
//       headerName: 'PM %',
//       field: 'pm_percentage',
//       valueGetter: (params) => {
//         const pocketMargin = params.data.pocket_margin || 0;
//         const netSales = params.data.net_sales || 0;
//         return netSales ? ((pocketMargin / netSales) * 100).toFixed(2) + '%' : '0%';
//       },
//     },
//     {
//       headerName: 'PM Per Unit',
//       field: 'pm_per_unit',
//       valueFormatter: (params) =>
//         '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
//       valueGetter: (params) => {
//         const pocketMargin = params.data.pocket_margin || 0;
//         const volume = params.data.volume_tons || 0;
//         return volume ? (pocketMargin / volume).toFixed(1) : 0;
//       },
//     },
//     {
//       headerName: 'Gross Profit',
//       field: 'gross_profit',
//       valueFormatter: (params) =>
//         '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
//     },
//   ];


//   return (
//     <div className="ag-theme-alpine" style={{ height: 350, width: 1300 }}>
//       {isLoading ? (
//         <p>Loading...</p>
//       ) : (
//         <AgGridReact
//           ref={gridRef}
//           rowData={cmValues}
//           columnDefs={columnDefs}
//           defaultColDef={{
//             sortable: true,
//             filter: true,
//             resizable: true,
//             width: 120,
//           }}
//           // onRowClicked={handleRowClick}
//           onFilterChanged={onFilterChanged} // Handle filter changes
//         />
//       )}
//     </div>
//   );
// };

// export default CmTable;










import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import { BASE_URL } from '../../common/baseUrl';
import debounce from 'lodash.debounce';
import './CmTable.css';

const CmTable = ({
  selectedYear,
  selectedBusiness,
  selectedVertical,
  selectedCustomer,
  selectedMaterial,
  selectedKeyaccount,
  selectedProductLine,
  onProductLineChange,
  onKeyaccountChange,
  onCustomerChange,
  onMaterialChange,
}) => {
  const [cmValues, setCmValues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pinnedRowData, setPinnedRowData] = useState([]);
  const gridRef = useRef();

  useEffect(() => {
    const fetchTotalsData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/cm-bridge-calculations`, {
          params: {
            year: selectedYear,
            business: selectedBusiness,
            vertical: selectedVertical,
            customer: selectedCustomer,
            material: selectedMaterial,
            keyaccount: selectedKeyaccount,
            productline: selectedProductLine,
          },
        });

         const data = response.data.customer_material_level_data || [];
         setCmValues(data);
         setPinnedRowData([calculateTotals(data)]);
      } catch (error) {
        console.error('Error fetching totals data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (
      selectedYear ||
      selectedBusiness ||
      selectedVertical ||
      selectedCustomer ||
      selectedMaterial ||
      selectedKeyaccount ||
      selectedProductLine
    ) {
      fetchTotalsData();
    }
  }, [
    selectedYear,
    selectedBusiness,
    selectedVertical,
    selectedCustomer,
    selectedMaterial,
    selectedKeyaccount,
    selectedProductLine,
  ]);




    // Calculate totals for each column
  const calculateTotals = (data) => {
    const totals = {
      key_account: 'Totals',
      customer_name: '',
      material_name: '',
      volume_tons: 0,
      net_sales: 0,
      cm: 0,
      pocket_margin: 0,
    };

    data.forEach((item) => {
      totals.volume_tons += item.volume_tons || 0;
      totals.net_sales += item.net_sales || 0;
      totals.cm += item.cm || 0;
      totals.pocket_margin += item.pocket_margin || 0;
    });

    // Calculate derived values for totals
    totals.cm_percentage = totals.net_sales
      ? `${((totals.cm / totals.net_sales) * 100).toFixed(2)}%`
      : '0%';
    totals.cm_per_unit = totals.volume_tons
      ? (totals.cm / totals.volume_tons).toFixed(1)
      : 0;
    totals.pm_percentage = totals.net_sales
      ? `${((totals.pocket_margin / totals.net_sales) * 100).toFixed(2)}%`
      : '0%';
    totals.pm_per_unit = totals.volume_tons
      ? (totals.pocket_margin / totals.volume_tons).toFixed(1)
      : 0;

    return totals;
  };

  const debouncedOnFilterChanged = useCallback(
    debounce(() => {
      if (gridRef.current) {
        const filterModel = gridRef.current.api.getFilterModel();
        const filteredNodes = [];
        gridRef.current.api.forEachNodeAfterFilterAndSort((node) => filteredNodes.push(node));

        if (filteredNodes.length > 0) {
          const firstRowData = filteredNodes[0].data;

          // Get filter values if filters are applied
          const keyAccountValue = firstRowData.key_account || null;
          const customerValue = firstRowData.customer_name || null;
          const materialValue = firstRowData.material_name || null;

          // Determine which values to pass to parent based on active filters
          if (filterModel['material_name']) {
            onMaterialChange(materialValue);
            onKeyaccountChange(keyAccountValue);
            onCustomerChange(customerValue);
          } else if (filterModel['customer_name']) {
            onCustomerChange(customerValue);
            onKeyaccountChange(keyAccountValue);
          } else if (filterModel['key_account']) {
            onKeyaccountChange(keyAccountValue);
          }

          console.log('Values passed to parent:', {
            keyAccountValue,
            customerValue,
            materialValue,
          });
        }
      }
    }, 1500),
    [onProductLineChange, onKeyaccountChange, onCustomerChange, onMaterialChange]
  );

  const clearFilters = () => {
    gridRef.current.api.setFilterModel(null);
    onKeyaccountChange(null);
    onCustomerChange(null);
    onMaterialChange(null);
  };


  const handleRowClick = (event) => {
    const clickedKeyAccount = event.data.key_account;
    const clickedCustomer = event.data.customer_name;
    const clickedMaterial = event.data.material_name;

    // Toggle selection: if the clicked item is already selected, deselect it
    if (
      selectedKeyaccount === clickedKeyAccount &&
      selectedCustomer === clickedCustomer &&
      selectedMaterial === clickedMaterial
    ) {
      onKeyaccountChange(null); // Deselect key account
      onCustomerChange(null); // Deselect customer
      onMaterialChange(null); // Deselect material
    } else {
      onKeyaccountChange(clickedKeyAccount); // Select new key account
      onCustomerChange(clickedCustomer); // Select new customer
      onMaterialChange(clickedMaterial); // Select new material
    }
  };

  const getRowHeight = (params) => {
    if (params.node.rowPinned) {
      // Set the height for the pinned (total) row; adjust as needed
      return 25; // Example height for the total row
    }
    // Set the height for the regular rows
    return 25; // Example height for regular rows
  };

  const HorizontalBarRenderer = (params) => {

    const isPinnedRow = params.node.rowPinned;
    const value = params.value || 0;
    if (isPinnedRow) {
        const formattedValue = `$${value.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        })}`;
        return <span>{formattedValue}</span>;
      }




    if (params.node.rowPinned) {
      return <span>{params.value ? params.value.toLocaleString() : ''}</span>;
    }

    const maxValue = Math.max(...cmValues.map((item) => item[params.colDef.field]));
    // const value = params.value || 0;
    const percentage = (value / maxValue) * 100;
  
    let barColor;
    let displayValue;
  
    switch (params.colDef.field) {
      case 'volume_tons':
        barColor = 'rgb(130 175 227)';
        displayValue = value.toLocaleString(undefined, { maximumFractionDigits: 0 });
        break;
      case 'net_sales':
        barColor = 'rgb(130 175 227)';
        displayValue = `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
        break;
      case 'cm':
        barColor = 'rgb(130 175 227)';
        displayValue = `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
        break;
      case 'pocket_margin':
        barColor = 'rgb(130 175 227)';
        displayValue = `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
        break;
      default:
        barColor = 'rgb(134, 228, 138)';
        displayValue = `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
        break;
    }
  
    return (
      <div className="bar-cell">
        <span className="bar-value">{displayValue}</span>
        <div className="bar" style={{ width: `${percentage}%`, backgroundColor: barColor }}></div>
      </div>
    );
  };

  const columnDefs = [
    { headerName: 'Key Account', field: 'key_account', filter: 'agTextColumnFilter', width: 120 },
    { headerName: 'Customer Name', field: 'customer_name', filter: 'agTextColumnFilter', width: 160 },
    { headerName: 'Material Name', field: 'material_name', filter: 'agTextColumnFilter', width: 160 },
    {
      headerName: 'Volume',
      field: 'volume_tons',
      cellRenderer: HorizontalBarRenderer,
      width: 100,
    },
    {
      headerName: 'Net Sales',
      field: 'net_sales',
      cellRenderer: HorizontalBarRenderer,
      width: 100,
    },
    {
      headerName: 'CM',
      field: 'cm',
      cellRenderer: HorizontalBarRenderer,
      width: 100,
    },
    {
      headerName: 'CM %',
      field: 'cm_percentage',
      valueGetter: (params) => {
        const cm = params.data.cm || 0;
        const netSales = params.data.net_sales || 0;
        return netSales ? ((cm / netSales) * 100).toFixed(2) + '%' : '0%';
      },
      width: 100,
    },
    {
      headerName: 'CM Per Unit',
      field: 'cm_per_unit',
      valueFormatter: (params) => '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
      valueGetter: (params) => {
        const cm = params.data.cm || 0;
        const volume = params.data.volume_tons || 0;
        return volume ? (cm / volume).toFixed(1) : 0;
      },
      width: 100,
    },
    {
      headerName: 'PM',
      field: 'pocket_margin',
      cellRenderer: HorizontalBarRenderer,
      width: 100,
    },
    {
      headerName: 'PM %',
      field: 'pm_percentage',
      valueGetter: (params) => {
        const pocketMargin = params.data.pocket_margin || 0;
        const netSales = params.data.net_sales || 0;
        return netSales ? ((pocketMargin / netSales) * 100).toFixed(2) + '%' : '0%';
      },
      width: 100,
    },
    {
      headerName: 'PM Per Unit',
      field: 'pm_per_unit',
      valueFormatter: (params) => '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
      valueGetter: (params) => {
        const pocketMargin = params.data.pocket_margin || 0;
        const volume = params.data.volume_tons || 0;
        return volume ? (pocketMargin / volume).toFixed(1) : 0;
      },
      width: 100,
    },
  ];

  return (
    <div className="ag-theme-alpine" style={{ height: 290, width: '100%' }}>
      {/* <div className="title-button">
        <div className="clear-button">
          <button onClick={clearFilters} className="clear-filters-button">
            Clear Filters
          </button>
        </div>
      </div> */}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <AgGridReact
          ref={gridRef}
          rowData={cmValues}
          columnDefs={columnDefs}
          pinnedBottomRowData={pinnedRowData}
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
            width: 120,
          }}
          getRowHeight={getRowHeight}
          onFilterChanged={debouncedOnFilterChanged} // Call debounced filter handler
          onRowClicked={handleRowClick}
        />
      )}
    </div>
  );
};

export default CmTable;





// import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { AgGridReact } from 'ag-grid-react';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
// import axios from 'axios';
// import { BASE_URL } from '../../common/baseUrl';
// import debounce from 'lodash.debounce';
// import './CmTable.css';

// const CmTable = ({
//   selectedYear,
//   selectedBusiness,
//   selectedVertical,
//   selectedCustomer,
//   selectedMaterial,
//   selectedKeyaccount,
//   selectedProductLine,
//   onProductLineChange,
//   onKeyaccountChange,
//   onCustomerChange,
//   onMaterialChange,
// }) => {
//   const [cmValues, setCmValues] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [pinnedRowData, setPinnedRowData] = useState([]);
//   const gridRef = useRef();

//   useEffect(() => {
//     const fetchTotalsData = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get(`${BASE_URL}/api/cm-bridge-calculations/`, {
//           params: {
//             year: selectedYear,
//             business: selectedBusiness,
//             vertical: selectedVertical,
//             customer: selectedCustomer,
//             material: selectedMaterial,
//             keyaccount: selectedKeyaccount,
//             productline: selectedProductLine,
//           },
//         });

//         const data = response.data.customer_material_level_data || [];
//         setCmValues(data);
//         setPinnedRowData([calculateTotals(data)]);
//       } catch (error) {
//         console.error('Error fetching totals data:', error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     if (
//       selectedYear ||
//       selectedBusiness ||
//       selectedVertical ||
//       selectedCustomer ||
//       selectedMaterial ||
//       selectedKeyaccount ||
//       selectedProductLine
//     ) {
//       fetchTotalsData();
//     }
//   }, [
//     selectedYear,
//     selectedBusiness,
//     selectedVertical,
//     selectedCustomer,
//     selectedMaterial,
//     selectedKeyaccount,
//     selectedProductLine,
//   ]);

//   // Calculate totals for each column
//   const calculateTotals = (data) => {
//     const totals = {
//       key_account: 'Totals',
//       customer_name: '',
//       material_name: '',
//       volume_tons: 0,
//       net_sales: 0,
//       cm: 0,
//       pocket_margin: 0,
//     };

//     data.forEach((item) => {
//       totals.volume_tons += item.volume_tons || 0;
//       totals.net_sales += item.net_sales || 0;
//       totals.cm += item.cm || 0;
//       totals.pocket_margin += item.pocket_margin || 0;
//     });

//     // Calculate derived values for totals
//     totals.cm_percentage = totals.net_sales
//       ? `${((totals.cm / totals.net_sales) * 100).toFixed(2)}%`
//       : '0%';
//     totals.cm_per_unit = totals.volume_tons
//       ? (totals.cm / totals.volume_tons).toFixed(1)
//       : 0;
//     totals.pm_percentage = totals.net_sales
//       ? `${((totals.pocket_margin / totals.net_sales) * 100).toFixed(2)}%`
//       : '0%';
//     totals.pm_per_unit = totals.volume_tons
//       ? (totals.pocket_margin / totals.volume_tons).toFixed(1)
//       : 0;

//     return totals;
//   };

//   const debouncedOnFilterChanged = useCallback(
//     debounce(() => {
//       if (gridRef.current) {
//         const filterModel = gridRef.current.api.getFilterModel();
//         const filteredNodes = [];
//         gridRef.current.api.forEachNodeAfterFilterAndSort((node) =>
//           filteredNodes.push(node)
//         );

//         if (filteredNodes.length > 0) {
//           const firstRowData = filteredNodes[0].data;

//           // Get filter values if filters are applied
//           const keyAccountValue = firstRowData.key_account || null;
//           const customerValue = firstRowData.customer_name || null;
//           const materialValue = firstRowData.material_name || null;

//           // Determine which values to pass to parent based on active filters
//           if (filterModel['material_name']) {
//             onMaterialChange(materialValue);
//             onKeyaccountChange(keyAccountValue);
//             onCustomerChange(customerValue);
//           } else if (filterModel['customer_name']) {
//             onCustomerChange(customerValue);
//             onKeyaccountChange(keyAccountValue);
//           } else if (filterModel['key_account']) {
//             onKeyaccountChange(keyAccountValue);
//           }

//           console.log('Values passed to parent:', {
//             keyAccountValue,
//             customerValue,
//             materialValue,
//           });
//         }
//       }
//     }, 1500),
//     [onProductLineChange, onKeyaccountChange, onCustomerChange, onMaterialChange]
//   );

//   const clearFilters = () => {
//     gridRef.current.api.setFilterModel(null);
//     onKeyaccountChange(null);
//     onCustomerChange(null);
//     onMaterialChange(null);
//   };

//   const HorizontalBarRenderer = (params) => {
//     const maxValue = Math.max(...cmValues.map((item) => item[params.colDef.field]));
//     const value = params.value || 0;
//     const percentage = (value / maxValue) * 100;

//     let barColor;
//     let displayValue;

//     switch (params.colDef.field) {
//       case 'volume_tons':
//         barColor = 'rgb(130 175 227)';
//         displayValue = value.toLocaleString(undefined, { maximumFractionDigits: 0 });
//         break;
//       case 'net_sales':
//         barColor = 'rgb(130 175 227)';
//         displayValue = `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
//         break;
//       case 'cm':
//         barColor = 'rgb(130 175 227)';
//         displayValue = `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
//         break;
//       case 'pocket_margin':
//         barColor = 'rgb(130 175 227)';
//         displayValue = `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
//         break;
//       default:
//         barColor = 'rgb(134, 228, 138)';
//         displayValue = `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`;
//         break;
//     }

//     return (
//       <div className="bar-cell">
//         <span className="bar-value">{displayValue}</span>
//         <div className="bar" style={{ width: `${percentage}%`, backgroundColor: barColor }}></div>
//       </div>
//     );
//   };

//   const columnDefs = [
//     { headerName: 'Key Account', field: 'key_account', filter: 'agTextColumnFilter', width: 120 },
//     { headerName: 'Customer Name', field: 'customer_name', filter: 'agTextColumnFilter', width: 160 },
//     { headerName: 'Material Name', field: 'material_name', filter: 'agTextColumnFilter', width: 160 },
//     {
//       headerName: 'Volume',
//       field: 'volume_tons',
//       cellRenderer: HorizontalBarRenderer,
//       width: 100,
//     },
//     {
//       headerName: 'Net Sales',
//       field: 'net_sales',
//       cellRenderer: HorizontalBarRenderer,
//       width: 100,
//     },
//     {
//       headerName: 'CM',
//       field: 'cm',
//       cellRenderer: HorizontalBarRenderer,
//       width: 100,
//     },
//     {
//       headerName: 'CM %',
//       field: 'cm_percentage',
//       valueGetter: (params) => {
//         const cm = params.data.cm || 0;
//         const netSales = params.data.net_sales || 0;
//         return netSales ? ((cm / netSales) * 100).toFixed(2) + '%' : '0%';
//       },
//       width: 100,
//     },
//     {
//       headerName: 'CM Per Unit',
//       field: 'cm_per_unit',
//       valueFormatter: (params) => '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
//       valueGetter: (params) => {
//         const cm = params.data.cm || 0;
//         const volume = params.data.volume_tons || 0;
//         return volume ? (cm / volume).toFixed(1) : 0;
//       },
//       width: 100,
//     },
//     {
//       headerName: 'PM',
//       field: 'pocket_margin',
//       cellRenderer: HorizontalBarRenderer,
//       width: 100,
//     },
//     {
//       headerName: 'PM %',
//       field: 'pm_percentage',
//       valueGetter: (params) => {
//         const pocketMargin = params.data.pocket_margin || 0;
//         const netSales = params.data.net_sales || 0;
//         return netSales ? ((pocketMargin / netSales) * 100).toFixed(2) + '%' : '0%';
//       },
//       width: 100,
//     },
//     {
//       headerName: 'PM Per Unit',
//       field: 'pm_per_unit',
//       valueFormatter: (params) => '$' + params.value.toLocaleString(undefined, { maximumFractionDigits: 0 }),
//       valueGetter: (params) => {
//         const pocketMargin = params.data.pocket_margin || 0;
//         const volume = params.data.volume_tons || 0;
//         return volume ? (pocketMargin / volume).toFixed(1) : 0;
//       },
//       width: 100,
//     },
//   ];

//   return (
//     <div className="ag-theme-alpine cm-table-container" style={{ height: '290px', width: '100%' }}>
//       {isLoading ? (
//         <div>Loading data...</div>
//       ) : (
//         <AgGridReact
//           ref={gridRef}
//           rowData={cmValues}
//           columnDefs={columnDefs}
//           pinnedBottomRowData={pinnedRowData}
//           // domLayout="autoHeight"
//           // rowHeight={30}
//           // pagination={true}
//           // paginationPageSize={50}
//           cacheQuickFilter={true}
//           enableCellTextSelection={true}
//           animateRows={true}
//           onFilterChanged={debouncedOnFilterChanged}
//         />
//       )}
//     </div>
//   );
// };

// export default CmTable;






