
// import React, { useEffect, useState } from 'react';
// import Plot from 'react-plotly.js';
// import axios from 'axios';
// import { BASE_URL } from '../../common/baseUrl';

// const OverallStackedBarchart = ({
//   selectedYear,
//   selectedBusiness,
//   selectedVertical,
//   selectedCustomer,
//   selectedMaterial,
//   selectedKeyaccount,
//   selectedProductLine,
// }) => {
//   const [chartData, setChartData] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     const fetchTotalsData = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get(`${BASE_URL}/api/price-category/`, {
//           params: {
//             year: selectedYear || undefined,
//             business: selectedBusiness || undefined,
//             vertical: selectedVertical || undefined,
//             customer: selectedCustomer || undefined,
//             material: selectedMaterial || undefined,
//             keyaccount: selectedKeyaccount || undefined,
//             productline: selectedProductLine || undefined,
//           },
//         });
//         console.log('API response:', response.data);
//         setChartData(response.data.monthly_summary);
//       } catch (error) {
//         console.error('Error fetching totals data:', error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     // Fetch data if any filters are selected
//     if (
//       selectedYear ||
//       selectedBusiness ||
//       selectedCustomer ||
//       selectedMaterial ||
//       selectedVertical ||
//       selectedKeyaccount ||
//       selectedProductLine
//     ) {
//       fetchTotalsData();
//     }
//   }, [
//     selectedYear,
//     selectedBusiness,
//     selectedVertical,
//     selectedCustomer,
//     selectedMaterial,
//     selectedKeyaccount,
//     selectedProductLine,
//   ]);

//   // Process the chart data into a format suitable for plotting
//   const processChartData = () => {
//     // Group data by month and category, filtering out null or undefined values
//     const dataByMonth = {};
//     chartData.forEach(({ month, month_customer_sales, overall_price_change_category }) => {
//       if (month == null || overall_price_change_category == null || month_customer_sales == null) return;

//       if (!dataByMonth[month]) {
//         dataByMonth[month] = {};
//       }
//       if (!dataByMonth[month][overall_price_change_category]) {
//         dataByMonth[month][overall_price_change_category] = 0;
//       }
//       dataByMonth[month][overall_price_change_category] += month_customer_sales;
//     });

//     // Normalize data by calculating the percentage of total sales for each category in each month
//     const normalizedData = {};
//     Object.keys(dataByMonth).forEach((month) => {
//       const monthData = dataByMonth[month];
//       const totalSales = Object.values(monthData).reduce((sum, sales) => sum + (sales || 0), 0); // Sum sales while ignoring nulls

//       // Check for zero total sales to avoid division by zero
//       normalizedData[month] = {};
//       Object.keys(monthData).forEach((category) => {
//         normalizedData[month][category] = totalSales > 0 ? (monthData[category] / totalSales) : 0;
//       });
//     });

//     console.log('Normalized Data:', normalizedData);

//     return normalizedData;
//   };

//   // Prepare the data for Plotly traces
//   const preparePlotlyTraces = (normalizedData) => {
//     const categories = [...new Set(chartData.map(item => item.overall_price_change_category).filter(Boolean))];
//     const months = [...new Set(chartData.map(item => item.month).filter(Boolean))];

//     return categories.map((category) => {
//       const yValues = months.map((month) => normalizedData[month]?.[category] || 0);
//       const textLabels = yValues.map(value => `${(value * 100).toFixed(1)}%`); // Convert to percentage

//       return {
//         x: months,
//         y: yValues,
//         text: textLabels,
//         textposition: 'inside',
//         textposition: 'inside', // Center text inside each bar segment
//         texttemplate: '%{text}', // Use text labels directly
//         type: 'bar',
//         name: category,
//         marker: { color: getColorForCategory(category) },
//       };
//     });
//   };

//   const getColorForCategory = (category) => {
//     switch (category) {
//       case 'No Sales Last Year':
//         return '#eaed21'; 
//       case 'Less than -3%':
//         return '#f55958';
//       case '-3% to 0%':
//         return '#eff777'; 
//       case '0%':
//         return '#94f7a0';
//       case '0% to 3%':
//         return '#eff777'; 
//       case 'Greater than 3%':
//         return '#04bf19';
//       case 'No Sales This Year':
//         return '#eaed21'; 
//       default:
//         return '#d62728';
//     }
//   };

//   // Process and prepare traces for Plotly
//   const normalizedData = processChartData();
//   const traces = preparePlotlyTraces(normalizedData);

//   return (
//     <div>
//       {isLoading ? (
//         <p>Loading...</p>
//       ) : (
//         <Plot
//           data={traces}
//           layout={{
//             title: 'Customer Sales by Price Change Category',
//             barmode: 'stack',
//             xaxis: {
//               title: 'Month',
//               tickangle: -45,
//             },
//             height: 500,
//             width: 1200,
//             yaxis: {
//               title: 'Percentage of Net Sales',
//               tickformat: '.0%',
//             },
//             showlegend: true,
//           }}
//           config={{ responsive: true }}
//         />
//       )}
//     </div>
//   );
// };

// export default OverallStackedBarchart;







import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import axios from 'axios';
import { BASE_URL } from '../../common/baseUrl';

const OverallStackedBarchart = ({
  selectedYear,
  selectedBusiness,
  selectedVertical,
  selectedCustomer,
  selectedMaterial,
  selectedKeyaccount,
  selectedProductLine,
}) => {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showStackedChart, setShowStackedChart] = useState(false);

  useEffect(() => {
    const fetchTotalsData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/price-category`, {
          params: {
            year: selectedYear || undefined,
            business: selectedBusiness || undefined,
            vertical: selectedVertical || undefined,
            customer: selectedCustomer || undefined,
            material: selectedMaterial || undefined,
            keyaccount: selectedKeyaccount || undefined,
            productline: selectedProductLine || undefined,
          },
        });
        console.log('API response:', response.data);
        setChartData(response.data.monthly_summary);
      } catch (error) {
        console.error('Error fetching totals data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    // Fetch data if any filters are selected
    if (
      selectedYear ||
      selectedBusiness ||
      selectedCustomer ||
      selectedMaterial ||
      selectedVertical ||
      selectedKeyaccount ||
      selectedProductLine
    ) {
      fetchTotalsData();
    }
  }, [
    selectedYear,
    selectedBusiness,
    selectedVertical,
    selectedCustomer,
    selectedMaterial,
    selectedKeyaccount,
    selectedProductLine,
  ]);

  // Process the chart data into a format suitable for plotting
  const processChartData = () => {
    const dataByMonth = {};
    chartData.forEach(({ month, month_customer_sales, overall_price_change_category }) => {
      if (month == null || overall_price_change_category == null || month_customer_sales == null) return;

      if (!dataByMonth[month]) {
        dataByMonth[month] = {};
      }
      if (!dataByMonth[month][overall_price_change_category]) {
        dataByMonth[month][overall_price_change_category] = 0;
      }
      dataByMonth[month][overall_price_change_category] += month_customer_sales;
    });

    const normalizedData = {};
    Object.keys(dataByMonth).forEach((month) => {
      const monthData = dataByMonth[month];
      const totalSales = Object.values(monthData).reduce((sum, sales) => sum + (sales || 0), 0);

      normalizedData[month] = {};
      Object.keys(monthData).forEach((category) => {
        normalizedData[month][category] = totalSales > 0 ? (monthData[category] / totalSales) : 0;
      });
    });

    console.log('Normalized Data:', normalizedData);

    return normalizedData;
  };

  // Prepare the data for Plotly traces
  const preparePlotlyTraces = (normalizedData) => {
    const categories = [...new Set(chartData.map(item => item.overall_price_change_category).filter(Boolean))];
    const months = [...new Set(chartData.map(item => item.month).filter(Boolean))];

    return categories.map((category) => {
      const yValues = months.map((month) => normalizedData[month]?.[category] || 0);
      const textLabels = yValues.map(value => `${(value * 100).toFixed(1)}%`);

      return {
        x: months,
        y: yValues,
        text: textLabels,
        textposition: 'inside',
        texttemplate: '%{text}',
        type: 'bar',
        name: category,
        marker: { color: getColorForCategory(category) },
      };
    });
  };

  const getColorForCategory = (category) => {
    switch (category) {
      case 'No Sales Last Year':
        return '#a4a8ab'; 
      case 'Less than -3%':
        return '#f58e9c';
      case '-3% to 0%':
        return '#05ecf0'; 
      case '0%':
        return '#cc93ed';
      case '0% to 3%':
        return '#33c1c8'; 
      case 'Greater than 3%':
        return '#2099f5';
      case 'No Sales This Year':
        return '#8ee3e6'; 
      default:
        return '#d62728';
    }
  };

  const normalizedData = processChartData();
  const traces = preparePlotlyTraces(normalizedData);

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Plot
          data={traces}
          layout={{
            title:{
                text:'Customer Sales by Price Change Category',
                font:{
                    size:13
                }
              } ,
            barmode: 'stack',
            xaxis: {
                // title: {
                //     text: 'Month',
                //     font: {
                //       size: 14, // Adjust the size for the x-axis title
                //       color: 'grey',
                //     },
                //   },
            //   tickangle: -45,
            tickfont: {
                size: 10,
                color: 'grey',
              },
              automargin: true,
            },
            height: 220,
            width: '1250',
            yaxis: {
            //   title: 'Percentage of Net Sales',
              tickformat: '.0%',
              tickfont: {
                size: 10,
                color: 'grey',
              },
              automargin: true,
            },
            margin: {
                t: 20, // Reduce the top margin
                b: 20, // Bottom margin
                l: 50, // Left margin
                r: 50, // Right margin
              },
            showlegend: true,
            legend: {
              itemclick: 'toggleothers',  // Toggle only the clicked item
              itemdoubleclick: 'toggle',  // Double-click to show/hide the clicked trace
            },
          }}
          config={{ responsive: true }}
        />
      )}
    </div>
  );
};

export default OverallStackedBarchart;







