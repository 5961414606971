// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();






import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import './index.css';
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig.js";
import { PublicClientApplication } from "@azure/msal-browser";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
    mutations: {
      refetchOnWindowFocus: false,
      retry: false,
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});
// const sessionStoragePersister = createSyncStoragePersister({ storage: window.sessionStorage })

persistQueryClient({
  queryClient,
  persister: localStoragePersister,
});

// Create a theme instance.
const theme = createTheme({
  palette: {
    default: {
      main: "#ADADAD",
    },
    success: {
      main: "#00A725",
    },
    warning: {
      main: "#FFB800",
    },
    error: {
      main: "#D91A1A",
    },
    primary: {
      main: "#00A725",
    },
    secondary: {
      main: "#f9f9f9",
    },
  },
  typography: {
    fontFamily: ["DM Sans", "sans-serif"].join(","),
  },
});

/**
 * Represents an instance of the PublicClientApplication class.
 * @type {PublicClientApplication}
 */
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </ThemeProvider>
  </QueryClientProvider>
  // </React.StrictMode>,
);
