// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// // import Home from './pages/OpportunityHowMuch';
// import Home from './pages/Home/Home';
// import OpportunityHowMuch from './pages/OpportunityHowMuch/OpportunityHowMuch';
// import OpportunityWhy from './pages/OpportunityWhy/OpportunityWhy';
// import './App.css';
// import Pvm from './pages/Pvm/Pvm';
// import CmAnalysis from './pages/CmAnalysis/CmAnalysis';
// import PriceChange from './pages/PriceChange/PriceChange';


// function App() {
//     return (
//         <Router>
//             <div className="App">
                
//                 <Routes>
//                     <Route path="/home" element={<Home />} />
//                     <Route path="/" element={<OpportunityHowMuch />} />
//                     <Route path="/pricing-detail" element={<OpportunityWhy />} />
//                     <Route path="/pvm" element={<Pvm />} />
//                     <Route path="/cm-bridge" element={<CmAnalysis/>} />
//                     <Route path="/price-change" element={<PriceChange/>} />
//                 </Routes>
//             </div>
//         </Router>
//     );
// }

// export default App;






import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import Home from './pages/Home/Home.jsx';
import OpportunityHowMuch from './pages/OpportunityHowMuch/OpportunityHowMuch.jsx';
import OpportunityWhy from './pages/OpportunityWhy/OpportunityWhy.jsx';
import Pvm from './pages/Pvm/Pvm.jsx';
import CmAnalysis from './pages/CmAnalysis/CmAnalysis.jsx';
import PriceChange from './pages/PriceChange/PriceChange.jsx';
import WithAuth from './ProtectedRoute.jsx';
import WithoutAuth from './PublicRoute.jsx';
import Login from './pages/Login/index.jsx';
import SignUp from './pages/Signup/index.jsx';



function App() {
  return (
    <BrowserRouter>
      <Routes>
          {/* Default route when visiting /home */}
          <Route
            index
            element={
              <WithAuth>
                <OpportunityHowMuch />
              </WithAuth>
            }
          />

          {/* Other nested routes under /home */}
          <Route
            path="/home"
            element={
              <WithAuth>
                <Home />
              </WithAuth>
            }
          />

          {/* Other nested routes under /home */}
          <Route
            path="/pricing-detail"
            element={
              <WithAuth>
                <OpportunityWhy />
              </WithAuth>
            }
          />

          <Route
            path="/pvm"
            element={
              <WithAuth>
                <Pvm />
              </WithAuth>
            }
          />
          <Route
            path="/cm-bridge"
            element={
              <WithAuth>
                <CmAnalysis />
              </WithAuth>
            }
          />

          <Route
            path="/price-change"
            element={
              <WithAuth>
                <PriceChange />
              </WithAuth>
            }
          />
        {/* </Route> */}

        {/* Public Routes (without authentication) */}
        <Route
          path="/login"
          element={
            <WithoutAuth>
              <Login />
            </WithoutAuth>
          }
        />
        <Route
          path="/register"
          element={
            <WithoutAuth>
              <SignUp />
            </WithoutAuth>
          }
        />

        {/* Optionally, handle 404 Not Found */}
        {/* 
        <Route
          path="*"
          element={
            <WithoutAuth>
              <NotFound />
            </WithoutAuth>
          }
        />
        */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;



