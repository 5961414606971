
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Plotly from 'plotly.js-dist';
import './BubbleChart.css'; 
import { BASE_URL } from '../../common/baseUrl';

const CustomBubbleChart = ({ selectedYear, selectedBusiness, selectedPricingGroup, selectedCustomerSize }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [maxNetSales, setMaxNetSales] = useState(0);

  const formatCurrency = (value) => {
    return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/customer-product-data`, {
          params: {
            year: selectedYear,
            business: selectedBusiness,
            pricing_group: selectedPricingGroup,
            customer_size: selectedCustomerSize,
          },
        });
        const bubbleData = response.data;

        // Find maximum net_sales value
        const maxSales = Math.max(...bubbleData.map((item) => item.net_sales));
        setMaxNetSales(maxSales);

        // Normalize bubble sizes based on max net_sales
        const normalizedData = bubbleData.map((item) => ({
          ...item,
          bubbleSize: Math.sqrt(item.net_sales / maxSales) * 70, // Increase factor for larger bubbles
        }));

        setData(normalizedData);
      } catch (error) {
        console.error('Error fetching bubble chart data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedYear, selectedBusiness, selectedPricingGroup, selectedCustomerSize]);

  useEffect(() => {
    if (!data.length) return;

    const uniqueBusinesses = [...new Set(data.map((item) => item.business))];

    const traces = uniqueBusinesses.map((business, index) => {
      const businessData = data.filter((item) => item.business === business);
      return {
        x: businessData.map((d) => d.pricing_power_percentile),
        y: businessData.map((d) => d.pocket_margin_percentile),
        text: businessData.map(
          (d) => `Customer: ${d.customer_name}<br>Material: ${d.product_name}<br>Net Sales: ${formatCurrency(d.net_sales)}<br>Opportunity: ${formatCurrency(d.opportunity)}`
        ),
        mode: 'markers',
        name: business,
        marker: {
          size: businessData.map((d) => d.bubbleSize),
          color: index, // Using index to differentiate colors
          colorscale: 'Portland', // Use Plotly colorscale
        },
        type: 'scatter',
      };
    });

    // Calculate the max values for the diagonal line
    const maxX = Math.max(...data.map((d) => d.pricing_power_percentile));
    const maxY = Math.max(...data.map((d) => d.pocket_margin_percentile));
    const diagonalLine = {
      x: [0, maxX],
      y: [0, maxY],
      mode: 'lines',
      // name: 'Diagonal Line',
      line: {
        dash: 'dashdot',
        width: 1,
        color: 'black',
      },
    };

    const layout = {
      // title: 'Bubble Chart',
      xaxis: {
        title: 'Pricing Power Percentile',
        tickformat: '.0%',
      },
      yaxis: {
        title: 'Pocket Margin Percentile',
        tickformat: '.0%',
      },
      margin: { t: 50, r: 30, b: 80, l: 50 }, // Adjust top margin for legend
      hovermode: 'closest',
      showlegend: true, // Show legend
      legend: {
        itemclick: 'toggleothers',  // Toggle only the clicked item
        itemdoubleclick: 'toggle',  // Double-click to show/hide the clicked trace
        orientation: 'h', // Horizontal orientation
        yanchor: 'top',
        y: 1.1, // Adjust y position for legend to appear above the chart
        xanchor: 'center',
        x: 0.5, // Center the legend horizontally
      },
      // height:400,
      // width:400
    };

    Plotly.newPlot('bubbleChart', [...traces, diagonalLine], layout);

  }, [data]);

  return (
    <div className="bubble-chart">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div id="bubbleChart" style={{ width: 600, height: 430 }}></div>
      )}
    </div>
  );
};

export default CustomBubbleChart;
