const MicrosoftIcon = () => {
  return (
    <>
      <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <rect fill="#fff" height="512" rx="15%" width="512" />
        <path d="m75 75v171h171v-171z" fill="#f25022" />
        <path d="m266 75v171h171v-171z" fill="#7fba00" />
        <path d="m75 266v171h171v-171z" fill="#00a4ef" />
        <path d="m266 266v171h171v-171z" fill="#ffb900" />
      </svg>
    </>
  );
};
export default MicrosoftIcon;
