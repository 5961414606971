// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .custom-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .dropdown-search {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    margin-bottom: 5px;
  }
  
  .custom-dropdown select {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
  }
  

  .filter-cm .filter-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;
  }
 */



/* 

 .filter-cm {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .filter-cm .filter-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 10px;
    width: 100%;
    max-width: 800px; 
  }
  
  .filter-cm .filter-group {
    width: 100%; 
  }
  
  .filter-cm .react-select-container {
    width: 100%; 
  }
   */





   .filter-cm {
    display: flex;
    flex-direction: column; 
    align-items: center;
    /* padding: 10px; */
   
    
  }
  
  .filter-cm .filter-grid-cm {
    display: flex;
    /* flex-direction: column;  */
    gap: 10px;
    width: 100%;
    font-size: 8px;
    background-color: #ffffff;
    /* max-width: 250px;  */
    
  }
  
  .filter-cm .filter-group {
    width: 100%; /* Ensures that each filter takes the full width */
    
  }
  
  .filter-cm .react-select-container {
    width: 100%; /* Ensures the react-select component stretches to fill the available width */
    
  }
  `, "",{"version":3,"sources":["webpack://./src/components/cm_bridge/CmFilter/CmFilter.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;EAyBE;;;;AAIF;;;;;;;;;;;;;;;;;;;;;;;;IAwBI;;;;;;GAMD;IACC,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;;;EAGrB;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,SAAS;IACT,WAAW;IACX,cAAc;IACd,yBAAyB;IACzB,uBAAuB;;EAEzB;;EAEA;IACE,WAAW,EAAE,kDAAkD;;EAEjE;;EAEA;IACE,WAAW,EAAE,6EAA6E;;EAE5F","sourcesContent":["/* .custom-dropdown {\n    position: relative;\n    display: inline-block;\n    width: 100%;\n  }\n  \n  .dropdown-search {\n    width: 100%;\n    box-sizing: border-box;\n    padding: 5px;\n    margin-bottom: 5px;\n  }\n  \n  .custom-dropdown select {\n    width: 100%;\n    padding: 5px;\n    box-sizing: border-box;\n  }\n  \n\n  .filter-cm .filter-grid {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n    gap: 5px;\n  }\n */\n\n\n\n/* \n\n .filter-cm {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 10px;\n  }\n  \n  .filter-cm .filter-grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));\n    gap: 10px;\n    width: 100%;\n    max-width: 800px; \n  }\n  \n  .filter-cm .filter-group {\n    width: 100%; \n  }\n  \n  .filter-cm .react-select-container {\n    width: 100%; \n  }\n   */\n\n\n\n\n\n   .filter-cm {\n    display: flex;\n    flex-direction: column; \n    align-items: center;\n    /* padding: 10px; */\n   \n    \n  }\n  \n  .filter-cm .filter-grid-cm {\n    display: flex;\n    /* flex-direction: column;  */\n    gap: 10px;\n    width: 100%;\n    font-size: 8px;\n    background-color: #ffffff;\n    /* max-width: 250px;  */\n    \n  }\n  \n  .filter-cm .filter-group {\n    width: 100%; /* Ensures that each filter takes the full width */\n    \n  }\n  \n  .filter-cm .react-select-container {\n    width: 100%; /* Ensures the react-select component stretches to fill the available width */\n    \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
