/**
 * Higher-order component that checks if the user is authenticated before rendering the wrapped component.
 * If the user is not authenticated, it redirects to the login page.
 *
 * @param {WithoutAuthTypes} props - The props for the component.
 * @returns {React.ReactNode} - The wrapped component or a redirect to the login page.
 */
const WithoutAuth = (props) => {
    const token = localStorage.getItem("accessToken");
  
    if (token) {
      return (window.location.href = "/");
    } else {
      return { ...props.children };
    }
  };
  
  export default WithoutAuth;
  