// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { BASE_URL } from '../../common/baseUrl';
// import './TopBottomCustomers.css';

// const TopBottomKeyAccount = ({ selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedBusiness, selectedVertical, showTotal, showBreakdown}) => {
//   const [keyAccountImpacts, setKeyAccountImpacts] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const fetchKeyAccountImpacts = async () => {
//       try {
//         const response = await axios.get(`${BASE_URL}/api/pvm-calculations/`, {
//           params: {
//             period: selectedPeriod,
//             base_period: selectedBasePeriod,
//             comparison_period: selectedCompPeriod,
//             business: selectedBusiness,
//             ship_to_vertical: selectedVertical,
//           },
//         });
//         return response.data.pvm_results || [];
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         return [];
//       }
//     };

//     const fetchData = async () => {
//       setIsLoading(true);
//       const impacts = await fetchKeyAccountImpacts();
//       setKeyAccountImpacts(aggregateImpactsByKeyAccount(impacts));
//       setIsLoading(false);
//     };

//     if (selectedBasePeriod && selectedCompPeriod) {
//       fetchData();
//     }
//   }, [selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedBusiness, selectedVertical]);

//   // Aggregates the impacts by key_account and key_account_name
//   const aggregateImpactsByKeyAccount = (impacts) => {
//     const keyAccountImpactMap = {};

//     impacts.forEach(({ key_account, ...impactValues }) => {
//       if (!keyAccountImpactMap[key_account]) {
//         keyAccountImpactMap[key_account] = { key_account, ...impactValues };
//       } else {
//         Object.keys(impactValues).forEach((key) => {
//           keyAccountImpactMap[key_account][key] += impactValues[key];
//         });
//       }
//     });

//     return Object.values(keyAccountImpactMap);
//   };

//   const getTopBottomKeyAccounts = (impactData, impactType) => {
//     const sortedData = [...impactData].sort((a, b) => b[impactType] - a[impactType]);
//     return {
//       top3: sortedData.slice(0, 3),
//       bottom3: sortedData.slice(-3),
//     };
//   };

//   const formatCurrency = (value) => {
//     const sign = value < 0 ? '-' : '';
//     const absValue = Math.abs(value);
  
//     if (absValue >= 1000000000) {
//       const billions = absValue / 1000000000;
//       return `${sign}$${billions.toFixed(billions >= 10 ? 1 : 2)}B`;
//     } else if (absValue >= 1000000) {
//       const millions = absValue / 1000000;
//       return `${sign}$${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
//     } else if (absValue >= 10000) {
//         const millions = absValue / 10000;
//         return `${sign}$${millions.toFixed(millions >= 10 ? 1 : 2)}K`;
//       } else {
//       return `${sign}$${absValue.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
//     }
//   };

//   if (isLoading) {
//     return <p>Loading...</p>;
//   }

//   if (!Array.isArray(keyAccountImpacts) || keyAccountImpacts.length === 0) {
//     return <p>No data available</p>;
//   }

// //   const impactTypes = [
// //     'pure_price_impact',
// //     'fx_other_impact',
// //     'total_volume_mix_impact',
// //   ];

//      const impactTypes = showBreakdown
//     ? ['pure_price_impact', 'fx_other_impact', 'vol_mix_impact', 'new_volume_impact', 'lost_volume_impact']
//     : showTotal
//     ? ['price_impact', 'total_volume_mix_impact']
//     : [];

//   const toNormalCase = (str) => {
//     return str
//       .replace(/_/g, ' ')
//       .replace(/\b\w/g, (char) => char.toUpperCase());
//   };

//   const renderTable = (impactData, impactType, title) => {
//     const { top3, bottom3 } = getTopBottomKeyAccounts(impactData, impactType);

//     const getBarWidth = (value, maxValue) => {
//       return `${(Math.abs(value) / maxValue) * 50}%`;
//     };

//     // Find the maximum value for scaling bars
//     const maxValue = Math.max(...impactData.map((data) => Math.abs(data[impactType])));

//     return (
//       <div className="table-container-pvm" key={impactType}>
//         <h3>{title}</h3>
//         <table>
//           <tbody>
//             {top3.map((data, index) => (
//               <tr key={`top-${index}`}>
//                 <td>{data.key_account || 'N/A'}</td>
//                 <td className="impact-cell">
//                   <div className="impact-bar-container">
//                     <div
//                       className={`impact-bar ${data[impactType] > 0 ? 'positive' : 'negative'}`}
//                       style={{ width: getBarWidth(data[impactType], maxValue) }}
//                     ></div>
//                   </div>
//                   <div className="impact-value">{formatCurrency(data[impactType])}</div>
//                 </td>
//               </tr>
//             ))}
//             {bottom3.map((data, index) => (
//               <tr key={`bottom-${index}`}>
//                 <td>{data.key_account || 'N/A'}</td>
//                 <td className="impact-cell">
//                   <div className="impact-bar-container">
//                     <div
//                       className={`impact-bar ${data[impactType] > 0 ? 'positive' : 'negative'}`}
//                       style={{ width: getBarWidth(data[impactType], maxValue) }}
//                     ></div>
//                   </div>
//                   <div className="impact-value">{formatCurrency(data[impactType])}</div>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     );
//   };

//   return (
//     <div className="top-bottom-container">
//         <p className='top-bottom-title'>Top and bottom 3 key account by PVM Impact  {selectedBasePeriod} vs {selectedCompPeriod} comparison</p>
//       {impactTypes.map((impactType) => (
//         <div key={impactType}>
//           {renderTable(keyAccountImpacts, impactType, toNormalCase(impactType))}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default TopBottomKeyAccount;









import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../common/baseUrl';
import './TopBottomCustomers.css';

const TopBottomKeyAccount = ({ selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedBusiness, selectedVertical, showTotal, showBreakdown}) => {
  const [keyAccountImpacts, setKeyAccountImpacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchKeyAccountImpacts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/pvm-calculations`, {
          params: {
            period: selectedPeriod,
            base_period: selectedBasePeriod,
            comparison_period: selectedCompPeriod,
            business: selectedBusiness,
            ship_to_vertical: selectedVertical,
          },
        });
        return response.data.pvm_results || [];
      } catch (error) {
        console.error('Error fetching data:', error);
        return [];
      }
    };

    const fetchData = async () => {
      setIsLoading(true);
      const impacts = await fetchKeyAccountImpacts();
      setKeyAccountImpacts(aggregateImpactsByKeyAccount(impacts));
      setIsLoading(false);
    };

    if (selectedBasePeriod && selectedCompPeriod) {
      fetchData();
    }
  }, [selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedBusiness, selectedVertical]);

  // Aggregates the impacts by key_account and key_account_name
  const aggregateImpactsByKeyAccount = (impacts) => {
    const keyAccountImpactMap = {};

    impacts.forEach(({ key_account, ...impactValues }) => {
      if (!keyAccountImpactMap[key_account]) {
        keyAccountImpactMap[key_account] = { key_account, ...impactValues };
      } else {
        Object.keys(impactValues).forEach((key) => {
          keyAccountImpactMap[key_account][key] += impactValues[key];
        });
      }
    });

    return Object.values(keyAccountImpactMap);
  };

  const getTopBottomKeyAccounts = (impactData, impactType) => {
    const sortedData = [...impactData].sort((a, b) => b[impactType] - a[impactType]);
    return {
      top3: sortedData.slice(0, 3),
      bottom3: sortedData.slice(-3),
    };
  };

  const formatCurrency = (value) => {
    const sign = value < 0 ? '-' : '';
    const absValue = Math.abs(value);
  
    if (absValue >= 1000000000) {
      const billions = absValue / 1000000000;
      return `${sign}$${billions.toFixed(billions >= 10 ? 1 : 2)}B`;
    } else if (absValue >= 1000000) {
      const millions = absValue / 1000000;
      return `${sign}$${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
    } else if (absValue >= 10000) {
        const millions = absValue / 10000;
        return `${sign}$${millions.toFixed(millions >= 10 ? 1 : 2)}K`;
      } else {
      return `${sign}$${absValue.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!Array.isArray(keyAccountImpacts) || keyAccountImpacts.length === 0) {
    return <p>No data available</p>;
  }

  // const impactTypes = [
  //   'pure_price_impact',
  //   'fx_other_impact',
  //   'total_volume_mix_impact',
  // ];

     const impactTypes = showBreakdown
    ? ['pure_price_impact', 'fx_other_impact', 'Volume_Impact', 'new_vol_impact', 'lost_vol_impact']
    : showTotal
    ? ['total_price_impact', 'total_vol_impact', 'Mix_Impact']
    : [];

  const toNormalCase = (str) => {
    return str
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const renderTable = (impactData, impactType, title) => {
    const { top3, bottom3 } = getTopBottomKeyAccounts(impactData, impactType);

    const getBarWidth = (value, maxValue) => {
      return `${(Math.abs(value) / maxValue) * 50}%`;
    };

    // Find the maximum value for scaling bars
    const maxValue = Math.max(...impactData.map((data) => Math.abs(data[impactType])));

    return (
      <div className="table-container-pvm" key={impactType}>
        <h3>{title}</h3>
        <table>
          <tbody>
            {top3.map((data, index) => (
              <tr key={`top-${index}`}>
                <td>{data.key_account || 'N/A'}</td>
                <td className="impact-cell">
                  <div className="impact-bar-container">
                    <div
                      className={`impact-bar ${data[impactType] > 0 ? 'positive' : 'negative'}`}
                      style={{ width: getBarWidth(data[impactType], maxValue) }}
                    ></div>
                  </div>
                  <div className="impact-value">{formatCurrency(data[impactType])}</div>
                </td>
              </tr>
            ))}
            {bottom3.map((data, index) => (
              <tr key={`bottom-${index}`}>
                <td>{data.key_account || 'N/A'}</td>
                <td className="impact-cell">
                  <div className="impact-bar-container">
                    <div
                      className={`impact-bar ${data[impactType] > 0 ? 'positive' : 'negative'}`}
                      style={{ width: getBarWidth(data[impactType], maxValue) }}
                    ></div>
                  </div>
                  <div className="impact-value">{formatCurrency(data[impactType])}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="top-bottom-container">
        <p className='top-bottom-title'>Top and bottom 3 key account by PVM Impact  {selectedBasePeriod} vs {selectedCompPeriod} comparison</p>
      {impactTypes.map((impactType) => (
        <div key={impactType}>
          {renderTable(keyAccountImpacts, impactType, toNormalCase(impactType))}
        </div>
      ))}
    </div>
  );
};

export default TopBottomKeyAccount;
