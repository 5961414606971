import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PriceTotals.css';
import { BASE_URL } from '../../common/baseUrl';

const PriceTotals = ({
  selectedYear,
  selectedBusiness,
  selectedPricingGroup,
  selectedVertical,
  selectedCustomer,
  selectedMaterial,
  selectedKeyaccount,
  selectedProductLine,
}) => {
  const [summaryData, setSummaryData] = useState({
    selected_yr_total_net_sales: 0,
    selected_yr_customer_sales: 0,
    selected_yr_total_cm: 0,
    selected_yr_total_volume: 0,
    prev_yr_total_net_sales: 0,
    prev_yr_customer_sales: 0,
    prev_yr_total_cm: 0,
    prev_yr_total_volume: 0,
    avgPrice: 0,
    prevAvgPrice: 0,
    percent_change: 0,
    avgPriceChangePercent: 0, // Set a default value here
  });

  useEffect(() => {
    fetchSummaryData();
  }, [
    selectedYear,
    selectedBusiness,
    selectedPricingGroup,
    selectedVertical,
    selectedCustomer,
    selectedMaterial,
    selectedKeyaccount,
    selectedProductLine,
  ]);

  const fetchSummaryData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/price-category`, {
        params: {
          year: selectedYear || undefined,
          business: selectedBusiness || undefined,
          pricing_group: selectedPricingGroup || undefined,
          vertical: selectedVertical || undefined,
          customer: selectedCustomer || undefined,
          material: selectedMaterial || undefined,
          keyaccount: selectedKeyaccount || undefined,
          productline: selectedProductLine || undefined,
        },
      });

      const data = response.data.yearly_totals;
      const yearly_data = response.data.yearly_summary;

      const avgPrice =
        data.selected_yr_customer_sales && data.selected_yr_total_volume
          ? data.selected_yr_customer_sales / data.selected_yr_total_volume
          : 0;

      const prevAvgPrice =
        data.prev_yr_customer_sales && data.prev_yr_total_volume
          ? data.prev_yr_customer_sales / data.prev_yr_total_volume
          : 0;

      const percent_change =
        prevAvgPrice !== 0
          ? ((avgPrice - prevAvgPrice) / prevAvgPrice) * 100
          : 0;

      const priceChangePercentValues = yearly_data.map(
        (item) => item.price_change_percent || 0
      );
      const avgPriceChangePercent =
        priceChangePercentValues.length > 0
          ? priceChangePercentValues.reduce((acc, value) => acc + value, 0) /
            priceChangePercentValues.length
          : 0;

      setSummaryData({
        selected_yr_total_net_sales: data.selected_yr_total_net_sales || 0,
        selected_yr_customer_sales: data.selected_yr_customer_sales || 0,
        selected_yr_total_cm: data.selected_yr_total_cm || 0,
        selected_yr_total_volume: data.selected_yr_total_volume || 0,
        prev_yr_total_net_sales: data.prev_yr_total_net_sales || 0,
        prev_yr_customer_sales: data.prev_yr_customer_sales || 0,
        prev_yr_total_cm: data.prev_yr_total_cm || 0,
        prev_yr_total_volume: data.prev_yr_total_volume || 0,
        avgPrice,
        prevAvgPrice,
        percent_change,
        avgPriceChangePercent, // Ensure it's included here
      });
    } catch (error) {
      console.error('Error fetching summary data:', error);
    }
  };

  const formatCurrency = (value) => {
    if (value >= 1000000000) {
      const billions = value / 1000000000;
      return `$${billions.toFixed(billions >= 10 ? 1 : 2)}B`;
    } else if (value >= 1000000) {
      const millions = value / 1000000;
      return `$${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
    } else {
      return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })}`;
    }
  };

  const formatPercentage = (value) => {
    return `${((value || 0)*100).toFixed(2)}%`; // Default to 0 if value is undefined
  };

  const formatNumber = (value) => {
    if (value > 1000000) {
      const millions = value / 1000000;
      return `${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
    }
    return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 });
  };

  return (
    <div className="summary-stats-price-category">
      <div className="stats-grid-price-category">
      <div className="stat-item-price-category">
          <span className="stat-value-price-category">{formatCurrency(summaryData.selected_yr_customer_sales)}</span>
          <span className="stat-label-price-category">Customer Sales</span>
        </div>

        <div className="stat-item-price-category">
          <span className="stat-value-price-category">{formatCurrency(summaryData.selected_yr_total_net_sales)}</span>
          <span className="stat-label-price-category">Net Sales</span>
        </div>

        <div className="stat-item-price-category">
          <span className="stat-value-price-category">{formatNumber(summaryData.selected_yr_total_volume)}</span>
          <span className="stat-label-price-category">Volume</span>
        </div>

        <div className="stat-item-price-category">
          <span className="stat-value-price-category">{formatCurrency(summaryData.selected_yr_total_cm)}</span>
          <span className="stat-label-price-category">Contribution Margin</span>
        </div>
        
        <div className="stat-item-price-category">
          <span className="stat-value-price-category">{formatPercentage(summaryData.avgPriceChangePercent)}</span>
          <span className="stat-label-price-category">Price Change Percent</span>
        </div>
      </div>
    </div>
  );
};

export default PriceTotals;
