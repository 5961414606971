// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
body {
  margin: 0;
  /* font-family: Arial, sans-serif; */
  /* font-family: 'DM Sans', sans-serif;
  font-weight: 400; */
  
}

/* .App {
  display: flex;
  flex-direction: column;
  align-items: center;
} */


.content {
  margin-top: 20px; 
  width: 100%;
  padding: 10px; 
  box-sizing: border-box; 
}







/* Additional styles for summary, filter, table, and chart sections */




/* .chart-container {
  width: 100%;
  overflow-x: auto;
}

.chart-wrapper {
  width: 2500px; 
}

.chart-section {
  margin-bottom: 20px;
} */




/* App.css */




`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,SAAS;EACT,oCAAoC;EACpC;qBACmB;;AAErB;;AAEA;;;;GAIG;;;AAGH;EACE,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;;;;;;;AAQA,qEAAqE;;;;;AAKrE;;;;;;;;;;;GAWG;;;;;AAKH,YAAY","sourcesContent":["/* App.css */\nbody {\n  margin: 0;\n  /* font-family: Arial, sans-serif; */\n  /* font-family: 'DM Sans', sans-serif;\n  font-weight: 400; */\n  \n}\n\n/* .App {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n} */\n\n\n.content {\n  margin-top: 20px; \n  width: 100%;\n  padding: 10px; \n  box-sizing: border-box; \n}\n\n\n\n\n\n\n\n/* Additional styles for summary, filter, table, and chart sections */\n\n\n\n\n/* .chart-container {\n  width: 100%;\n  overflow-x: auto;\n}\n\n.chart-wrapper {\n  width: 2500px; \n}\n\n.chart-section {\n  margin-bottom: 20px;\n} */\n\n\n\n\n/* App.css */\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
