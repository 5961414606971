
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import './CustomerBar.css';
import { BASE_URL } from '../../common/baseUrl';

const CustomerBar = ({ selectedYear, selectedBusiness, selectedPricingGroup, selectedSalesManager, selectedCustomer, selectedVertical }) => {
  const [chartData, setChartData] = useState({
    customer: { labels: [], datasets: [] },
    salesManager: { labels: [], datasets: [] },
    shipToVertical: { labels: [], datasets: [] }
  });
  const [isLoading, setIsLoading] = useState(false);
  const [textLine, setTextLine] = useState('');
  const [filteredSalesManager, setFilteredSalesManager] = useState(selectedSalesManager);
  const [filteredCustomer, setFilteredCustomer] = useState(selectedCustomer);
  const [filteredVertical, setFilteredVertical] = useState(selectedVertical);

  // const formatCurrency = (value) => {
  //   return `$${Number(value).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
  // };


  const formatCurrency = (value) => {
     
    if (value >= 1000000000) {
      const billions = value / 1000000000;
      return `$${billions.toFixed(billions >= 10 ? 1 : 2)}B`;
    }

    else if (value >= 1000000) {
      // For values greater than or equal to 1,000,000, format as $X.XM
      const millions = value / 1000000;
      return `$${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
    } 
    else {
      // For values less than 1,000,000, format with commas
      return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    }
  };

  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/pricingdata-summary`, {
          params: { year: selectedYear, business: selectedBusiness, pricing_group: selectedPricingGroup, 
            sales_manager: filteredSalesManager, customer: filteredCustomer , vertical: filteredVertical }
        });
        const data = response.data;

        if (data) {
          setChartData({
            customer: processChartData(data.customer, 'key', 'cumulative_percent', true),
            salesManager: processChartData(data.salesManager, 'sales_manager', 'total_opportunity', false),
            shipToVertical: processChartData(data.shipToVertical, 'ship_to_vertical', 'total_opportunity', false),
          });

          // Update the text line based on the response data
          const top80Opportunity = (0.8 * data.total_opportunity).toFixed(1);
          const remaining20Opportunity = (0.2 * data.total_opportunity).toFixed(1);
          setTextLine(`${data.top_80_customers_count} customers contribute to 80% ${formatCurrency(top80Opportunity)} of the opportunity. The remaining 20% ${formatCurrency(remaining20Opportunity)} of opportunity is from ${data.remaining_20_customers_count} customers.`);
        }
      } catch (error) {
        console.error('Error fetching chart data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedYear, selectedBusiness, selectedPricingGroup, filteredSalesManager, filteredCustomer, filteredVertical]);


  const processChartData = (data, key, sortByField, sortAscending) => {
    if (!data || data.length === 0) {
      return { labels: [], datasets: [] };
    }

    data.sort((a, b) => sortAscending ? a[sortByField] - b[sortByField] : b[sortByField] - a[sortByField]);

    // const labels = data.map(item => truncateLabel(item[key]));
    const labels = data.map(item => item[key]);
    const opportunityData = data.map(item => parseFloat(item.total_opportunity));
    const cumulativeBusinessOppData = data.map(item => parseFloat(item.cumulative_percent));

    return {
      labels,
      datasets: [
        {
          type: 'bar',
          label: 'Opportunity',
          data: opportunityData,
          backgroundColor: 'rgba(54, 162, 235, 0.8)', // Darker blue color
        },
        ...(sortAscending ? [{
          type: 'line',
          label: 'Cumulative Opportunity %',
          data: cumulativeBusinessOppData,
          borderColor: 'rgba(255, 99, 132, 0.8)',
          fill: false,
          mode: 'lines+markers',
          marker: {
            color: 'rgba(255, 99, 132, 0.8)',
            size: 6, // Adjust the size of the dots
          },
          yAxisID: 'y-axis-2',
        }] : []),
      ],
    };
  };

  const handleSalesManagerClick = (event) => {
    const salesManager = event.points[0].x;
    setFilteredSalesManager(prev => prev === salesManager ? '' : salesManager);
  };

  const handleCustomerClick = (event) => {
    const Customer = event.points[0].x;
    setFilteredCustomer(prev => prev === Customer ? '' : Customer);
  };

  const handleVerticalClick = (event) => {
    const Vertical = event.points[0].x;
    setFilteredVertical(prev => prev === Vertical ? '' : Vertical);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  

  return (
    <div className="charts-container">
      <div className="chart-section">
        <div className="chart-text-line">
          {/* <div className='chart-title'>
          <strong>Where: Which customers? How much is in the tail vs. large customers?</strong>
          </div> */}
          <p className='text-line'>{textLine}</p>
        </div>
        <Plot
          data={[
            {
              x: chartData.customer.labels,
              y: chartData.customer.datasets[0]?.data,
              type: 'bar',
              name: 'Opportunity',
              marker: { color: 'rgba(54, 162, 235, 0.8)' },
              // text: chartData.salesManager.datasets[0]?.data.map(
              //   (value) => `${(value / 1_000_000).toFixed(1)}M` // Convert values to millions and format
              // ),
              // textposition: 'outside', // Position the text automatically
              // textfont: {
              //   size: 9, // Font size of the text on bars
              //   color: '#000', // Font color
              // },
              
            },
            ...(chartData.customer.datasets.length > 1 ? [{
              x: chartData.customer.labels,
              y: chartData.customer.datasets[1]?.data,
              type: 'scatter',
              mode: 'lines+markers',
              name: 'Cumulative Opportunity %',
              line: { color: 'rgba(255, 99, 132, 0.8)' },
              marker: {
                color: 'rgba(255, 99, 132, 0.8)',
                size: 6, // Adjust the size of the dots
              },
              yaxis: 'y2'
            }] : []),
          ]}
          layout={{
            title: {
              text: 'Where: Which customers? How much is in the tail vs. large customers',
              x: 0,
              y: 0.9,
              font: {
                size: 12, 
                family: 'Arial, sans-serif',
                color: '#333',
                // weight: 'bold',
              }
            },
            xaxis: {
              // title: 'Customer',
              tickfont: {
                size: 9, // Adjust the font size here
                family: 'Arial, sans-serif',
                color: '#333',
              },
              tickmode: 'auto',
              tickangle: 45,
              range: [-1, 40], // Show the first 30 bars initially
              // rangeslider: {
              //   visible: true,
              //   thickness: 0.02, // Adjust the thickness if needed
              //   yaxis: {

              //     fixedrange: true,
              //     automargin: false,
              //     rangemode: 'fixed', // Automatically adjust margin
              //   },
              // },
              // rangeslider: { visible: true }
            },
            yaxis: { 
              title: {
                text: 'Opportunity',
                font: {
                  size: 9, 
                  family: 'Arial, sans-serif',
                  color: '#333',
                }
              }, 
              tickfont: {
                size: 9, // Decrease the size of y-axis tick labels
                // family: 'Arial, sans-serif',
                color: '#333',
              },
             },
            ...(chartData.customer.datasets.length > 1 ? {
              yaxis2: {
                // title: 'Cumulative Opportunity %',
                overlaying: 'y',
                side: 'right',
              }
            } : {}),
            legend: {
              x: 0.4, // Position the legend on the right
              y: 1.9, // Position the legend at the top
              xanchor: 'left', // Anchor the legend from the left
              yanchor: 'top', // Anchor the legend from the top
              orientation: 'h', // Vertical orientation
              font: {
                size: 10, // Adjust the font size here
                family: 'Arial, sans-serif',
                color: '#333',
              }
            },
            height: 160,
            width: 880,
            margin: {
              t: 30, 
              b: 40, 
              l: 50, 
              r: 50, 
            },
            clickmode: 'event+select'
          }}
          onClick={handleCustomerClick}
          // config={{ displayModeBar: true }}
        />
      </div>
      <div className="chart-section">
        {/* <div className='chart-title'>
        <strong>Who: Which sales manager have a biggest opportunity?</strong>
        </div> */}
        <Plot
          data={[
            {
              x: chartData.salesManager.labels,
              y: chartData.salesManager.datasets[0]?.data,
              type: 'bar',
              name: 'Opportunity',
              marker: { color: 'rgba(54, 162, 235, 0.8)' },
              // text: chartData.salesManager.datasets[0]?.data.map(
              //   (value) => `${(value / 1_000_000).toFixed(1)}M` // Convert values to millions and format
              // ),
              // textposition: 'outside', // Position the text automatically
              // textfont: {
              //   size: 9, // Font size of the text on bars
              //   color: '#000', // Font color
              // },
            },
          ]}
          layout={{
            title: {
              text: 'Who: Which sales manager have a biggest opportunity?',
              x: 0,
              y: 0.9,
              font: {
                size: 12, // Adjust the font size here
                family: 'Arial, sans-serif',
                color: '#333',
              }
            },
            xaxis: {
              // title: 'Sales Manager',
              // range: [0, 29], // Show the first 30 bars initially
              // rangeslider: { visible: true },
              tickfont: {
                size: 9, // Adjust the font size here
                family: 'Arial, sans-serif',
                color: '#333',
              },
              tickangle: 45,
              range: [-1, 40],
            },
            yaxis: { 
              title: {
                text: 'Opportunity',
                font: {
                  size: 9, 
                  family: 'Arial, sans-serif',
                  color: '#333',
                }
              }, 
              tickfont: {
                size: 9, 
                color: '#333',
              },
              automargin: true,
             },
            
            height: 160,
            width: 880,
            margin: {
              t: 50, // Reduce the top margin
              b: 40, // Bottom margin
              l: 50, // Left margin
              r: 50, // Right margin
            },
            clickmode: 'event+select'
          }}
          onClick={handleSalesManagerClick}
        />
      </div>
      <div className="chart-section">
        {/* <div className='chart-title'>
        <strong>Where: Which customers verticals?</strong>
        </div> */}
        <Plot
          data={[
            {
              x: chartData.shipToVertical.labels,
              y: chartData.shipToVertical.datasets[0]?.data,
              type: 'bar',
              name: 'Opportunity',
              marker: { color: 'rgba(54, 162, 235, 0.8)' },
            },
          ]}
          layout={{
            title: {
              text: 'Where: Which customers verticals?',
              x: 0,
              y: 0.9,
              font: {
                size: 12, // Adjust the font size here
                family: 'Arial, sans-serif',
                color: '#333',
              }
            },
            xaxis: {
              // title: 'Vertical',
              // range: [0, 29], // Show the first 30 bars initially
              // rangeslider: { visible: true },
              tickfont: {
                size: 9, // Adjust the font size here
                family: 'Arial, sans-serif',
                color: '#333',
              },
              tickangle: 45,
            },
            yaxis: { 
              title: {
                text: 'Opportunity',
                font: {
                  size: 9, 
                  family: 'Arial, sans-serif',
                  color: '#333',
                }
              }, 
              tickfont: {
                size: 9, 
                color: '#333',
              },
             },
            height: 160,
            width: 880,
            margin: {
              t: 30, // Reduce the top margin
              b: 40, // Bottom margin
              l: 50, // Left margin
              r: 50, // Right margin
            },
            clickmode: 'event+select'
          }}
          onClick={handleVerticalClick}
          // config={{ displayModeBar: true }}
        />
      </div>
    </div>
  );
};

export default CustomerBar;
