// import React, { useState, useEffect } from 'react';
// import '../App.css';
// import '../components/static/Home.css';
// import axios from 'axios';
// import PricingDataTable from '../components/opportunity_how_much/PricingDataTable.jsx';
// import CustomerBar from '../components/opportunity_how_much/CustomerBar.jsx';
// import Navbar from '../components/common/Navbar.jsx';
// import Filter from '../components/common/Filter.jsx';
// import SummaryStats from '../components/common/SummaryStats.jsx';
// import { BASE_URL } from '../components/common/baseUrl.js';


// function Home() {
//     const [selectedYear, setSelectedYear] = useState('2023');
//     const [selectedBusiness, setSelectedBusiness] = useState('');
//     const [selectedPricingGroup, setSelectedPricingGroup] = useState('');
//     const [selectedCustomerSize, setSelectedCustomerSize] = useState('');
//     const [selectedSalesManager, setSelectedSalesManager] = useState('');
//     const [selectedItem, setSelectedItem] = useState(null);
//     const [years, setYears] = useState([]);
//     const [businesses, setBusinesses] = useState([]);
//     const [pricingGroups, setPricingGroups] = useState([]);
//     const [customerSizes, setCustomerSizes] = useState([]);
//     const [salesManagers, setSalesManager] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);

//     useEffect(() => {
//         fetchFilters();
//     }, []);

//     const fetchFilters = async () => {
//         try {
//             const response = await axios.get(`${BASE_URL}/api/pricingdata/`);
//             const data = response.data;

            
//             // const uniqueYears = [''].concat([...new Set(data.map(item => item.year))]);
//             // const uniqueBusinesses = [''].concat([...new Set(data.map(item => item.business))]);
//             const uniqueYears = [2020, 2021, 2022, 2023];
//             const uniqueBusinesses = ['Commodity Adhesives', "Speciality Sealants", "Speciality Adhesives"];
//             const uniquePricingGroups = [''].concat([...new Set(data.map(item => item.pricing_group))]);
//             const uniqueCustomerSizes = [''].concat([...new Set(data.map(item => item.customer_size))]);
//             const uniqueSalesManager = [''].concat([...new Set(data.map(item => item.sales_manager))]);

//             setYears(uniqueYears);
//             setBusinesses(uniqueBusinesses);
//             setPricingGroups(uniquePricingGroups);
//             setCustomerSizes(uniqueCustomerSizes);
//             setSalesManager(uniqueSalesManager);
//         } catch (error) {
//             console.error('Error fetching filter data:', error);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const onItemClick = (item) => {
//         setSelectedItem(item);
//     };

//     const handlePricingGroupClick = (pricingGroup) => {
//         if (selectedPricingGroup === pricingGroup) {
//             setSelectedPricingGroup('');
//         } else {
//             setSelectedPricingGroup(pricingGroup);
//         }
//     };

//     const handleSalesManagerClick = (salesManager) => {
//         if (selectedSalesManager === salesManager) {
//             setSelectedSalesManager('');
//         } else {
//             setSelectedSalesManager(salesManager);
//         }
//     };

    

//     return (
//         <div className="Home">
//             <Navbar />
//             <div className="main-content">
//                 <div className="header-container">
//                     <div className="summary-container">
//                         <SummaryStats
//                             selectedYear={selectedYear}
//                             selectedBusiness={selectedBusiness}
//                             selectedPricingGroup={selectedPricingGroup}
//                             selectedItem={selectedItem}
//                             selectedSalesManager={selectedSalesManager}
//                         />
//                     </div>
//                     <div className="filter-container">
//                         <Filter
//                             years={years}
//                             businesses={businesses}
//                             pricingGroups={pricingGroups}
//                             customerSizes={customerSizes}
//                             salesManagers = {salesManagers}
//                             selectedYear={selectedYear}
//                             selectedBusiness={selectedBusiness}
//                             selectedPricingGroup={selectedPricingGroup}
//                             selectedCustomerSize={selectedCustomerSize}
//                             selectedSalesManager = {setSelectedSalesManager}
//                             onYearChange={setSelectedYear}
//                             onBusinessChange={setSelectedBusiness}
//                             onPricingGroupChange={setSelectedPricingGroup}
//                             onCustomerSizeChange={setSelectedCustomerSize}
//                             onSalesManagerChange={setSelectedSalesManager}
//                             showCustomerSizeFilter={false}
//                         />
//                     </div>
//                 </div>
//                 <div className="container">
//                     <div className="table-container">
//                         {isLoading ? (
//                             <p>Loading...</p>
//                         ) : (
//                             <PricingDataTable
//                                 selectedYear={selectedYear}
//                                 selectedBusiness={selectedBusiness}
//                                 selectedPricingGroup={selectedPricingGroup}
//                                 onItemClick={onItemClick}
//                                 onPricingGroupClick={handlePricingGroupClick}
//                             />
//                         )}
//                     </div>
//                     <div className="charts-container">
//                         {isLoading ? (
//                             <p>Loading...</p>
//                         ) : (
//                             <CustomerBar
//                                 selectedYear={selectedYear}
//                                 selectedBusiness={selectedBusiness}
//                                 selectedPricingGroup={selectedPricingGroup}
//                                 selectedItem={selectedItem}
//                                 onItemClick={onItemClick}
//                                 onSalesManagerClick={handleSalesManagerClick}
//                             />
//                         )}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Home;





import React, { useState, useEffect } from 'react';
// import '../App.css';
import './Home.css';
import axios from 'axios';
import PricingDataTable from '../../components/opportunity_how_much/Table/PricingDataTable.jsx';
import CustomerBar from '../../components/opportunity_how_much/BarCharts/CustomerBar.jsx';
import Navbar from '../../components/common/Navbar/Navbar.jsx';
import Filter from '../../components/common/Filter/Filter.jsx';
import SummaryStats from '../../components/common/Totals/SummaryStats.jsx';
import { BASE_URL } from '../../components/common/baseUrl.js';

function OpportunityHowMuch() {
    const [selectedYear, setSelectedYear] = useState('2023');
    const [selectedBusiness, setSelectedBusiness] = useState('');
    const [selectedPricingGroup, setSelectedPricingGroup] = useState('');
    const [selectedCustomerSize, setSelectedCustomerSize] = useState('');
    const [selectedSalesManager, setSelectedSalesManager] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [years, setYears] = useState([]);
    const [businesses, setBusinesses] = useState([]);
    const [pricingGroups, setPricingGroups] = useState([]);
    const [customerSizes, setCustomerSizes] = useState([]);
    const [salesManagers, setSalesManager] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchFilters();
    }, []);

    const fetchFilters = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/pricingdata`);
            const data = response.data;

            // Static unique values for years and businesses
            const uniqueYears = [2020, 2021, 2022, 2023];
            const uniqueBusinesses = ['Commodity Adhesives', "Speciality Sealants", "Speciality Adhesives"];
            const uniquePricingGroups = [''].concat([...new Set(data.map(item => item.pricing_group))]);
            const uniqueCustomerSizes = [''].concat([...new Set(data.map(item => item.customer_size))]);
            const uniqueSalesManager = [''].concat([...new Set(data.map(item => item.sales_manager))]);

            setYears(uniqueYears);
            setBusinesses(uniqueBusinesses);
            setPricingGroups(uniquePricingGroups);
            setCustomerSizes(uniqueCustomerSizes);
            setSalesManager(uniqueSalesManager);
        } catch (error) {
            console.error('Error fetching filter data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const onItemClick = (item) => {
        setSelectedItem(item);
    };

    const handlePricingGroupClick = (pricingGroup) => {
        setSelectedPricingGroup(selectedPricingGroup === pricingGroup ? '' : pricingGroup);
    };

    const handleSalesManagerClick = (salesManager) => {
        setSelectedSalesManager(selectedSalesManager === salesManager ? '' : salesManager);
    };

    return (
        <div className="Home">
            <Navbar />
            <div className="main-content-howmuch">
                <div className="header-container-howmuch">
                    <div className='title-filter'>
                        <div className='page-title-home'>
                            <p>Price Opportunity How Much?</p>
                        </div>
                        <div className="filter-container-howmuch">
                            <Filter
                                years={years}
                                businesses={businesses}
                                pricingGroups={pricingGroups}
                                customerSizes={customerSizes}
                                salesManagers={salesManagers}
                                selectedYear={selectedYear}
                                selectedBusiness={selectedBusiness}
                                selectedPricingGroup={selectedPricingGroup}
                                selectedCustomerSize={selectedCustomerSize}
                                selectedSalesManager={selectedSalesManager}
                                onYearChange={setSelectedYear}
                                onBusinessChange={setSelectedBusiness}
                                onPricingGroupChange={setSelectedPricingGroup}
                                onCustomerSizeChange={setSelectedCustomerSize}
                                onSalesManagerChange={setSelectedSalesManager}
                                showCustomerSizeFilter={false}
                                showSalesManagerFilter={false}
                            />
                        </div>
                    </div>
                    <div className="summary-container-howmuch">
                        <SummaryStats
                            selectedYear={selectedYear}
                            selectedBusiness={selectedBusiness}
                            selectedPricingGroup={selectedPricingGroup}
                            selectedItem={selectedItem}
                            selectedSalesManager={selectedSalesManager}
                        />
                    </div>
                    {/* <div className="filter-container">
                        <Filter
                            years={years}
                            businesses={businesses}
                            pricingGroups={pricingGroups}
                            customerSizes={customerSizes}
                            salesManagers={salesManagers}
                            selectedYear={selectedYear}
                            selectedBusiness={selectedBusiness}
                            selectedPricingGroup={selectedPricingGroup}
                            selectedCustomerSize={selectedCustomerSize}
                            selectedSalesManager={selectedSalesManager}
                            onYearChange={setSelectedYear}
                            onBusinessChange={setSelectedBusiness}
                            onPricingGroupChange={setSelectedPricingGroup}
                            onCustomerSizeChange={setSelectedCustomerSize}
                            onSalesManagerChange={setSelectedSalesManager}
                            showCustomerSizeFilter={false}
                            showSalesManagerFilter={false}
                        />
                    </div> */}
                </div>
                <div className="container-howmuch">
                    <div className="table-container-howmuch">
                        {isLoading ? (
                            <p>Loading...</p>
                        ) : (
                            <PricingDataTable
                                selectedYear={selectedYear}
                                selectedBusiness={selectedBusiness}
                                selectedPricingGroup={selectedPricingGroup}
                                onItemClick={onItemClick}
                                onPricingGroupClick={handlePricingGroupClick}
                            />
                        )}
                    </div>
                    <div className="charts-container-howmuch">
                        {isLoading ? (
                            <p>Loading...</p>
                        ) : (
                            <CustomerBar
                                selectedYear={selectedYear}
                                selectedBusiness={selectedBusiness}
                                selectedPricingGroup={selectedPricingGroup}
                                selectedItem={selectedItem}
                                onItemClick={onItemClick}
                                onSalesManagerClick={handleSalesManagerClick}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OpportunityHowMuch;







// import React, { useState, useEffect } from 'react';
// import './Home.css';
// import axios from 'axios';
// import PricingDataTable from '../../components/opportunity_how_much/Table/PricingDataTable.jsx';
// import CustomerBar from '../../components/opportunity_how_much/BarCharts/CustomerBar.jsx';
// import Navbar from '../../components/common/Navbar/Navbar.jsx';
// import Filter from '../../components/common/Filter/Filter.jsx';
// import SummaryStats from '../../components/common/Totals/SummaryStats.jsx';
// import { BASE_URL } from '../../components/common/baseUrl.js';

// function OpportunityHowMuch() {
//     const [selectedYear, setSelectedYear] = useState('2023');
//     const [selectedBusiness, setSelectedBusiness] = useState('');
//     const [selectedPricingGroup, setSelectedPricingGroup] = useState('');
//     const [selectedCustomerSize, setSelectedCustomerSize] = useState('');
//     const [selectedSalesManager, setSelectedSalesManager] = useState('');
//     const [selectedItem, setSelectedItem] = useState(null);
//     const [years, setYears] = useState([]);
//     const [businesses, setBusinesses] = useState([]);
//     const [pricingGroups, setPricingGroups] = useState([]);
//     const [customerSizes, setCustomerSizes] = useState([]);
//     const [salesManagers, setSalesManager] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);

//     useEffect(() => {
//         fetchFilters();
//     }, []);

//     // Function to fetch filters from API or localStorage
//     const fetchFilters = async () => {
//         try {
//             const cachedFilters = JSON.parse(localStorage.getItem('filterData'));
//             const cacheExpiration = localStorage.getItem('cacheExpiration');
            
//             // Check if cached data exists and is still valid
//             const isCacheValid = cacheExpiration && new Date().getTime() < cacheExpiration;

//             if (cachedFilters && isCacheValid) {
//                 // Use cached data
//                 setYears(cachedFilters.years);
//                 setBusinesses(cachedFilters.businesses);
//                 setPricingGroups(cachedFilters.pricingGroups);
//                 setCustomerSizes(cachedFilters.customerSizes);
//                 setSalesManager(cachedFilters.salesManagers);
//             } else {
//                 // Fetch new data from the API
//                 const response = await axios.get(`${BASE_URL}/pricingdata/`);
//                 const data = response.data;

//                 // Unique filter values
//                 const uniqueYears = [2020, 2021, 2022, 2023];
//                 const uniqueBusinesses = ['Commodity Adhesives', "Speciality Sealants", "Speciality Adhesives"];
//                 const uniquePricingGroups = [''].concat([...new Set(data.map(item => item.pricing_group))]);
//                 const uniqueCustomerSizes = [''].concat([...new Set(data.map(item => item.customer_size))]);
//                 const uniqueSalesManager = [''].concat([...new Set(data.map(item => item.sales_manager))]);

//                 // Update state
//                 setYears(uniqueYears);
//                 setBusinesses(uniqueBusinesses);
//                 setPricingGroups(uniquePricingGroups);
//                 setCustomerSizes(uniqueCustomerSizes);
//                 setSalesManager(uniqueSalesManager);

//                 // Cache the data with an expiration time (e.g., 10 minutes)
//                 localStorage.setItem('filterData', JSON.stringify({
//                     years: uniqueYears,
//                     businesses: uniqueBusinesses,
//                     pricingGroups: uniquePricingGroups,
//                     customerSizes: uniqueCustomerSizes,
//                     salesManagers: uniqueSalesManager,
//                 }));
//                 localStorage.setItem('cacheExpiration', new Date().getTime() + 10 * 60 * 1000); // Cache for 10 minutes
//             }
//         } catch (error) {
//             console.error('Error fetching filter data:', error);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const onItemClick = (item) => {
//         setSelectedItem(item);
//     };

//     const handlePricingGroupClick = (pricingGroup) => {
//         setSelectedPricingGroup(selectedPricingGroup === pricingGroup ? '' : pricingGroup);
//     };

//     const handleSalesManagerClick = (salesManager) => {
//         setSelectedSalesManager(selectedSalesManager === salesManager ? '' : salesManager);
//     };

//     return (
//         <div className="Home">
//             <Navbar />
//             <div className="main-content-howmuch">
//                 <div className="header-container-howmuch">
//                     <div className='title-filter'>
//                         <div className='page-title-home'>
//                             <p>Price Opportunity How Much?</p>
//                         </div>
//                         <div className="filter-container-howmuch">
//                             <Filter
//                                 years={years}
//                                 businesses={businesses}
//                                 pricingGroups={pricingGroups}
//                                 customerSizes={customerSizes}
//                                 salesManagers={salesManagers}
//                                 selectedYear={selectedYear}
//                                 selectedBusiness={selectedBusiness}
//                                 selectedPricingGroup={selectedPricingGroup}
//                                 selectedCustomerSize={selectedCustomerSize}
//                                 selectedSalesManager={selectedSalesManager}
//                                 onYearChange={setSelectedYear}
//                                 onBusinessChange={setSelectedBusiness}
//                                 onPricingGroupChange={setSelectedPricingGroup}
//                                 onCustomerSizeChange={setSelectedCustomerSize}
//                                 onSalesManagerChange={setSelectedSalesManager}
//                                 showCustomerSizeFilter={false}
//                                 showSalesManagerFilter={false}
//                             />
//                         </div>
//                     </div>
//                     <div className="summary-container-howmuch">
//                         <SummaryStats
//                             selectedYear={selectedYear}
//                             selectedBusiness={selectedBusiness}
//                             selectedPricingGroup={selectedPricingGroup}
//                             selectedItem={selectedItem}
//                             selectedSalesManager={selectedSalesManager}
//                         />
//                     </div>
//                 </div>
//                 <div className="container-howmuch">
//                     <div className="table-container-howmuch">
//                         {isLoading ? (
//                             <p>Loading...</p>
//                         ) : (
//                             <PricingDataTable
//                                 selectedYear={selectedYear}
//                                 selectedBusiness={selectedBusiness}
//                                 selectedPricingGroup={selectedPricingGroup}
//                                 onItemClick={onItemClick}
//                                 onPricingGroupClick={handlePricingGroupClick}
//                             />
//                         )}
//                     </div>
//                     <div className="charts-container-howmuch">
//                         {isLoading ? (
//                             <p>Loading...</p>
//                         ) : (
//                             <CustomerBar
//                                 selectedYear={selectedYear}
//                                 selectedBusiness={selectedBusiness}
//                                 selectedPricingGroup={selectedPricingGroup}
//                                 selectedItem={selectedItem}
//                                 onItemClick={onItemClick}
//                                 onSalesManagerClick={handleSalesManagerClick}
//                             />
//                         )}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default OpportunityHowMuch;
