// import React from 'react';
// import '../static/Filter.css';

// const Pvmfilter = ({
//   years = [2020, 2021, 2022, 2023],
//   periods = ['Year','Quarter','Month'],
//   quarters = [],
//   months = [],
//   businesses = [],
//   verticals = [],
//   selectedperiod,
//   selectedbaseperiod,
//   selectedcompperiod,
//   selectedBusiness,
//   selectedVertical,
//   onBasePeriodChange,
//   onCompPeriodChange,
//   onBusinessChange,
//   onVerticalChange,
// }) => {
//   return (
//     <div className="filter">
//       <div className='filter-grid'>
//       <div className="filter-group">
//           <label id='nav-label' htmlFor="period">Select Period:</label>
//           <select id="period" value={selectedperiod} onChange={(e) => onBasePeriodChange(e.target.value)}>
//             <option value="">All</option>
//             {periods.map(period => (
//               <option key={period} value={period}>{period}</option>
//             ))}
//           </select>
//         </div>
//         <div className="filter-group">
//           <label id='nav-label' htmlFor="period">Quarters:</label>
//           <select id="quarters" value={selectedperiod} onChange={(e) => onBasePeriodChange(e.target.value)}>
//             <option value="">All</option>
//             {quarters.map(quarter => (
//               <option key={quarter} value={quarter}>{quarter}</option>
//             ))}
//           </select>
//         </div>
//         <div className="filter-group">
//           <label id='nav-label' htmlFor="period">Months:</label>
//           <select id="months" value={selectedperiod} onChange={(e) => onBasePeriodChange(e.target.value)}>
//             <option value="">All</option>
//             {months.map(month => (
//               <option key={month} value={month}>{month}</option>
//             ))}
//           </select>
//         </div>
//         <div className="filter-group">
//           <label id='nav-label' htmlFor="year">Base Year:</label>
//           <select id="year" value={selectedbaseperiod} onChange={(e) => onBasePeriodChange(e.target.value)}>
//             <option value="">All</option>
//             {years.map(year => (
//               <option key={year} value={year}>{year}</option>
//             ))}
//           </select>
//         </div>
//         <div className="filter-group">
//           <label id='nav-label' htmlFor="year">Comp Year:</label>
//           <select id="year" value={selectedcompperiod} onChange={(e) => onCompPeriodChange(e.target.value)}>
//             <option value="">All</option>
//             {years.map(year => (
//               <option key={year} value={year}>{year}</option>
//             ))}
//           </select>
//         </div>
//         <div className="filter-group">
//           <label id='nav-label' htmlFor="business">Business:</label>
//           <select id="business" value={selectedBusiness} onChange={(e) => onBusinessChange(e.target.value)}>
//             <option value="">All</option>
//             {businesses.map(business => (
//               <option key={business} value={business}>{business}</option>
//             ))}
//           </select>
//         </div>
//         <div className="filter-group">
//           <label id='nav-label' htmlFor="vertical">Vertical:</label>
//           <select id="vertical" value={selectedVertical} onChange={(e) => onVerticalChange(e.target.value)}>
//             <option value="">All</option>
//             {verticals.map(vertical => (
//               <option key={vertical} value={vertical}>{vertical}</option>
//             ))}
//           </select>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Pvmfilter;



// import React, { useState, useEffect } from 'react';
// // import '../static/Filter.css';
// import Select from 'react-select';
// import './Pvmfilter.css';

// const Pvmfilter = ({
//   years = [2020, 2021, 2022, 2023],
//   periods = ['Year', 'Quarter', 'Month'],
//   quarters = [],
//   months = [],
//   businesses = [],
//   verticals = [],
//   selectedperiod,
//   selectedbaseperiod,
//   selectedcompperiod,
//   selectedBusiness,
//   selectedVertical,
//   onPeriodChange,
//   onBasePeriodChange,
//   onCompPeriodChange,
//   onBusinessChange,
//   onVerticalChange,
// }) => {
//   const [selectedPeriod, setSelectedPeriod] = useState('Year');
//   const [selectedBasePeriod, setSelectedBasePeriod] = useState(2020);
//   const [selectedCompPeriod, setSelectedCompPeriod] = useState(2023);

//   const [baseOptions, setBaseOptions] = useState(years);
//   const [compOptions, setCompOptions] = useState(years);

//   const formatOptions = (options) => options.map(option => ({ value: option, label: option }));

//   useEffect(() => {
//     if (selectedPeriod === 'Year') {
//       setBaseOptions(years);
//       setCompOptions(years);
//     } else if (selectedPeriod === 'Quarter') {
//       setBaseOptions(quarters);
//       setCompOptions(quarters);
//     } else if (selectedPeriod === 'Month') {
//       setBaseOptions(months);
//       setCompOptions(months);
//     }
//   }, [selectedPeriod, years, quarters, months]);

//   useEffect(() => {
//     onPeriodChange(selectedPeriod);
//     onBasePeriodChange(selectedBasePeriod);
//     onCompPeriodChange(selectedCompPeriod);
//   }, [selectedPeriod, selectedBasePeriod, selectedCompPeriod, onPeriodChange, onBasePeriodChange, onCompPeriodChange]);

//   return (
//     <div className="filter-pvm">
//       <div className="filter-grid-pvm">
//         {/* <div className="filter-group">
//           <select id="basePeriod" value={selectedBasePeriod} onChange={(e) => setSelectedBasePeriod(e.target.value)}>
//             <option value="">Start Year </option>
//             {baseOptions.map(option => (
//               <option key={option} value={option}>{option}</option>
//             ))}
//           </select>
//         </div>
//         <div className="filter-group">
//           <select id="compPeriod" value={selectedCompPeriod} onChange={(e) => setSelectedCompPeriod(e.target.value)}>
//             <option value="">End Year</option>
//             {compOptions.map(option => (
//               <option key={option} value={option}>{option}</option>
//             ))}
//           </select>
//         </div> */}

//         <div className="filter-group-pvm">
//           <Select
//             options={formatOptions(years)}
//             value={selectedBasePeriod ? { value: selectedBasePeriod, label: selectedBasePeriod } : null}
//             onChange={(option) => onBasePeriodChange(option ? option.value : '')}
//             isClearable
//             placeholder="Start Year"
//             className="react-select-container"
//           />
//         </div>

//         <div className="filter-group-pvm">
//           <Select
//             options={formatOptions(years)}
//             value={selectedCompPeriod ? { value: selectedCompPeriod, label: selectedCompPeriod } : null}
//             onChange={(option) => onCompPeriodChange(option ? option.value : '')}
//             isClearable
//             placeholder="End Year"
//             className="react-select-container"
//           />
//         </div>

//         <div className="filter-group-pvm">
//           <Select
//             options={formatOptions(businesses)}
//             value={selectedBusiness ? { value: selectedBusiness, label: selectedBusiness } : null}
//             onChange={(option) => onBusinessChange(option ? option.value : '')}
//             isClearable
//             placeholder="Business"
//             className="react-select-container"
//           />
//         </div>

//         <div className="filter-group-pvm">
//           <Select
//             options={formatOptions(verticals)}
//             value={selectedVertical ? { value: selectedVertical, label: selectedVertical } : null}
//             onChange={(option) => onVerticalChange(option ? option.value : '')}
//             isClearable
//             placeholder="Verticals"
//             className="react-select-container"
//           />
//         </div>

//         {/* <div className="filter-group">
//           <select id="business" value={selectedBusiness} onChange={(e) => onBusinessChange(e.target.value)}>
//             <option value="">Business</option>
//             {businesses.map(business => (
//               <option key={business} value={business}>{business}</option>
//             ))}
//           </select>
//         </div>
//         <div className="filter-group">
//           <select id="vertical" value={selectedVertical} onChange={(e) => onVerticalChange(e.target.value)}>
//             <option value="">Vertical</option>
//             {verticals.map(vertical => (
//               <option key={vertical} value={vertical}>{vertical}</option>
//             ))}
//           </select>
//         </div> */}
//       </div>
//     </div>
//   );
// };

// export default Pvmfilter;






// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
// import './Pvmfilter.css';

// const Pvmfilter = ({
//   years = [2020, 2021, 2022, 2023],
//   periods = ['Year', 'Quarter', 'Month'],
//   quarters = [],
//   months = [],
//   businesses = [],
//   verticals = [],
//   selectedperiod,
//   selectedbaseperiod = 2023,
//   selectedcompperiod = 2022,
//   selectedBusiness,
//   selectedVertical,
//   onPeriodChange,
//   onBasePeriodChange,
//   onCompPeriodChange,
//   onBusinessChange,
//   onVerticalChange,
// }) => {
//   // Initialize state with defaults
//   const [selectedPeriod, setSelectedPeriod] = useState(selectedperiod || 'Year');
//   const [selectedBasePeriod, setSelectedBasePeriod] = useState(selectedbaseperiod ); // Default to 2022
//   const [selectedCompPeriod, setSelectedCompPeriod] = useState(selectedcompperiod ); // Default to 2023

//   const [baseOptions, setBaseOptions] = useState(years);
//   const [compOptions, setCompOptions] = useState(years);

//   useEffect(() => {
//     if (selectedPeriod === 'Year') {
//       setBaseOptions(years);
//       setCompOptions(years);
//     } else if (selectedPeriod === 'Quarter') {
//       setBaseOptions(quarters);
//       setCompOptions(quarters);
//     } else if (selectedPeriod === 'Month') {
//       setBaseOptions(months);
//       setCompOptions(months);
//     }
//   }, [selectedPeriod, years, quarters, months]);

//   useEffect(() => {
//     onPeriodChange(selectedPeriod);
//     onBasePeriodChange(selectedBasePeriod);
//     onCompPeriodChange(selectedCompPeriod);
//   }, [selectedPeriod, selectedBasePeriod, selectedCompPeriod, onPeriodChange, onBasePeriodChange, onCompPeriodChange]);

//   return (
//     <div className="filter-pvm">
//       <div className="filter-grid-pvm">

//         <div className="filter-group-pvm">
//           <Select
//             options={formatOptions(compOptions)}
//             value={compOptions.find(option => option === selectedCompPeriod) ? { value: selectedCompPeriod, label: selectedCompPeriod } : null}
//             onChange={(option) => {
//               const value = option ? option.value : '';
//               setSelectedCompPeriod(value);
//               onCompPeriodChange(value);
//             }}
//             isClearable
//             placeholder="Comp Year"
//             className="react-select-container"
//           />
//         </div>

//         <div className="filter-group-pvm">
//           <Select
//             options={formatOptions(baseOptions)}
//             value={baseOptions.find(option => option === selectedBasePeriod) ? { value: selectedBasePeriod, label: selectedBasePeriod } : null}
//             onChange={(option) => {
//               const value = option ? option.value : '';
//               setSelectedBasePeriod(value);
//               onBasePeriodChange(value);
//             }}
//             isClearable
//             placeholder="Base Year"
//             className="react-select-container"
//           />
//         </div>

//         <div className="filter-group-pvm">
//           <Select
//             options={formatOptions(businesses)}
//             value={selectedBusiness ? { value: selectedBusiness, label: selectedBusiness } : null}
//             onChange={(option) => {
//               const value = option ? option.value : '';
//               onBusinessChange(value);
//             }}
//             isClearable
//             placeholder="Business"
//             className="react-select-container"
//           />
//         </div>

//         <div className="filter-group-pvm">
//           <Select
//             options={formatOptions(verticals)}
//             value={selectedVertical ? { value: selectedVertical, label: selectedVertical } : null}
//             onChange={(option) => {
//               const value = option ? option.value : '';
//               onVerticalChange(value);
//             }}
//             isClearable
//             placeholder="Verticals"
//             className="react-select-container"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// const formatOptions = (options) => options.map(option => ({ value: option, label: option }));

// export default Pvmfilter;





// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
// import './Pvmfilter.css';

// const Pvmfilter = ({
//   years = [2020, 2021, 2022, 2023],
//   periods = ['Year', 'Quarter', 'Month'],
//   quarters = [],
//   months = [],
//   businesses = [],
//   verticals = [],
//   selectedperiod,
//   selectedbaseperiod, 
//   selectedcompperiod,
//   selectedBusiness,
//   selectedVertical,
//   onPeriodChange,
//   onBasePeriodChange,
//   onCompPeriodChange,
//   onBusinessChange,
//   onVerticalChange,
// }) => {
//   // Initialize state with defaults
//   const [selectedPeriod, setSelectedPeriod] = useState(selectedperiod || 'Year');
//   const [selectedBasePeriod, setSelectedBasePeriod] = useState(selectedbaseperiod); 
//   const [selectedCompPeriod, setSelectedCompPeriod] = useState(selectedcompperiod); 

//   const [baseOptions, setBaseOptions] = useState(years);
//   const [compOptions, setCompOptions] = useState(years);

//   useEffect(() => {
//     if (selectedPeriod === 'Year') {
//       setBaseOptions(years);
//       setCompOptions(years);
//     } else if (selectedPeriod === 'Quarter') {
//       setBaseOptions(quarters);
//       setCompOptions(quarters);
//     } else if (selectedPeriod === 'Month') {
//       setBaseOptions(months);
//       setCompOptions(months);
//     }
//   }, [selectedPeriod, years, quarters, months]);

//   useEffect(() => {
//     onPeriodChange(selectedPeriod);
//     onBasePeriodChange(selectedBasePeriod);
//     onCompPeriodChange(selectedCompPeriod);
//   }, [selectedPeriod, selectedBasePeriod, selectedCompPeriod, onPeriodChange, onBasePeriodChange, onCompPeriodChange]);

//   return (
//     <div className="filter-pvm">
//       <div className="filter-grid-pvm">

//         <div className="filter-group-pvm">
//           <Select
//             options={formatOptions(compOptions)}
//             value={compOptions.find(option => option === selectedCompPeriod) ? { value: selectedCompPeriod, label: selectedCompPeriod } : { value: 2022, label: 2022 }}
//             onChange={(option) => {
//               const value = option ? option.value : '';
//               setSelectedCompPeriod(value);
//               onCompPeriodChange(value);
//             }}
//             isClearable
//             placeholder="Comp Year"
//             className="react-select-container"
//           />
//         </div>

//         <div className="filter-group-pvm">
//           <Select
//             options={formatOptions(baseOptions)}
//             value={baseOptions.find(option => option === selectedBasePeriod) ? { value: selectedBasePeriod, label: selectedBasePeriod } : { value: 2023, label: 2023 }}
//             onChange={(option) => {
//               const value = option ? option.value : '';
//               setSelectedBasePeriod(value);
//               onBasePeriodChange(value);
//             }}
//             isClearable
//             placeholder="Base Year"
//             className="react-select-container"
//           />
//         </div>

//         <div className="filter-group-pvm">
//           <Select
//             options={formatOptions(businesses)}
//             value={selectedBusiness ? { value: selectedBusiness, label: selectedBusiness } : null}
//             onChange={(option) => {
//               const value = option ? option.value : '';
//               onBusinessChange(value);
//             }}
//             isClearable
//             placeholder="Business"
//             className="react-select-container"
//           />
//         </div>

//         <div className="filter-group-pvm">
//           <Select
//             options={formatOptions(verticals)}
//             value={selectedVertical ? { value: selectedVertical, label: selectedVertical } : null}
//             onChange={(option) => {
//               const value = option ? option.value : '';
//               onVerticalChange(value);
//             }}
//             isClearable
//             placeholder="Verticals"
//             className="react-select-container"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// const formatOptions = (options) => options.map(option => ({ value: option, label: option }));

// export default Pvmfilter;




import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './Pvmfilter.css';

const Pvmfilter = ({
  years = [2020, 2021, 2022, 2023],
  periods = ['Year', 'Quarter', 'Month'],
  quarters = [],
  months = [],
  businesses = [],
  verticals = [],
  selectedperiod,
  selectedbaseperiod, 
  selectedcompperiod,
  selectedBusiness,
  selectedVertical,
  onPeriodChange,
  onBasePeriodChange,
  onCompPeriodChange,
  onBusinessChange,
  onVerticalChange,
}) => {
  // Initialize state with defaults
  const [selectedPeriod, setSelectedPeriod] = useState(selectedperiod || 'Year');
  const [selectedBasePeriod, setSelectedBasePeriod] = useState(selectedbaseperiod); 
  const [selectedCompPeriod, setSelectedCompPeriod] = useState(selectedcompperiod); 

  const [baseOptions, setBaseOptions] = useState(years);
  const [compOptions, setCompOptions] = useState(years);

  useEffect(() => {
    if (selectedPeriod === 'Year') {
      setBaseOptions(years);
      setCompOptions(years);
    } else if (selectedPeriod === 'Quarter') {
      setBaseOptions(quarters);
      setCompOptions(quarters);
    } else if (selectedPeriod === 'Month') {
      setBaseOptions(months);
      setCompOptions(months);
    }
  }, [selectedPeriod, years, quarters, months]);

  useEffect(() => {
    onPeriodChange(selectedPeriod);
    onBasePeriodChange(selectedBasePeriod);
    onCompPeriodChange(selectedCompPeriod);
  }, [selectedPeriod, selectedBasePeriod, selectedCompPeriod, onPeriodChange, onBasePeriodChange, onCompPeriodChange]);

  return (
    <div className="filter-pvm">
      <div className="filter-grid-pvm">

        <div className="filter-group-pvm">
          <Select
            options={formatOptions(compOptions)}
            value={compOptions.find(option => option === selectedCompPeriod) ? { value: selectedCompPeriod, label: selectedCompPeriod } : null}
            onChange={(option) => {
              const value = option ? option.value : '';
              setSelectedCompPeriod(value);
              onCompPeriodChange(value);
            }}
            isClearable
            placeholder="Comp Year"
            className="react-select-container"
          />
        </div>

        <div className="filter-group-pvm">
          <Select
            options={formatOptions(baseOptions)}
            value={baseOptions.find(option => option === selectedBasePeriod) ? { value: selectedBasePeriod, label: selectedBasePeriod } : null}
            onChange={(option) => {
              const value = option ? option.value : '';
              setSelectedBasePeriod(value);
              onBasePeriodChange(value);
            }}
            isClearable
            placeholder="Base Year"
            className="react-select-container"
          />
        </div>

        <div className="filter-group-pvm">
          <Select
            options={formatOptions(businesses)}
            value={selectedBusiness ? { value: selectedBusiness, label: selectedBusiness } : null}
            onChange={(option) => {
              const value = option ? option.value : '';
              onBusinessChange(value);
            }}
            isClearable
            placeholder="Business"
            className="react-select-container"
          />
        </div>

        <div className="filter-group-pvm">
          <Select
            options={formatOptions(verticals)}
            value={selectedVertical ? { value: selectedVertical, label: selectedVertical } : null}
            onChange={(option) => {
              const value = option ? option.value : '';
              onVerticalChange(value);
            }}
            isClearable
            placeholder="Verticals"
            className="react-select-container"
          />
        </div>
      </div>
    </div>
  );
};

const formatOptions = (options) => options.map(option => ({ value: option, label: option }));

export default Pvmfilter;
