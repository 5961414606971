import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Pvmtotals.css';
import { BASE_URL } from '../../common/baseUrl';

const Pvmtotals = ({ selectedYear, selectedBusiness, selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedVertical }) => {
  const [summaryData, setSummaryData] = useState({
    totalBaseNS: 0,
    totalCompNS: 0,
    totalBudgetNS: 0,
    totalBaseVol: 0,
    totalCompVol: 0,
    totalBudgetVol:0,
  });

  useEffect(() => {
    console.log('useEffect called with dependencies:', { selectedYear, selectedBusiness, selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedVertical });
    fetchSummaryData();
  }, [selectedYear, selectedBusiness, selectedPeriod, selectedBasePeriod, selectedCompPeriod, selectedVertical]);

  const fetchSummaryData = async () => {
    try {
      console.log('Fetching summary data with params:', {
        year: selectedYear,
        business: selectedBusiness,
        period: selectedPeriod,
        base_period: selectedBasePeriod,
        comparison_period: selectedCompPeriod,
        ship_to_vertical: selectedVertical,
      });
      const response = await axios.get(`${BASE_URL}/pvm-calculations`, {
        params: {
            period: selectedPeriod,
            base_period: selectedBasePeriod,
            comparison_period: selectedCompPeriod,
            business: selectedBusiness,
            ship_to_vertical:selectedVertical,
        }
      });
      const data = response.data;

      const budget_response = await axios.get(`${BASE_URL}/api/budget-pvm-calculations/`, {
        params: {
            period: selectedPeriod,
            base_period: selectedBasePeriod,
            comparison_period: selectedCompPeriod,
            business: selectedBusiness,
            ship_to_vertical:selectedVertical,
        }
      });

      const budget_data = budget_response.data;

      console.log('Received data:', data);

      setSummaryData({
        totalBaseNS: data.totals.total_base_ns || 0,
        totalCompNS: data.totals.total_comp_ns || 0,
        totalBudgetNS: budget_data.totals.total_comp_ns || 0,
        totalBaseVol: data.totals.total_base_vol || 0,
        totalCompVol: data.totals.total_comp_vol || 0,
        totalBudgetVol: budget_data.totals.total_comp_vol ||0,

      });
    } catch (error) {
      console.error('Error fetching summary data:', error);
    }
  };



const formatCurrency = (value) => {
  if (value >= 1000000) {
    // For values greater than or equal to 1,000,000, format as $X.XM
    const millions = value / 1000000;
    return `$${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
  } else {
    // For values less than 1,000,000, format with commas
    return `$${value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
  }
};

const formatNumber = (value) => {
  if (value > 1000000){
    const millions = value/1000000;
    return `${millions.toFixed(millions >= 10 ? 1 : 2)}M`;
  }
  return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
};



  return (
    <div className="summary-stats-pvm">
      <div className="stats-grid-pvm">
      <div className="stat-item-pvm">
          <span className="stat-label-pvm">Comp NS:</span>
          <span className="stat-value-pvm">{formatCurrency(summaryData.totalCompNS)}</span>
        </div>
        <div className="stat-item-pvm">
          <span className="stat-label-pvm">Actual NS:</span>
          <span className="stat-value-pvm">{formatCurrency(summaryData.totalBaseNS)}</span>
        </div>
        <div className="stat-item-pvm">
          <span className="stat-label-pvm">Budget NS:</span>
          <span className="stat-value-pvm">{formatCurrency(summaryData.totalBudgetNS)}</span>
        </div>
        <div className="stat-item-pvm">
          <span className="stat-label-pvm">Comp Vol:</span>
          <span className="stat-value-pvm">{formatNumber(summaryData.totalCompVol)}</span>
        </div>
        <div className="stat-item-pvm">
          <span className="stat-label-pvm">Actual Vol:</span>
          <span className="stat-value-pvm">{formatNumber(summaryData.totalBaseVol)}</span>
        </div>
        <div className="stat-item-pvm">
          <span className="stat-label-pvm">Budget Vol:</span>
          <span className="stat-value-pvm">{formatNumber(summaryData.totalBudgetVol)}</span>
        </div>
      </div>
    </div>
  );
};

export default Pvmtotals;
