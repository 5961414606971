export const msalConfig = {
    auth: {
      clientId: "bfdff80b-289a-483d-a924-db293f97e25d",
      authority:
        "https://login.microsoftonline.com/abc2364a-935c-447e-b06a-b8229166e82b",
        redirectUri: "https://pricing.insightshigh.com/"
        // redirectUri: "http://localhost:3000"
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  };
  
  export const loginRequest = {
    scopes: [
      "User.Read",
      "Directory.Read.All",
      "api://bfdff80b-289a-483d-a924-db293f97e25d/access_as_user",
    ],
  };
  