// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.filter-price-category-comp  {
    display: flex;
    flex-direction: column; 
    align-items: center;
    /* padding: 10px; */
   
    
  }
  
  .filter-price-category-comp  .filter-grid-price-category {
    display: flex;
    /* flex-direction: column;  */
    gap: 10px;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    background-color: #ffffff;
    /* max-width: 250px;  */
    
  }
  
  .filter-price-category-comp  .filter-group-price-category {
    width: 100%; /* Ensures that each filter takes the full width */
    
  }

  .filter-group-price-category{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .filter-price-category-comp .react-select-container {
    width: 100%; /* Ensures the react-select component stretches to fill the available width */
    
  }
  `, "",{"version":3,"sources":["webpack://./src/components/price_category/PriceCategoryFilter/PriceCategory.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;;;EAGrB;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,SAAS;IACT,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,uBAAuB;;EAEzB;;EAEA;IACE,WAAW,EAAE,kDAAkD;;EAEjE;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,WAAW,EAAE,6EAA6E;;EAE5F","sourcesContent":["\n.filter-price-category-comp  {\n    display: flex;\n    flex-direction: column; \n    align-items: center;\n    /* padding: 10px; */\n   \n    \n  }\n  \n  .filter-price-category-comp  .filter-grid-price-category {\n    display: flex;\n    /* flex-direction: column;  */\n    gap: 10px;\n    width: 100%;\n    font-size: 12px;\n    font-weight: 500;\n    background-color: #ffffff;\n    /* max-width: 250px;  */\n    \n  }\n  \n  .filter-price-category-comp  .filter-group-price-category {\n    width: 100%; /* Ensures that each filter takes the full width */\n    \n  }\n\n  .filter-group-price-category{\n    margin-top: 5px;\n    margin-bottom: 5px;\n  }\n  \n  .filter-price-category-comp .react-select-container {\n    width: 100%; /* Ensures the react-select component stretches to fill the available width */\n    \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
