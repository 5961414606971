// import React from 'react';
// import { Link } from 'react-router-dom';
// import '../static/Navbar.css';

// function Navbar() {
//     return (
//         <nav className="navbar">
//             <div className="navbar-container">
//                 <div className="navbar-brand">Pricing Demo</div>
//                 <div className="navbar-links">
//                     <Link to="/">Price Opportunity - How Much?</Link>
//                     <Link to="/pricing-detail">Price Opportunity - Why?</Link>
//                     <Link to="/pvm">PVM</Link>

//                 </div>
//             </div>
//         </nav>
//     );
// }

// export default Navbar;





// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import './Navbar.css';
// import Logo from './Logo.jpg';

// function Navbar() {
//     return (
//         <nav className="navbar">
//             <div className="navbar-container">
//                 <img src={Logo} alt="Logo" className="navbar-logo" />
//                 {/* <div className="navbar-brand">Pricing Demo</div> */}
//                 <div className="navbar-links">
//                 <NavLink exact to="/home" className={({ isActive }) => (isActive ? 'active-link' : '')}>
//                         Home
//                     </NavLink>
//                     <NavLink exact to="/" className={({ isActive }) => (isActive ? 'active-link' : '')}>
//                         Price Opportunity - How Much?
//                     </NavLink>
//                     <NavLink to="/pricing-detail" className={({ isActive }) => (isActive ? 'active-link' : '')}>
//                         Price Opportunity - Why?
//                     </NavLink>
//                     <NavLink to="/pvm" className={({ isActive }) => (isActive ? 'active-link' : '')}>
//                         Price Volume Mix
//                     </NavLink>
//                     <NavLink to="/cm-bridge" className={({ isActive }) => (isActive ? 'active-link' : '')}>
//                         CM Bridge Analysis
//                     </NavLink>
//                     <NavLink to="/price-change" className={({ isActive }) => (isActive ? 'active-link' : '')}>
//                         YoY Price
//                     </NavLink>
//                 </div>
//             </div>
//         </nav>
//     );
// }

// export default Navbar;





import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Avatar, Menu, MenuItem, ListItemIcon, IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import Logo from "./Logo.jpg";
import "./Navbar.css";

function Navbar({ userProfileQuery }) {
  const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleAvatarClick = (event) => {
    setAvatarAnchorEl(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken"); // Remove access token from local storage
    setAvatarAnchorEl(null);
    navigate("/login", { replace: true }); // Navigate to login page
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <img src={Logo} alt="Logo" className="navbar-logo" />
        <div className="navbar-links">
          <NavLink exact to="/home" className={({ isActive }) => (isActive ? "active-link" : "")}>
            Home
          </NavLink>
          <NavLink exact to="/" className={({ isActive }) => (isActive ? "active-link" : "")}>
            Price Opportunity - How Much?
          </NavLink>
          <NavLink to="/pricing-detail" className={({ isActive }) => (isActive ? "active-link" : "")}>
            Price Opportunity - Why?
          </NavLink>
          <NavLink to="/pvm" className={({ isActive }) => (isActive ? "active-link" : "")}>
            Price Volume Mix
          </NavLink>
          <NavLink to="/cm-bridge" className={({ isActive }) => (isActive ? "active-link" : "")}>
            CM Bridge Analysis
          </NavLink>
          <NavLink to="/price-change" className={({ isActive }) => (isActive ? "active-link" : "")}>
            YoY Price
          </NavLink>
        </div>

        {/* Logout Functionality at the bottom */}
        <div className="navbar-logout">
          <IconButton onClick={handleAvatarClick}>
            <Avatar
              src={userProfileQuery?.profile_image_url || ""}
              sx={{
                bgcolor: "transparent",
                border: "1px solid var(--border-color)",
                color: "var(--text-color)",
                cursor: "pointer",
              }}
            >
              {userProfileQuery?.profile_image_url ? "" : (userProfileQuery?.username || "").charAt(0)}
            </Avatar>
          </IconButton>

          <Menu
            anchorEl={avatarAnchorEl}
            open={Boolean(avatarAnchorEl)}
            onClose={handleAvatarClose}
          >
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

