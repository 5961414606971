// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.summary-stats-pvm,
.filter {
  padding-right: 5px;
  width: 100%;
}

.stats-grid-pvm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}

.stat-item-pvm {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9em;
}

.stat-label-pvm {
  font-weight: bold;
  font-size: 15px;
}

.stat-value-pvm {
  color: #333;
  font-weight: bold;
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pvm/Pvmtotals/Pvmtotals.css"],"names":[],"mappings":";AACA;;EAEE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,kCAAkC;EAClC,QAAQ;AACV;;AAEA;EACE,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,YAAY;EACZ,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":["\n.summary-stats-pvm,\n.filter {\n  padding-right: 5px;\n  width: 100%;\n}\n\n.stats-grid-pvm {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 5px;\n}\n\n.stat-item-pvm {\n  margin-top: 4px;\n  display: flex;\n  justify-content: space-between;\n  padding: 5px;\n  background-color: #fff;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  font-size: 0.9em;\n}\n\n.stat-label-pvm {\n  font-weight: bold;\n  font-size: 15px;\n}\n\n.stat-value-pvm {\n  color: #333;\n  font-weight: bold;\n  font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
